import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from 'react';
import { getUsersFilteredByGeo } from '../../Services/Auth/Authentification';
import { getListSpecificNavette } from '../../Services/Navettes/Navettes';
import { LinearProgress } from '@mui/material';
import { assignNavetteToPrep, setPriorityToPrep, assignPrepToUser, getPrepPalettes } from '../../Services/Commandes/Commandes';
import { setPaletteInNavette } from '../../Services/Palettes/Palettes';

const Styles = {
    select: {padding: "10px", width: "100%"},
    number: {padding: "10px", width: "100%", textAlign: "center"}
}

const ActionsDialogue = (props) => {

  let handleClose = props.handleClose;
  let handleReload = props.handleReload;
  let open = props.open;
  let action = props.action;
  let selected = props.selected;
  const [users, setUsers] = useState(null);
  const [navettes, setNavettes] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userSelected, setUserSelected] = useState(null);
  const [navetteSelected, setNavetteSelected] = useState(null);
  const [priorityInput, setPriorityInput] = useState(0);

  function handleSave(){
    if(action === "assignnavette"){
        selected.forEach((order_prep_id) => {
            assignNavetteToPrep(order_prep_id, navetteSelected);
            getPrepPalettes("/order_preparations/"+order_prep_id).then( async palettes=>{
                await palettes['hydra:member'].forEach(palette =>{
                    setPaletteInNavette(palette['@id'], navetteSelected);
                })
            })
        });
    }else if(action === "setpriority"){
        selected.forEach((order_prep_id) => {
            setPriorityToPrep(order_prep_id, priorityInput);
        });
    }else if(action === "assigntouser"){
        selected.forEach((order_prep_id) => {
            assignPrepToUser(order_prep_id, userSelected);
        });
    }
    setUserSelected(null);
    setNavetteSelected(null);
    setPriorityInput(0);
    handleClose();
    setTimeout(() => {
        handleReload();
    }, 1000)
  }

  function handleChangeUserSelect(e){
    let value = e.target.value;
    setUserSelected(value);
  }

  function handleChangeNavetteSelect(e){
    let value = e.target.value;
    setNavetteSelected(value);
  }

  function handleChangePriorityInput(e){
    let value = e.target.value;
    setPriorityInput(value);
  }

  useEffect(() => {
    setLoading(true);
    getUsersFilteredByGeo().then((users)=>{
        setUsers(users);
        getListSpecificNavette("&charged=0").then((navettes)=>{
            setNavettes(navettes['hydra:member']);
            setLoading(false);
        });
    })
    setUserSelected(null);
    setNavetteSelected(null);
    setPriorityInput(0);
  }, [])


  let bodyComponent;
  let error = false;
  switch (action) {
    case 'assignnavette':
        bodyComponent = 
        <>
            <DialogTitle>Assigner à une navette</DialogTitle>
            
            <DialogContent>
                <DialogContentText>
                    Préparation séléctionné : <br></br>{selected.join(" & ")}<br></br><br></br>
                    <select value={navetteSelected} onChange={handleChangeNavetteSelect} style={Styles.select}>
                        <option selected disabled>-- Choisir une navette</option>
                        {navettes.map((navette)=>{
                            return <option value={navette['@id']}>{"#"+navette['@id'].replace("/navettes/", "")} - {navette.carrier.name} - {new Date(Date.parse(navette.estimatedAt)).toLocaleDateString("fr") + " à " + new Date(Date.parse(navette.estimatedAt)).toLocaleTimeString("fr")}</option>;
                        })}
                        
                        
                        
                    </select>
                </DialogContentText>
            </DialogContent>
        </>;
        break;
    case 'setpriority':
        bodyComponent = 
        <>
            <DialogTitle>Définir la priorité</DialogTitle>
            
            <DialogContent>
                <DialogContentText>
                    Préparation séléctionné : <br></br>{selected.join(" et ")}<br></br><br></br>
                    <input value={priorityInput} onChange={handleChangePriorityInput} style={Styles.number} type="text" /> 
                </DialogContentText>
            </DialogContent>
        </>;
        break;
    case 'assigntouser':
        bodyComponent = 
        <>
            <DialogTitle>Attribuer à un utilisateur</DialogTitle>
            
            <DialogContent>
                <DialogContentText>
                    Préparation séléctionné : <br></br>{selected.join(" et ")}<br></br><br></br>
                    <select value={userSelected} onChange={handleChangeUserSelect} style={Styles.select}>
                        <option selected disabled>-- Choisir un utilisateur</option>
                        {users.map((user)=>{
                            return <option value={user['@id']}>{user.email}</option>;
                        })}
                        
                        
                        
                    </select>
                </DialogContentText>
            </DialogContent>
        </>;
        break;
    default:
        error = true;
        bodyComponent = <DialogTitle>Error</DialogTitle>;
  }
  
  if(loading){
    return(<LinearProgress/>)
  }  
  return (
    <div>

      <Dialog open={open} onClose={handleClose}>
        {loading? <LinearProgress/> : 

            bodyComponent
        
        }
        <DialogActions>
          <Button onClick={handleClose}>Fermer</Button>
          {
            !error? 
            <Button onClick={handleSave}>Sauvegarder</Button>
            :
            <></>

          }
        </DialogActions>
      </Dialog>
    </div>
  );
}


export default ActionsDialogue;