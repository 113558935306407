import { useEffect, useState } from "react";
import { changeLabelNavette, getOneNavette } from "../../Services/Navettes/Navettes";
import {LinearProgress, Button, IconButton, TextField} from "@mui/material";
import ChannelsPresenter from "../Channels/ChannelsPresenter";
import TransporteurPresenter from "../Transporteurs/TransporteurPresenter";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ListingPalettesInNavette from "../Palettes/ListingPalettesInNavette";
import DownloadpdfList from "../Button/btnDownloadPDFfromPresta";
import { useNavigate } from "react-router-dom";
import { canAccess } from "../../Services/Auth/Authentification";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const NavetteDetailsTablePaletteInside = (props) =>{

    let id_navette = props.id_navette;
    let handleReloadWithoutPal = props.handleReloadWithoutPal;
    let reloadInside = props.reloadInside;
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [labelNavette, setLabelNavette] = useState("");
    const [colorSaveLabel, setColorSaveLabel] = useState("transparent");
    const navigate = useNavigate();
    
    function handleChangeLabel(e){
        let value = e.target.value;
        setLabelNavette(value);
    }

    function saveLabelToApi(){
        changeLabelNavette("/navettes/"+id_navette, labelNavette).then(result => {
            if(result['Label'] === labelNavette){
                setColorSaveLabel("green");
                setTimeout(
                    function() {
                        setColorSaveLabel("transparent");
                    }, 1000);
            }else{
                setColorSaveLabel("red");
                setTimeout(
                    function() {
                        setColorSaveLabel("transparent");
                    }, 1000);
            }
        });
    }
    
    useEffect(() => {

        setLoading(true);
        getOneNavette(id_navette).then(navettes => {
                    setData(navettes);
                    setLoading(false);
                    setLabelNavette(navettes.Label);
        });
      
    }, [id_navette, reloadInside]);


    return (<>
    
    
    {loading?  <LinearProgress/> :
    <><Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
        <Grid item xs={6} md={3}>
        <IconButton color="primary" aria-label="Go back" component="label" onClick={()=>{navigate("/charger")}}>
            <ArrowBackIcon/>
        </IconButton>
        <h1>Detail de la navette n°{id_navette}</h1>
        </Grid>
        <Grid item xs={6} md={3} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
            <ChannelsPresenter channels={data.channels}/>
        </Grid>
        <Grid item xs={6} md={3} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', m: 1 }}>
                        <DownloadpdfList sx={{m: 1}} type={"Invoice"} navette={data} />
                        <DownloadpdfList sx={{m: 1}} type={"DeliverySlip"} navette={data} />
                        {canAccess(['ROLE_RESPONSABLE', 'ROLE_ADMIN'])?  
                        <Button style={{marginLeft: 10}} variant="contained" onClick={()=>{navigate("/navettes/"+id_navette+"/details")}}>Vue détaillée</Button>
                        :
                        <></>
                        }
                    </Box>
        </Grid>
        <Grid item xs={6} md={3} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
            <TransporteurPresenter component="chip" transporteur={data.carrier}/>
            <div style={{display: "flex", flexDirection: "column", marginLeft: "auto"}}>
                <TextField value={labelNavette} onChange={handleChangeLabel} id="filled-basic" label="Label" variant="filled"/>
                <Button style={{backgroundColor: colorSaveLabel, color: colorSaveLabel === "transparent"? "": "white"}} onClick={saveLabelToApi}>Valider</Button>
            </div>
        </Grid>
        <Grid item xs={6} md={2} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
            Commandes: {data.orderPreparations.length}
        </Grid>
        <Grid item xs={6} md={2} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
            Palettes: {data.palettes.length}
        </Grid>
        <Grid item xs={6} md={2} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
            Poids Total: {data.weight /1000}kg
        </Grid>
        <Grid item xs={6} md={2} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
            Volume: {data.volume}m³
        </Grid>
        <Grid item xs={6} md={2} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
            Arrivée le: {new Date(Date.parse(data.estimatedAt)).toLocaleDateString("fr") + " à " + new Date(Date.parse(data.estimatedAt)).toLocaleTimeString("fr")}
        </Grid>
        </Grid>
    </Box>
    <hr></hr>

    <ListingPalettesInNavette handleReloadWithoutPal={handleReloadWithoutPal} reloadInside={reloadInside} id_navette={id_navette}/>
    </>
    }
    
    
    </>);
}

export default NavetteDetailsTablePaletteInside;