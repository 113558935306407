import { CircularProgress } from "@mui/material";
import { useState, useEffect } from "react";
import { getAgencesList } from "../../Services/Agences/Agences";
import AgencePresenter from "./AgencePresenter";

const AgencesListPresenter = (props) =>{
    let agenceslist = props.agences;
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);

    const [newAgenceList, setNewAgenceList] = useState(null);
    
    useEffect(() => {

        let agenceslistformated = [];
        agenceslist.forEach((agence)=>{
            agenceslistformated.push(agence['@id']);
        });
        setNewAgenceList(agenceslistformated);
        setLoading(true);
        getAgencesList().then(carriers => {
                    setData(carriers['hydra:member']);
                    setLoading(false);
        });
      
    }, [agenceslist]);
    
    if(agenceslist === null) return <></>;
    if(loading){
        return <CircularProgress/>
    }else{
        return (<span>
            {
                data.map((agence)=>{
                    return newAgenceList.includes(agence['@id'])? <AgencePresenter key={agence['@id']} agence={agence}/>: <></>
                })

            }
        
        </span>);
    }
}
export default AgencesListPresenter;