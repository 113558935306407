import * as React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {LinearProgress} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

function LinearProgressWithLabel(props) {
    return (
        <Box style={{overflow: "hidden"}} sx={{ position: 'relative', with: '100%', marginTop: "10px", overflow: "hidden" }}>
            <LinearProgress sx={{ height: 25, overflow: "hidden" }} variant="determinate" color={props.percent >= 100 ? 'success' : 'primary'} value={props.percent} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography variant="caption" component="div" color="white" fontWeight="bolder" marginTop="3px">
                    {props.content}
                </Typography>
            </Box>
        </Box>
    );
}

LinearProgressWithLabel.propTypes = {
    percent: PropTypes.number.isRequired,
    content: PropTypes.string.isRequired,
};

export default function LinearProgressWithLabelStatic(props) {
    const progress = props.info;
    const content = progress ? progress.content : "";
    const percent = progress ? progress.percent : "0";
    const currentStep = progress ? progress.currentStep : "";

    if(!currentStep){
        if(percent < 100){
            return <CloseIcon style={{color: "red", width: "20px", marginLeft:"auto", marginRight: "auto"}}/>
        } else {
            return <CheckIcon style={{color: "green", width: "20px", marginLeft:"auto", marginRight: "auto"}}/>
        }
    }
    return <LinearProgressWithLabel percent={percent} content={content}/>;
}
