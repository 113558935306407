import { useState } from "react";
import { updatePaletteFieldInTable } from "../../Services/Palettes/Palettes";

const UpdateFieldInTable = (props)=>{
    let field = props.field;
    let defaultvalue = props.value;
    let target = props.target;

    const [value, setValue] = useState(defaultvalue);

    function handleChange(e) {
        setValue(e.target.value);
        if(field === "weight") {
           updatePaletteFieldInTable(target, field, e.target.value*1000);
        }else{
           updatePaletteFieldInTable(target, field, e.target.value);
        }
    }

    return <input style={{textAlign: "center", width: "75px"}} onChange={handleChange} value={value} type="text"/>
}
export default UpdateFieldInTable;
