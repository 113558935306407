import { Chip } from "@mui/material";

const AttributesToArrayDisplayer = (props) =>{
    let attributes = props.attributes;
    let withCustom = props.withCustom;
    return(<div style={{display: "flex", gap: "5px", flexWrap: "wrap"}}>
    
        {

            attributes.map((attribute)=>{
                return <><Chip label={attribute[0] + " - " + attribute[1]}/></>
            })

            
        }
        {(withCustom && withCustom !== "")? <Chip label={withCustom}/>:<></>}
    </div>)

}

export default AttributesToArrayDisplayer;