
import LinearProgressWithLabelStatic from "../Progress/LinearProgressWithLabelField";

const StepSpecificDisplayer = (props) =>{
    let data = props.data;
    let stepSelected = props.stepSelected;
    let planned = data.planned;
    let prepared = data.prepared;
    let controlled = data.controlled;
    let controlledResponsible = data.controlledResponsible;
    let packed = data.packed;
    let charged = data.charged;
    
    let currentStep;
    if(stepSelected === "plan"){
      currentStep = planned;
    }else if(stepSelected === "prepare"){
      currentStep = prepared;
    }else if(stepSelected === "control"){
      currentStep = controlled;
    }else if(stepSelected === "control-responsible"){
      currentStep = controlledResponsible;
    }else if(stepSelected === "pack"){
      currentStep = packed;
    }else if(stepSelected === "charge"){
      currentStep = charged;
    }else if(stepSelected === "expect"){
      currentStep = "Expédié";
      return "Expédié";
    }else{
      currentStep = "error";
      return "error";
    }
  
    if(currentStep !== "error" && currentStep !== "Expédié"){
      return (
        <>
          <LinearProgressWithLabelStatic info={currentStep}/>
        </>
  
      );
     
    }
}

export default StepSpecificDisplayer;