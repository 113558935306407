import { useEffect, useState } from "react";
import { getPrepPalettes } from "../../Services/Commandes/Commandes";
import { Button, LinearProgress } from "@mui/material";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import ButtonPalettePrint from "../../component/Palettes/ButtonPrint";
import ChangeType from "../../component/Palettes/ChangeType";
import DeleteButtonWithConfirm from "../../component/Palettes/ButtonDeletePalette";
import UpdateFieldInTable from "../../component/Palettes/UpdateFieldInTable";
import AddPalette from "../../component/Palettes/AddPalette";



const CommandesDetailsPalettesList = (props)=>{
    let prepID = props.order_prep_id;
    let orderPreparation = props.orderPreparation;
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [reload, setReload] = useState(false);

  

    function handleReload(){
        setReload(!reload);
    }

    useEffect(() => {
        setLoading(true);
        getPrepPalettes(prepID).then((order_prep_items)=>{
            setData(order_prep_items['hydra:member']);
            setLoading(false);
        })
        
    }, [prepID, reload]);

    if(loading) return (<LinearProgress/>); 
    return(<>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Actions</TableCell>
            <TableCell align="right">Numéro</TableCell>
            <TableCell align="right">Type</TableCell>
            <TableCell align="right">Qté Produits</TableCell>
            <TableCell align="right">Longeur cm</TableCell>
            <TableCell align="right">Largeur cm</TableCell>
            <TableCell align="right">Hauteur cm</TableCell>
            <TableCell align="right">Volume</TableCell>
            <TableCell align="right">Poids</TableCell>
            <TableCell align="right">Emplacement</TableCell>
            <TableCell align="right">Contrôlé</TableCell>
            <TableCell align="right">Emballé</TableCell>
            <TableCell align="right">Chargé</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow
              key={row['@id']}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" style={{display: "flex"}}>
                <ChangeType actual_palette={row}/>
                <ButtonPalettePrint palette={row} orderPreparation={orderPreparation}/>
                <DeleteButtonWithConfirm handleReload={handleReload} id_palette={row['@id']} />
              </TableCell>
              <TableCell align="right">{row.number}</TableCell>
              <TableCell align="right">{row.type.name}</TableCell>
              <TableCell align="right">{row.products}</TableCell>
              <TableCell align="right"><UpdateFieldInTable target={row['@id']} field="length" value={row.length}/></TableCell>
              <TableCell align="right"><UpdateFieldInTable target={row['@id']} field="width" value={row.width}/></TableCell>
              <TableCell align="right"><UpdateFieldInTable target={row['@id']} field="height" value={row.height}/></TableCell>
              <TableCell align="right">{(((row.length/100) * (row.width/100) * (row.height/100))).toFixed(2)} m³</TableCell>
              <TableCell align="right"><UpdateFieldInTable target={row['@id']} field="weight" value={row.weight /1000}/> kg</TableCell>
              <TableCell align="right"><span style={{backgroundColor: row.spot.color, fontWeight: "bold", padding:"5px", borderRadius: "5px", whiteSpace: "nowrap"}}>{row.spot.spot}</span></TableCell>
              
              <TableCell align="right">{row.controlled? <CheckIcon style={{color: "green"}} />:<CloseIcon style={{color: "red"}}/>}</TableCell>
              <TableCell align="right">{row.packed? <CheckIcon style={{color: "green"}} />:<CloseIcon style={{color: "red"}}/>}</TableCell>
              <TableCell align="right">{row.charged? <CheckIcon style={{color: "green"}} />:<CloseIcon style={{color: "red"}}/>}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <AddPalette prepID={prepID} handleReload={handleReload}/>
    </>)
}
export default CommandesDetailsPalettesList;