import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {getIdWithEmail, getRoles} from '../../Services/Auth/Authentification';
import './Navbar.css';
import { NavLink as Link } from 'react-router-dom';
import MyProfile from '../../component/Profile/MyProfile';
import MyTeams from '../../component/Teams/MyTeams';
import AddToTeam from "../../component/Teams/AddToTeam";

import DashboardIcon from '@mui/icons-material/Dashboard';
import AssignmentIcon from '@mui/icons-material/Assignment';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import GroupIcon from '@mui/icons-material/Group';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import BorderAllIcon from '@mui/icons-material/BorderAll';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import CategoryIcon from '@mui/icons-material/Category';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import PlaceIcon from '@mui/icons-material/Place';
import ReportIcon from '@mui/icons-material/Report';
import AssistantPhotoIcon from '@mui/icons-material/AssistantPhoto';
import BuildIcon from '@mui/icons-material/Build';
import StorefrontIcon from '@mui/icons-material/Storefront';
import ApartmentIcon from '@mui/icons-material/Apartment';
import BrandingWatermarkIcon from '@mui/icons-material/BrandingWatermark';
import { Badge } from '@mui/material';

import { styled } from '@mui/material/styles';
import { getStatsACharger, getStatsAController, getStatsAControllerResp, getStatsAEmballer, getStatsAPreparer } from '../../Services/Commandes/Commandes';
import ScanContext from '../../component/Scan/ScanContext';

const Navbar = (props) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [drawerWidth, setDrawerWidth] = React.useState(260);
  const [hiddenText, setHiddenText] = React.useState(false);

  const [reloadTeam, setReloadTeam] = React.useState(false);
  const [reloadCounter, setReloadCounter] = React.useState(false);

  const [statsPrepared, setStatsPrepared] = React.useState(0);
  const [statsControlled, setStatsControlled] = React.useState(0);
  const [statsControlledResp, setStatsControlledResp] = React.useState(0);
  const [statsEmballer, setStatsEmballer] = React.useState(0);
  const [statsCharger, setStatsCharger] = React.useState(0);

  const [canControl, setCanControl] = React.useState(false);

  React.useEffect(() => {

    getStatsAPreparer().then((number)=>{
      setStatsPrepared(number);
    });

    getStatsAController().then((number)=>{
      setStatsControlled(number);
    });

    getStatsAControllerResp().then((number)=>{
      setStatsControlledResp(number);
    });

    getStatsAEmballer().then((number)=>{
      setStatsEmballer(number);
    });

    getStatsACharger().then((number)=>{
      setStatsCharger(number);
    });

    getIdWithEmail().then((data)=>{
      setCanControl(data['hydra:member'][0]['canControl']);
    })
    
  }, [reloadCounter]);

  function ReloadCounter(){
    setReloadCounter(!reloadCounter);
  }

  function ReloadTeam(){
    setReloadTeam(!reloadTeam);
  }

  React.useEffect(() => {
    setInterval(() => {
      ReloadCounter();
    }, 60000);
  }, []);


  const handleDrawerToggle = () => {
    setDrawerWidth(240);
    setMobileOpen(!mobileOpen);
  };

  const handleHideTextToggle = () => {
    if(hiddenText === false){
      setDrawerWidth(73);
    }else{
      setDrawerWidth(260);
    }
    setHiddenText(!hiddenText);
  };

  const ToggleTextSeparator = (props) => {
    if(!hiddenText){
      return(
        <Divider style={{overflow: "hidden"}}><span style={{padding: "0", width: "90px"}}>{props.name}</span></Divider>
      )
    }else{
      return(
        <Divider/>
      )
    }
  }

  const ItemsResp = (props) => {
    if(getRoles().includes("ROLE_RESPONSABLE")){
      return(
        <>
          <ToggleTextSeparator name="RESP"/>
          <List>
          <ListItem key={"Utilisateurs"} disablePadding>
                  <Link to={'/utilisateurs'} style={{width:"100%"}}>
                  <ListItemButton>
                    <ListItemIcon>
                      <GroupIcon />
                    </ListItemIcon>
                    <span>Utilisateurs</span>
                  </ListItemButton>
                </Link>
          </ListItem>
          <ListItem key={"Preparations"} disablePadding>
                <Link to={'/preparations'} style={{width:"100%"}}>
                  <ListItemButton>
                    <ListItemIcon>
                      <ShoppingCartIcon />
                    </ListItemIcon>
                    <span>Préparations</span>
                  </ListItemButton>
                </Link>
          </ListItem>
          <ListItem key={"Palettes"} disablePadding>
              <Link to={'/palettes'} style={{width:"100%"}}>
                  <ListItemButton>
                    <ListItemIcon>
                      <BorderAllIcon />
                    </ListItemIcon>
                    <span>Palettes</span>
                  </ListItemButton>
                </Link>
          </ListItem>
          <ListItem key={"Navettes"} disablePadding>
              <Link to={'/navettes'} style={{width:"100%"}}>
                  <ListItemButton>
                    <ListItemIcon>
                      <AirportShuttleIcon />
                    </ListItemIcon>
                    <span>Navettes</span>
                  </ListItemButton>
                </Link>
          </ListItem>
          <ListItem key={"Catalogue"} disablePadding>
              <Link to={'/catalogue'} style={{width:"100%"}}>
                  <ListItemButton>
                    <ListItemIcon>
                      <CategoryIcon />
                    </ListItemIcon>
                    <span>Catalogue</span>
                  </ListItemButton>
                </Link>
          </ListItem>
          <ListItem key={"Emplacement Entrepôt"} disablePadding>
              <Link to={'/emplacements'} style={{width:"100%"}}>
                  <ListItemButton>
                    <ListItemIcon>
                      <PlaceIcon />
                    </ListItemIcon>
                    <span>Emplacement Entrepôt</span>
                  </ListItemButton>
                </Link>
          </ListItem>
          <ListItem key={"Souffrances"} disablePadding>
              <Link to={'/souffrances'} style={{width:"100%"}}>
                  <ListItemButton>
                    <ListItemIcon>
                      <ReportIcon />
                    </ListItemIcon>
                    <span>Souffrances</span>
                  </ListItemButton>
                </Link>
          </ListItem>
          <ListItem key={"Transporteurs"} disablePadding>
              <Link to={'/transporteurs'} style={{width:"100%"}}>
                  <ListItemButton>
                    <ListItemIcon>
                      <LocalShippingIcon />
                    </ListItemIcon>
                    <span>Transporteurs</span>
                  </ListItemButton>
                </Link>
          </ListItem>
        </List>
        </>
      )
    } else{
      return(<></>);
    }
    
  }





  const ItemsAdmin = (props) => {
    if(getRoles().includes("ROLE_ADMIN")){
      return(
        <>
          <ToggleTextSeparator name="ADMIN"/>
          <List>
            <ListItem key={"Dépôts"} disablePadding>
                <Link to={'/depots'} style={{width:"100%"}}>
                    <ListItemButton>
                      <ListItemIcon>
                        <WarehouseIcon />
                      </ListItemIcon>
                      <span>Dépôts</span>
                    </ListItemButton>
                  </Link>
            </ListItem>
            <ListItem key={"Utilisateurs"} disablePadding>
                  <Link to={'/utilisateurs'} style={{width:"100%"}}>
                  <ListItemButton>
                    <ListItemIcon>
                      <GroupIcon />
                    </ListItemIcon>
                    <span>Utilisateurs</span>
                  </ListItemButton>
                </Link>
            </ListItem>
            <ListItem key={"État des commandes"} disablePadding>
                <Link to={'/etats'} style={{width:"100%"}}>
                  <ListItemButton>
                    <ListItemIcon>
                      <AssistantPhotoIcon />
                    </ListItemIcon>
                    <span>État des commandes</span>
                  </ListItemButton>
                </Link>
            </ListItem>
            <ListItem key={" Étape des commandes"} disablePadding>
                <Link to={'/etapes'} style={{width:"100%"}}>
                  <ListItemButton>
                    <ListItemIcon>
                      <BuildIcon />
                    </ListItemIcon>
                    <span> Étape des commandes</span>
                  </ListItemButton>
                </Link>
            </ListItem>
            <ListItem key={"Emplacement Entrepôt"} disablePadding>
                <Link to={'/emplacements'} style={{width:"100%"}}>
                  <ListItemButton>
                    <ListItemIcon>
                      <PlaceIcon />
                    </ListItemIcon>
                    <span>Emplacement Entrepôt</span>
                  </ListItemButton>
                </Link>
            </ListItem>
            <ListItem key={"Transporteurs"} disablePadding>
                <Link to={'/transporteurs'} style={{width:"100%"}}>
                  <ListItemButton>
                    <ListItemIcon>
                      <LocalShippingIcon />
                    </ListItemIcon>
                    <span>Transporteurs</span>
                  </ListItemButton>
                </Link>
            </ListItem>
            <ListItem key={"Canaux"} disablePadding>
                <Link to={'/canaux'} style={{width:"100%"}}>
                  <ListItemButton>
                    <ListItemIcon>
                      <StorefrontIcon />
                    </ListItemIcon>
                    <span>Canaux</span>
                  </ListItemButton>
                </Link>
            </ListItem>
            <ListItem key={"Agences"} disablePadding>
                <Link to={'/agences'} style={{width:"100%"}}>
                  <ListItemButton>
                    <ListItemIcon>
                      <ApartmentIcon />
                    </ListItemIcon>
                    <span>Agences</span>
                  </ListItemButton>
                </Link>
            </ListItem>
            <ListItem key={"Marques"} disablePadding>
                <Link to={'/marques'} style={{width:"100%"}}>
                  <ListItemButton>
                    <ListItemIcon>
                      <BrandingWatermarkIcon />
                    </ListItemIcon>
                    <span>Marques</span>
                  </ListItemButton>
                </Link>
            </ListItem>
          </List>
        </>
      )
    } else{
      return(<></>);
    }
    
  }
  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: 27,
      top: 13,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px'
    },
  }));

  const drawer = (
    <div>
      <Toolbar />
      <List>
            <ListItem key={"Dashboard"} disablePadding>
                <Link to={'/'} style={{width:"100%"}}>
                  <ListItemButton>
                    <ListItemIcon>
                      <DashboardIcon />
                    </ListItemIcon>
                    <span>Tableau de bord</span>
                  </ListItemButton>
                </Link>
            </ListItem>
      </List>
      <ToggleTextSeparator name="LOG"/>
      <List>
        <ListItem key={"Préparer"} disablePadding>
              <Link to={'/preparation'} style={{width:"100%"}}>
                    <ListItemButton>
                      <StyledBadge badgeContent={statsPrepared} color="primary">
                        <ListItemIcon>
                          <AssignmentIcon />
                        </ListItemIcon>
                      </StyledBadge>
                      <span>Préparer</span>
                    </ListItemButton>
              </Link>
        </ListItem>
        <ListItem key={"Contrôler"} disablePadding>
              <Link to={'/controler'} style={{width:"100%"}}>
                    <ListItemButton>
                      <StyledBadge badgeContent={statsControlled} color="primary">
                        <ListItemIcon>
                          <FactCheckIcon />
                        </ListItemIcon>
                      </StyledBadge>
                      <span>Contrôler</span>
                    </ListItemButton>
              </Link>
        </ListItem>
        {getRoles().includes("ROLE_ADMIN") || getRoles().includes("ROLE_RESPONSABLE") || canControl? 
        <ListItem key={"Contrôler Responsable"} disablePadding>
              <Link to={'/controler-resp'} style={{width:"100%"}}>
                    <ListItemButton>
                      <StyledBadge badgeContent={statsControlledResp} color="primary">
                        <ListItemIcon>
                          <FactCheckIcon />
                        </ListItemIcon>
                      </StyledBadge>
                      <span>Contrôler Responsable</span>
                    </ListItemButton>
              </Link>
        </ListItem>
        : <></>
        }
        <ListItem key={"Emballer"} disablePadding>
              <Link to={'/emballer'} style={{width:"100%"}}>
                    <ListItemButton>
                      <StyledBadge badgeContent={statsEmballer} color="primary">
                        <ListItemIcon>
                          <AutoAwesomeMotionIcon />
                        </ListItemIcon>
                      </StyledBadge>
                      <span>Emballer</span>
                    </ListItemButton>
              </Link>
        </ListItem>
        <ListItem key={"Charger"} disablePadding>
              <Link to={'/charger'} style={{width:"100%"}}>
                    <ListItemButton>
                      <StyledBadge badgeContent={statsCharger} color="primary">
                        <ListItemIcon>
                          <LocalShippingIcon />
                        </ListItemIcon>
                      </StyledBadge>
                      <span>Charger</span>
                    </ListItemButton>
              </Link>
        </ListItem>
      </List>
      <ItemsResp/>
      <ItemsAdmin/>
      
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;
  
  

  return (
    <Box id="spaceappBar" sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <IconButton onClick={handleHideTextToggle} sx={{ mr: 2, display: { xs: 'none', sm: 'block' }, color: "white" }} >
            <MenuIcon />
          </IconButton>
          <ScanContext/>
          <Typography variant="h6" noWrap component="div" style={{width: "300px"}}>
              LOGISTIQUE
          </Typography>
          <div style={{width: "100%", display: "flex", justifyContent: "right", alignItems: "center", gap: "10px"}}>
              <MyTeams handleReload={ReloadTeam}/>
              <AddToTeam handleReload={ReloadTeam}/>
              <MyProfile/>
          </div>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, 
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar />
        {props.outlet}
      </Box>
    </Box>
  );
}

Navbar.propTypes = {
  window: PropTypes.func,
};

export default Navbar;