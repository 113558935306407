import { LinearProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { getOnePalette } from "../../Services/Palettes/Palettes";
import AttributesToArrayDisplayer from "./AttributeToArrayDisplayer";

const DisplayAttribAndCustomFromPaletteItem = (props) =>{
    let id_palette_item = props.id_palette_item;
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        setData(null);
        getOnePalette(id_palette_item).then((palette_item) =>{
            getOnePalette(palette_item.orderPreparationItem).then((orderPreparationItem) =>{
                setData(orderPreparationItem);
                setLoading(false);
            });
        })
    
    }, [id_palette_item]);
    if (loading) return <LinearProgress/>;
    
    return (<AttributesToArrayDisplayer attributes={data.product.attributesToArray} withCustom={data.custom}/>)
}
export default DisplayAttribAndCustomFromPaletteItem;
