import { canAccess } from "../../Services/Auth/Authentification";
import { Navigate } from "react-router-dom";
import DepotsList from "./DepotsList";

const Depots = (props) => {
    let permNeeded = props.permNeeded;
    

    if(!canAccess(permNeeded)){
        return(
            <Navigate to={"/"}/>
        )
    }else{
        

        
        return(<>
        
            <h1>Liste des Dépôts</h1>
            <hr></hr>
            <DepotsList/>
        </>)
    }
}
export default Depots;