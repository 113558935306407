import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { getOneProduct } from "../../Services/Catalogue/Catalogue";
import ScaleIcon from '@mui/icons-material/Scale';

const ProductCondiAndWeightDisplayer = (props) =>{
    let id_product = props.id_product;
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    useEffect(() => {
        setLoading(true);
        getOneProduct(id_product).then((data)=>{
            setData(data);
            setLoading(false);
        });
    }, [id_product]);


    if (loading) return <CircularProgress/>;

    return (<div style={{textAlign: "center"}}>
        <p>{capitalizeFirstLetter(data.packagingType)} de {data.packagingQuantity} {capitalizeFirstLetter(data.packagingUnit)}(s)</p>
        <p style={{display: "flex", alignItems: "center", justifyContent: "center"}}><ScaleIcon/> {data.weight/1000}kg/{data.packagingUnit}</p>
    </div>)
}
export default ProductCondiAndWeightDisplayer;
