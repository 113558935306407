import { canAccess } from "../../Services/Auth/Authentification";
import { Navigate } from "react-router-dom";
import SouffrancesList from "./SouffrancesList";

const Souffrances = (props) => {
    let permNeeded = props.permNeeded;
    

    if(!canAccess(permNeeded)){
        return(
            <Navigate to={"/"}/>
        )
    }else{
        

        
        return(<>
        
            <h1>Liste des Souffrances</h1>
            <hr></hr>
            <SouffrancesList/>
        </>)
    }
}
export default Souffrances;