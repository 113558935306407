import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useState, useEffect } from 'react';
import { getOneNavette, getPalettesInNavette, setChargedNavette } from '../../Services/Navettes/Navettes';
import { Checkbox, LinearProgress } from '@mui/material';
import ChannelsPresenter, { ChannelsPresenterWithoutFetch } from '../Channels/ChannelsPresenter';
import DeletePaletteFromNavette from './DeletePaletteFromNavette';
import {Button} from '@mui/material';
import { ToggleChargedPalette } from '../../Services/Palettes/Palettes';
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { GoToPaletteDetail } from '../../Pages/Palettes/PaletteDetail';

const ChargerDechargerPalette = (props) =>{
    
    let charged = props.charged;
    let id_palette = props.id_palette;
    let handleReload = props.handleReload;
    let id_navette = props.id_navette;

    function toggleChargement(){
        ToggleChargedPalette(id_palette, !charged).then(data =>{handleReload(false);});
        if(charged === true){
           setChargedNavette("/navettes/"+id_navette, false);
        }
    }


    if(charged){
        return(<Button onClick={toggleChargement} variant="contained" color="error">Décharger</Button>)
    }else{
        return(<Button onClick={toggleChargement} variant="contained" color="success">Charger</Button>)
    }

    
}


const ListingPalettesInNavette = (props) =>{
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(true);
    const [reload, setReload] = useState(false);
    const [canValide, setCanValide] = useState(false);
    const [navetteIsCharged, setNavetteIsCharged] = useState(false);
    let id_navette = props.id_navette;
    let reloadInside = props.reloadInside;
    let handleReloadWithoutPal = props.handleReloadWithoutPal;
    const [checkboxes, setCheckboxes] = useState([]);
  
    const handleCheckboxChange = (e) => {
      const { value, checked } = e.target;
      if (checked) {
        setCheckboxes([...checkboxes, value]);
      } else {
        setCheckboxes(checkboxes.filter(item => item !== value));
      }
    }

    function chargeAll() {
      if (checkboxes.length > 0) {
        setCheckboxes([]);
        const promises = checkboxes.map(value => ToggleChargedPalette(value, true));
        Promise.all(promises)
          .then(() => {
            setChargedNavette("/navettes/" + id_navette, false);
            handleReload(false);
          })
          .catch(error => {
          });
      }
    }
    
    function dechargeAll() {
      if (checkboxes.length > 0) {
        setCheckboxes([]);
        const promises = checkboxes.map(value => ToggleChargedPalette(value, false));
        Promise.all(promises)
          .then(() => {
            handleReload(false);
          })
          .catch(error => {
          });
      }
    }

    function handleReload(toWithOutPalette){
        if(toWithOutPalette === false){
          setReload(!reload);
        }else{
          setReload(!reload);
          handleReloadWithoutPal();
        }
    }


    function validateChargement(){
        setChargedNavette("/navettes/"+id_navette, true);
        handleReload(false);
    }

    function UnValidateChargement(){
        setChargedNavette("/navettes/"+id_navette, false);
        handleReload(false);
    }

    useEffect(() => {
      
        setLoading(true);
        getPalettesInNavette(id_navette).then(palettes => {
                    setLoading(false);
                    setData(palettes['hydra:member']);
                    var canValideTest = true;
                    palettes['hydra:member'].forEach((palette)=>{
                        if(palette.charged === false){
                            canValideTest = false;
                        }
                    });
                    setCanValide(canValideTest);
                    getOneNavette(id_navette).then((navette)=>{
                        if(navette.charged){
                            setNavetteIsCharged(true);
                        }else{
                            setNavetteIsCharged(false);
                        }
                    });
        });
      
    }, [id_navette, reload, reloadInside]);

    return (<>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Palette n°</TableCell>
                <TableCell align="right">Commande</TableCell>
                <TableCell align="right">Paiement</TableCell>
                <TableCell align="right">Numéro</TableCell>
                <TableCell align="right">Type</TableCell>
                <TableCell align="right">Qté Produits</TableCell>
                <TableCell align="right">Longueur cm</TableCell>
                <TableCell align="right">Largeur cm</TableCell>
                <TableCell align="right">Hauteur cm</TableCell>
                <TableCell align="right">Volume m³</TableCell>
                <TableCell align="right">Poids</TableCell>
                <TableCell align="right">Actions</TableCell>
                <TableCell align="right">Emplacement</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <>
                {checkboxes.length > 0 ? <TableRow><TableCell colSpan={14}><div style={{display:"flex", flexDirection: "row", width: "100%", justifyContent: "center", marginTop: "5px", marginBottom: "15px", gap: "15px", width: "100%"}}><Button variant="contained" color="success" onClick={chargeAll}>Charger</Button><Button variant="contained" color="error" onClick={dechargeAll}>Décharger</Button></div></TableCell></TableRow> : ""}
              </>
              {loading? <LinearProgress/>: data.map((row) => (
                
                <TableRow
                  key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  {console.log(row)}
                  <TableCell><Checkbox value={row['@id']} onChange={handleCheckboxChange} /></TableCell>
                  <TableCell component="th" scope="row">
                    <GoToPaletteDetail id_palette={row['@id'].replace('/palettes/', '')} text={"n°"+row['@id'].replace('/palettes/', '')}/>
                  </TableCell>
                  <TableCell align="right"><ChannelsPresenterWithoutFetch customer={row.orderPreparation.customer} channel={row.orderPreparation.channel} number={row.orderPreparation.orderId} id_prep={row.orderPreparation['@id'].replace("/order_preparations/", "")}/></TableCell>
                  <TableCell align="right">{row.orderPreparation.payment? <CheckIcon style={{color: "green"}} />:<CloseIcon style={{color: "red"}}/>}</TableCell>
                  <TableCell align="right">{row.number}</TableCell>
                  <TableCell align="right">{row.typeName}</TableCell>
                  <TableCell align="right">{row.products}</TableCell>
                  <TableCell align="right">{row.length}</TableCell>
                  <TableCell align="right">{row.width}</TableCell>
                  <TableCell align="right">{row.height}</TableCell>
                  <TableCell align="right">{parseFloat((row.length/100)*(row.width/100)*(row.height/100)).toFixed(2)}</TableCell>
                  <TableCell align="right">{(row.weight/1000).toFixed(2)}kg</TableCell>
                  <TableCell align="center"><DeletePaletteFromNavette id_palette={row['@id']} handleReload={handleReload}/></TableCell>
                  <TableCell align="right"><ChargerDechargerPalette id_palette={row['@id']} id_navette={id_navette} handleReload={handleReload} charged={row.charged}/></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div style={{textAlign: "center", paddingTop: "10px"}}>
        {
        navetteIsCharged? <Button onClick={UnValidateChargement} variant="contained" color="error">Marquer la navette comme déchargée</Button> :
        
        canValide? 
            <Button onClick={validateChargement} variant="contained" color="success">Valider le chargement global et le départ de la navette</Button>
            
            :
            
            <Button variant="contained" disabled={true}>Valider le chargement global et le départ de la navette</Button>}
        </div>
        </>
    );
}
export default ListingPalettesInNavette;