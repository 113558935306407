import { ENTRY_POINT } from "../../global-config";
import { getToken, ValidateToken } from "../Auth/Authentification";

export async function getTransporteursList(){
    const options = {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + getToken()
        }
      };
      
    let data = await fetch(ENTRY_POINT+"/carriers", options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => {
            console.error(error);
        });
    
    if(ValidateToken(data) || data !== undefined){
        return data;
    }else{
        return "error";
    }
}

export async function getOneTransporteurs(id){
    const options = {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + getToken()
        }
      };
      
    let data = await fetch(ENTRY_POINT+id, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => {
            console.error(error);
        });
    
    if(ValidateToken(data) || data !== undefined){
        return data;
    }else{
        return "error";
    }
}

export function editTransporteurs(id, name, color, maxweight){
    
    const options = {
        method: 'PUT',
        headers: {
        'Content-Type': 'application/ld+json',
        Authorization: 'Bearer ' + getToken()
        },
        body: '{"name":"'+name+'", "color":"'+color+'", "maxWeightPerPalette":'+maxweight+'}'
    };
  
    let data = fetch(ENTRY_POINT+id, options)
            .then((response) => response.json())
            .then(data => {
                return data;
            })
            .catch(error => {
                console.error(error);
            });


    
    if(ValidateToken(data)){
        return data;
    }else{
        return "error";
    }
}