import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { FilledInput } from '@mui/material';
import './fixCssFilter.css';
import { getStepsList } from '../../Services/Commandes/Commandes';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


function getStyles(name, stepName, theme) {
  return {
    fontWeight:
      stepName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function StepsMultipleSelect(props) {
  const theme = useTheme();
  let filterStepsName = props.filterStepsName;
  let setFilterStepsName = props.setFilterStepsName;
  const [stepName, setstepName] = React.useState(filterStepsName);
  const [stepsList, setStepList] = React.useState([]);

  React.useEffect(() => {
    getStepsList().then(steps => {
        setStepList(steps);
    })
  }, []);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setstepName(
      typeof value === 'string' ? value.split(',') : value,
    );
    setFilterStepsName(typeof value === 'string' ? value.split(',') : value,)
    let stringFilter = "";
    value.forEach(step=>{
        stringFilter += "&step[]="+step;
    });
    props.setFilterSteps(stringFilter);
  };

  return (
    <div>
      <FormControl sx={{ m: 1, width: 300 }} className="multipleSelect">
        <InputLabel>Étapes</InputLabel>
        <Select
          multiple
          margin="dense"
          value={stepName}
          onChange={handleChange}
          variant="filled"
          input={<FilledInput margin="dense" label="Étapes" />}
          MenuProps={MenuProps}
        >
          {stepsList.map((step) => (
            <MenuItem
              key={step['@id']}
              value={step['@id']}
              style={getStyles(step, stepName, theme)}
            >
              {step['name']}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
