import { Link } from "react-router-dom";

const NoPage = ()  => {
    return (
        <p>Erreur 404 
            <li>
                <Link to="/">Retour au Tableau de bord</Link>
            </li>
        </p>
    )
}

export default NoPage;