
import {Button, Tooltip} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React from "react";
import Webcam from "react-webcam";
import { useCallback, useRef, useState, useEffect } from "react";
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';
import { postMediaPreps } from "../../Services/MediaPreps/MediaPreps";
import { getIdWithEmail } from "../../Services/Auth/Authentification";

function dataURLtoFile(dataurl, filename) {
 
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
        
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    
    return new File([u8arr], filename, {type:mime});
}



const WebcamCapture = (props) => {
    const webcamRef = useRef(null);
    const [imgSrc, setImgSrc] = useState(null);
    const [turn, setTurn] = useState(false);
    const[file, setFile] = useState(null);
    const capture = useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        setImgSrc(imageSrc);
        setFile(dataURLtoFile(imageSrc, props.prep["@id"].replaceAll("/", "_")+".jpg"));
    }, [webcamRef, setImgSrc]);

    function toggleTurn(){
        setTurn(!turn);
    }
    let facingmode = "user";
    if(turn){
        facingmode = { exact: "environment" };
    }else{
        facingmode = "user"
    }

    const videoConstraints = {
    width: 640,
    height: 480,
    facingMode: facingmode,
    };

    function SaveToApi(){
        
        getIdWithEmail().then((result) =>{
            const FD  = new FormData();
            FD.append('file', file);
            FD.append('user', result['hydra:member'][0]['@id']);
            FD.append('id_prep', props.prep.id);
            postMediaPreps(FD).then((data)=>{
                setImgSrc(null);
                setFile(null);
            });
        });
        
    }

    return (
        <>
        <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpg"
            videoConstraints={videoConstraints}
            minScreenshotWidth={1080} //Reglage taille de screenshoot
            minScreenshotHeight={720}
        /><br></br>
        <div style={{display:"flex",justifyContent:"center",gap:"15px"}}>
            <Button onClick={toggleTurn} variant="contained" endIcon={<CameraswitchIcon />}>
                Pivoter la caméra
            </Button>
            <Button onClick={capture} variant="contained" endIcon={<AddAPhotoIcon />}>
                Prendre la photo
            </Button>
        </div>
        <div style={{display:"flex",justifyContent:"center",gap:"15px", marginTop:"25px", alignItems:"center"}}>
                {imgSrc && <img style={{width: "250px"}} src={imgSrc} alt="img" />}
                {imgSrc && <Button onClick={SaveToApi} style={{height: "80px"}} variant="contained" endIcon={<SendIcon/>}><div style={{display: "flex", flexDirection: "column"}}><span>Envoyer la photo </span><span>et la rattacher à la préparation</span></div></Button>}
        </div>
        </>
    );
};

const style = {"color":"white","margin":"5px","padding":"10px","minWidth":"unset"};

const BtnCaptureImagePrep = (props) =>{
    

    const [open, setOpen] = useState(false);
  
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    return (
        <>
        <Tooltip title="Prendre une photo" arrow>
            <Button style={style} variant="contained" onClick={handleClickOpen} component="label" size="small">
                        <AddAPhotoIcon />
            </Button>
        </Tooltip>
        <Dialog maxWidth={false} open={open} onClose={handleClose}>
          <DialogTitle>Prendre une photo de la préparation {props.data.name}</DialogTitle>
          <DialogContent>
            <DialogContentText>
                <WebcamCapture prep={props.data}/>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Fermer</Button>
          </DialogActions>
        </Dialog>
        </>
    )
}
export default BtnCaptureImagePrep;