import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import {Chip} from "@mui/material";
import { getWareHouseDetail } from "../../Services/Depots/WareHouse";

const WareHousePresenter = (props) =>{
    const [loading, setLoading] = useState(true)
    const [name, setName] = useState("");
    useEffect(() => {
        getWareHouseDetail(props.warehouse).then((data)=>{
           setName(data.name);
           setLoading(false);
        });
    }, [props.warehouse]);
    
    if(loading) return <CircularProgress/>
    return(
        <>
        
        <Chip label={name}/>
        
        </>
  
    );
}

export default WareHousePresenter;