import React, { useRef, useState, useEffect, useImperativeHandle } from "react";

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { flexbox } from "@mui/system";
const styleModal = {
  
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  textAlign: "center"
};


function useKeyPress(targetKey) {

    const [keyPressed, setKeyPressed] = useState(false);
    function downHandler({ key }) {
      if (key === targetKey) {
        setKeyPressed(true);
      }
    }

    const upHandler = ({ key }) => {
      if (key === targetKey) {
        setKeyPressed(false);
      }
    };
    useEffect(() => {
      window.addEventListener("keydown", downHandler);
      window.addEventListener("keyup", upHandler);
      return () => {
        window.removeEventListener("keydown", downHandler);
        window.removeEventListener("keyup", upHandler);
      };
    }, []); 
    return keyPressed;
}

const EntrepotSys = React.forwardRef((props, ref) => {
  const selectedPlan = props.planselected;
  const canvasRef = useRef(null);
  const [lines, setLines] = useState(JSON.parse(selectedPlan['lines']));
  const [spots, setSpots] = useState(JSON.parse(selectedPlan['spots']));
  const [selectedLine, setSelectedLine] = useState(null);
  const [mode, setMode] = useState("SELECT");
  const [isDragging, setIsDragging] = useState(true);

  const [viewNames, setViewNames] = useState(true);
  const [regexFilter, setRegexFilter] = useState("**-**");

  const suppressPress = useKeyPress("Delete");
  const EditSpotPress = useKeyPress("E");
  const EditSpot2Press = useKeyPress("e");

  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [createOrAssign, setCreateOrAssign] = useState("none");
  const [formNameSpot, setFormNameSpot] = useState("");
  const [formColorSpot, setFormColorSpot] = useState("");
  const handleCloseModalEdit = () => setOpenModalEdit(false);

  useImperativeHandle(ref, () => ({
    handleCreateButtonClick,
    handleMoveButtonClick,
    deleteLigne,
    handleSelectButtonClick,
    handleCreateSpotButtonClick,
    toggleViewNames,
    setRegexFilter,
    editSpotsSelected,
    lines,
    spots,
    canvasRef
  }));

  function toggleViewNames(){
    setViewNames(!viewNames);
  }

  function generateString() {
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let string = '';
    for (let i = 0; i < 2; i++) {
      string += letters.charAt(Math.floor(Math.random() * letters.length));
    }
    string += '-';
    for (let i = 0; i < 2; i++) {
      string += Math.floor(Math.random() * 10);
    }
    return string;
  }

  function detectSelectedElement(x, y, elements) {
    const selectedLine = elements.lines.find((line) => {
      const { start, end } = line;
      if (start && end) {
        const distanceFromStart = Math.sqrt((x - start.x) ** 2 + (y - start.y) ** 2);
        const distanceFromEnd = Math.sqrt((x - end.x) ** 2 + (y - end.y) ** 2);
        const distanceAlongLine = Math.sqrt((start.x - end.x) ** 2 + (start.y - end.y) ** 2);
        const tolerance = 1; 
        return (
          distanceFromStart + distanceFromEnd - distanceAlongLine < tolerance &&
          distanceAlongLine - (distanceFromStart + distanceFromEnd) < tolerance
        );
      }
      return false;
    });
  
    const selectedSpot = elements.spots.find((spot) => {
      return (
        x >= spot.x - spot.width / 2 &&
        x <= spot.x + spot.width / 2 &&
        y >= spot.y - spot.height / 2 &&
        y <= spot.y + spot.height / 2
      );
    });
  
    return selectedSpot || selectedLine;
  }

  function handleCanvasClick(e) {
    if (mode === "CREATE") {
      const canvas = canvasRef.current;
      const rect = canvas.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      if (lines.length === 0 || (lines.length > 0 && lines[lines.length - 1].end)) {

        setLines([...lines, { start: { x, y }, end: null }]);
      } else {
        
        const newLines = [...lines];
        newLines[newLines.length - 1].end = { x, y };
        setLines(newLines);
      }
    }

    if (mode === "SELECT") {
        const canvas = canvasRef.current;
        const rect = canvas.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
      

        const selectedElement = detectSelectedElement(x, y, { lines, spots });
        if (selectedElement) {
          setSelectedLine(selectedElement);
        } else {
          setSelectedLine(null);
        }
    }

    if (mode === "CREATESPOT") {
      const canvas = canvasRef.current;
      const rect = canvas.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      const randomColor = Math.floor(Math.random()*16777215).toString(16);
      const nameRandom = generateString();
      const newSpot = { x, y, width: 10, height: 10, spotId: null, spotName: nameRandom, color: randomColor}; // définir les propriétés du carré
      setSpots([...spots, newSpot]); 
    }
    
  }

  

  function handleCanvasDoubleClick(e) {
    if (mode === "SELECT" || mode === "MOVE") {
      const canvas = canvasRef.current;
      const rect = canvas.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
    
      const selectedElement = detectSelectedElement(x, y, { lines, spots });
      if (selectedElement) {
        setSelectedLine(selectedElement);
        if(mode === "MOVE"){
          setIsDragging(true);
        }
      } else {
        setSelectedLine(null);
      }
      
    }
  }



  function handleCreateButtonClick() {
    setMode("CREATE");
    setSelectedLine(null);
  }

  function handleSelectButtonClick() {
    setMode("SELECT");
    setSelectedLine(null);
  }

  function handleMoveButtonClick(){
    if(selectedLine != null){
        setMode("MOVE");
        setIsDragging(true);
    }else{
        setMode("MOVE");
    }
  }

  function onMouseDown(){
    if(selectedLine != null && mode === "MOVE"){
        setIsDragging(false);
        setSelectedLine(null);
    }
  }

  function handleCreateSpotButtonClick() {
    setMode("CREATESPOT");
    setSelectedLine(null);
  }



  function deleteLigne(){
    if(selectedLine != null){
        if(selectedLine.hasOwnProperty('spotId')){
          const filteredSpot = spots.filter((spot) => spot !== selectedLine);
          setSpots(filteredSpot);
        }else{
          const filteredLines = lines.filter((line) => line !== selectedLine);
          setLines(filteredLines);
        }
        setSelectedLine(null);
    }else{
        alert("aucune ligne séléctionné ou emplacement");
    }

  }


  

  function handleMouseMove(e) {
    if (isDragging && mode === "MOVE" && selectedLine !== null) {
      const canvas = canvasRef.current;
      const rect = canvas.getBoundingClientRect();
      const x2 = e.clientX - rect.left;
      const y2 = e.clientY - rect.top;
  

      
      if(selectedLine.hasOwnProperty('spotId')){
        const deltaX = x2 - selectedLine.x;
        const deltaY = y2 - selectedLine.y;
        var match = null;
        const updatedSpots = spots.map((spot, index) => {
          if (spot === selectedLine) {
            match = index;
            return {
              ...spot,
              x: spot.x + deltaX,
              y: spot.y + deltaY,
            };
          }
          return spot;
        });
  
        setSpots(updatedSpots);
        if(match != null){
          setSelectedLine(updatedSpots[match]);
        }
      }else{
        //LIGNE SELECTIONNER
        const deltaX = x2 - selectedLine.start.x;
        const deltaY = y2 - selectedLine.start.y;
        var match = null;
        const updatedLines = lines.map((line, index) => {
          if (line === selectedLine) {
            match = index;
            return {
              ...line,
              start: { x: line.start.x + deltaX, y: line.start.y + deltaY },
              end: { x: line.end.x + deltaX, y: line.end.y + deltaY },
            };
          }
          return line;
        });
    
        setLines(updatedLines);
        if(match != null){
          setSelectedLine(updatedLines[match]);
        }
      }
      
    }
  }
  

  useEffect(() => {
    function drawLines() {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");
        ctx.clearRect(0, 0, canvas.width, canvas.height);
    
        //drawdot
        var r = 1;
        var cw = 20;
        var ch = 20;
        var vw = canvas.scrollWidth;;
        var vh = canvas.scrollHeight;
        for (var x = 20; x < vw; x+=cw) {
            for (var y = 20; y < vh; y+=ch) {
                ctx.fillStyle = '#000000';   
                ctx.fillRect(x-r/1,y-r/1,r,r);
            }
        }
        //***----- */
    
        lines.forEach((line) => {
          if (line.end) {
            ctx.beginPath();
            ctx.moveTo(line.start.x, line.start.y);
            ctx.lineTo(line.end.x, line.end.y);
            ctx.lineWidth = 2;
            if ((mode === "SELECT"||mode === "MOVE") && selectedLine === line) {
              ctx.strokeStyle = "red";
            } else {
              ctx.strokeStyle = "black";
            }
            ctx.stroke();
          }
        });

        if(regexFilter){
          const inputRegex = /^(\*|\w)*-(\*|\w)*$/;
          if (inputRegex.test(regexFilter)) {
            const firstPart = regexFilter.slice(0, 2); 
            const secondPart = regexFilter.slice(3); 
          
            
            const firstRegex = new RegExp(`^${firstPart.replace(/\*/g, '\\w')}$`);
          
            
            const secondRegex = new RegExp(`^${secondPart.replace(/\*/g, '\\w')}$`);
            spots.forEach((spot) => {
              var splitspot = spot.spotName.split("-");
               
              if (firstRegex.test(splitspot[0]) && secondRegex.test(splitspot[1])) {
                ctx.beginPath();
                const radius = 6;
                const startAngle = 0;
                const endAngle = 2 * Math.PI;
                ctx.arc(spot.x, spot.y, radius, startAngle, endAngle);
                ctx.fillStyle = "#"+spot.color;
                if ((mode === "SELECT"||mode === "MOVE") && selectedLine === spot) {
                  ctx.strokeStyle = "red";
                } else {
                  ctx.strokeStyle = "transparent";
                }
                ctx.fill();
                ctx.stroke();
              
                // Ajouter le nom du spot à côté du point
                if(viewNames){
                  ctx.font = "12px sans-serif";
                  ctx.fillStyle = "black";
                  ctx.fillText(spot.spotName, spot.x + radius + 5, spot.y);
                }
              } 
              
            });
           
          } else {
            spots.forEach((spot) => {
              ctx.beginPath();
              const radius = 6;
              const startAngle = 0;
              const endAngle = 2 * Math.PI;
              ctx.arc(spot.x, spot.y, radius, startAngle, endAngle);
              ctx.fillStyle = "#"+spot.color;
              if ((mode === "SELECT"||mode === "MOVE") && selectedLine === spot) {
                ctx.strokeStyle = "red";
              } else {
                ctx.strokeStyle = "transparent";
              }
              ctx.fill();
              ctx.stroke();
            
              // Ajouter le nom du spot à côté du point
              if(viewNames){
                ctx.font = "12px sans-serif";
                ctx.fillStyle = "black";
                ctx.fillText(spot.spotName, spot.x + radius + 5, spot.y);
              }
            });
          }

        }else{
          spots.forEach((spot) => {
            ctx.beginPath();
            const radius = 6;
            const startAngle = 0;
            const endAngle = 2 * Math.PI;
            ctx.arc(spot.x, spot.y, radius, startAngle, endAngle);
            ctx.fillStyle = "#"+spot.color;
            if ((mode === "SELECT"||mode === "MOVE") && selectedLine === spot) {
              ctx.strokeStyle = "red";
            } else {
              ctx.strokeStyle = "transparent";
            }
            ctx.fill();
            ctx.stroke();
          
            // Ajouter le nom du spot à côté du point
            if(viewNames){
              ctx.font = "12px sans-serif";
              ctx.fillStyle = "black";
              ctx.fillText(spot.spotName, spot.x + radius + 5, spot.y);
            }
          });
        }
        


    
        
    }
    drawLines();

  }, [lines, isDragging, selectedLine, spots, viewNames, regexFilter]);

  useEffect(() => {
    if(suppressPress){
        deleteLigne();
    }
  }, [suppressPress]);

  function editSpotsSelected(){
    if(selectedLine && selectedLine.hasOwnProperty("spotId")){
      setFormNameSpot(selectedLine["spotName"]);
      setFormColorSpot("#"+selectedLine["color"]);
      setOpenModalEdit(true);
    }
  }
  useEffect(() => {
    if(EditSpotPress || EditSpot2Press){
        editSpotsSelected();
    }
  }, [EditSpotPress, EditSpot2Press]);

  useEffect(() => {
    setLines(JSON.parse(selectedPlan['lines']));
    setSpots(JSON.parse(selectedPlan['spots']));
  }, [selectedPlan]);

  function handleChangeAssignOrCreate(e){
    var value = e.target.value;
    setCreateOrAssign(value);
  }

  function handleChangeFormNameSpot(e){
    setFormNameSpot(e.target.value);
  }

  function handleChangeFormColorSpot(e){
    setFormColorSpot(e.target.value);
  }

  function createOrAssignToSpot(){

    const newSpots = [...spots];

    const selectedSpotsIndex = newSpots.findIndex((spot) => spot === selectedLine);
    newSpots[selectedSpotsIndex] = {
      ...newSpots[selectedSpotsIndex],
      spotName: formNameSpot,
      color: formColorSpot.replace("#", ""),
    }
    console.log("spot", spots);

    setSpots(newSpots);
    setOpenModalEdit(false);
  }

  return (
    <>
      <canvas
        ref={canvasRef}
        height={700}
        width={1200}
        style={{ border: "1px solid black", cursor: "pointer" }}
        onClick={handleCanvasClick}
        onDoubleClick={handleCanvasDoubleClick}
        onMouseMove={handleMouseMove}
        onMouseDown={onMouseDown}
      />
      
    <div>
      <Modal
        open={openModalEdit}
        onClose={handleCloseModalEdit}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          <Typography id="modal-modal-title" variant="h6" component="h2" style={{marginBottom: "10px"}}>
            Édition d'un emplacement
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }} component="span">
            {selectedLine && selectedLine.hasOwnProperty("spotId")? 
                  <span style={{display:"flex", flexDirection: "column", justifyContent: "center", gap: "10px"}}>
                    <FormControl fullWidth>
                      <InputLabel id="select-assignorcreate-label">Relié à un emplacement existant?</InputLabel>
                      <Select
                        labelId="select-assignorcreate-label"
                        id="select-assignorcreate"
                        label="Relié à un emplacement existant?"
                        value={createOrAssign}
                        onChange={handleChangeAssignOrCreate}
                      >
                        <MenuItem value={"none"}>Non, je souhaite le crée</MenuItem>
                        <MenuItem value={"1"}>Spot 1 AZ-4B - Produit 1 - Stock 58</MenuItem>
                        <MenuItem value={"2"}>Spot 1 3B-57 - Produit 2 - Stock 89</MenuItem>
                      </Select>
                    </FormControl>
                    <TextField id="outlined-basic" label="Nom" onChange={handleChangeFormNameSpot} value={formNameSpot} variant="filled" />
                    <input style={{width: "100%"}} type="color" id="head" name="head" onChange={handleChangeFormColorSpot} value={formColorSpot}></input>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Produit</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Produits"
                        value={"none"}
                      >
                        <MenuItem value={"none"}>Aucun</MenuItem>
                        <MenuItem value={"0"}>Produit 1</MenuItem>
                        <MenuItem value={"1"}>Produit 2</MenuItem>
                        <MenuItem value={"2"}>Produit 3</MenuItem>
                      </Select>
                    </FormControl>
                    <TextField id="outlined-basic" value={0} label="Stock" variant="filled" />
                    <TextField id="outlined-basic" value={"0000"}  label="Code Pin" variant="filled" />
                    <Button variant="contained" color={createOrAssign === "none"? "success": "warning"} onClick={createOrAssignToSpot}>
                      {createOrAssign === "none"? "Créer et relier": "Modifier"}
                    </Button>
                  </span>
            :
                  <>Vous n'avez pas séléctionné un emplacement</>
            }
          </Typography>
        </Box>
      </Modal>
    </div>
    </>
  );
});

export default EntrepotSys;