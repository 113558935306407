import { Chip, LinearProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { getPrepItems } from "../../Services/Commandes/Commandes";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import MediaObjectPresenter from "../../component/MediaObject/MediaObjectPresenter";
import AttributesToArrayDisplayer from "../../component/Product/AttributeToArrayDisplayer";
import StepSpecificDisplayer from "../../component/Etapes/StepSpecificDisplayer";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import PriorityChanger from "../../component/Product/PriorityChanger";


const CommandesDetailsProductsList = (props)=>{
    let prepID = props.order_prep_id;

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [reload, setReload] = useState(false);

    function handleReload(){
      setReload(!reload);
    }

    useEffect(() => {
        setLoading(true);
        getPrepItems(prepID).then((order_prep_items)=>{
            setData(order_prep_items['hydra:member']);
            setLoading(false);
        })
        
    }, [prepID, reload]);
    if(loading) return (<LinearProgress/>); 
    return(<>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Ordre</TableCell>
            <TableCell align="left">Spot</TableCell>
            <TableCell align="left">Image</TableCell>
            <TableCell align="left">Produits</TableCell>
            <TableCell align="center">Progressions</TableCell>
            <TableCell align="center">Palettes</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow
              key={row['@id']}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <PriorityChanger handleReload={handleReload} id_item={row['@id']} priority={row.priority}/>
              </TableCell>
              <TableCell align="left"><span style={{backgroundColor: row.spot.color, fontWeight: "bold", padding:"5px", borderRadius: "5px", whiteSpace: "nowrap"}}>{row.spot.spot}</span></TableCell>
              <TableCell align="left">{row.product.image? <MediaObjectPresenter type={"reconstructUrl"} ean={row.product.ean} mediaobjectid={row.product.image['@id']} width="96px" height="96px"/> : <></>}</TableCell>
              <TableCell align="left"><b>{row.product.name}</b><br></br><AttributesToArrayDisplayer attributes={row.product.attributesToArray} withCustom={row.custom}/></TableCell>
              <TableCell align="right"><StepSpecificDisplayer data={row} stepSelected={"prepare"}/></TableCell>
              <TableCell align="right">
                
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead style={{borderTop: "grey 1px solid", borderBottom: "grey 1px solid"}}>
                        <TableRow>
                          <TableCell style={{borderTop: "grey 1px solid", borderBottom: "grey 1px solid"}} align="center">Quantités</TableCell>
                          <TableCell style={{borderTop: "grey 1px solid", borderBottom: "grey 1px solid"}} align="center">Controlé</TableCell>
                          <TableCell style={{borderTop: "grey 1px solid", borderBottom: "grey 1px solid"}} align="center">Controlé par un Responsable</TableCell>
                          
                        </TableRow>
                      </TableHead>
                      <TableBody style={{borderTop: "grey 1px solid", borderBottom: "grey 1px solid"}}>
                          <TableCell style={{borderTop: "grey 1px solid", borderBottom: "grey 1px solid"}} align="center">{row.paletteItems[0]?row.paletteItems[0].quantity: "/"}</TableCell>
                          <TableCell style={{borderTop: "grey 1px solid", borderBottom: "grey 1px solid"}} align="center">{row.paletteItems[0]?row.paletteItems[0].controlled? <CheckIcon style={{color: "green"}} />:<CloseIcon style={{color: "red"}}/> : "/"}</TableCell>
                          <TableCell style={{borderTop: "grey 1px solid", borderBottom: "grey 1px solid"}} align="center">{row.paletteItems[0]?row.paletteItems[0].controlledResponsible? <CheckIcon style={{color: "green"}} />:<CloseIcon style={{color: "red"}}/> : "/"}</TableCell>
                      </TableBody>
                    </Table>
                  </TableContainer>
                
                </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

    </>)

}
export default CommandesDetailsProductsList;