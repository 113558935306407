import {Button} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { useState, useEffect } from "react";
import CollectionsIcon from '@mui/icons-material/Collections';
import React from 'react';
import { ENTRY_POINT } from '../../global-config';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';
import { LinearProgress, CircularProgress } from '@mui/material';
import { getOneUser } from '../../Services/Auth/Authentification';
import { deleteMediaPreps, getMediaPreps } from '../../Services/MediaPreps/MediaPreps';

const style = {"color":"white","margin":"5px","padding":"10px","minWidth":"unset"};
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});



const UserViewer = (props) => {

    const [ data, setData ] = useState(null);
    const [ loading, setLoading ] = useState(true);
    useEffect(() => {
        setLoading(true);
        getOneUser(props.user).then((user)=>{
            setData(user);
            setLoading(false);
        });
    }, [props.user])


    if (loading){
      return <CircularProgress style={{width: "16px", height: "16px", marginLeft: "10px", marginRight: "10px"}}/>;
    }
   

    if(typeof data !== "undefined"){
      return data.firstName + " " + data.lastName + " ("+ data.email +")"
    }else{
      return "Erreur lors du chargement de l'utilisateur";
    }
}

const BtnSeeImgPrep = (props) =>{
    let id_prep = props.data;
    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]); 
    const [loading, setLoading] = useState(true);
    const [loadingSuppress, setLoadingSuppress] = useState(false);

    const [reload, setReload] = useState(false);


    function handleClickOpen(e){
        setOpen(true);
        e.stopPropagation();
    }

    const handleClose = (e) => {
        setOpen(false);
    };

    useEffect(() => {
        if(open === true){
            setLoading(true);
            getMediaPreps(id_prep).then((datas)=>{
                setData(datas['hydra:member']);
                setLoading(false);
            });
        }else{
            setLoading(false);
        }

    }, [open, reload, id_prep])

    function deleteThisPic(e){
        setLoadingSuppress(true);
        let target = e.target;
        let id_media_prep = target.getAttribute('id_media_prep');
        deleteMediaPreps(id_media_prep).then((value)=>{
            setTimeout(() => {
                setLoadingSuppress(false);
                setReload(true);
            }, 2000)
        })
        
    }

    let haveImages = (data.length > 0)? true: false;
    return(
        <>  
        {   loading? <CircularProgress style={{width: "16px", height: "16px", marginLeft: "10px", marginRight: "10px"}}/> : 
            
            <Button style={style} variant="contained"  onClick={handleClickOpen} component="label" size="small">
                        <CollectionsIcon />
            </Button>
        }
        <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        >
        <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
            <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
            >
                <CloseIcon />
            </IconButton>
            
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Liste des images liée la préparation n°{id_prep}
            </Typography>
            <Button autoFocus variant="contained" style={style} onClick={handleClose}>
                Fermer
            </Button>
            </Toolbar>
        </AppBar>
        {loading? <LinearProgress style={{marginTop: "10px"}}/> :
            !haveImages? <h1>Pas d'image disponible</h1>:
            <div>
                
                <Carousel showArrows={true}>
                        {
                            data.map((item)=>{
                                return <div>
                                        <img alt="image_" src={ENTRY_POINT+"/prep/"+item.filePath} style={{width: "100%", maxHeight: "600px"}}/>
                                        <p className="legend">Prise le : <b>{item.date_created}</b> par <b><UserViewer user={item.user}/></b> <a style={{textDecoration: "none"}} target="_blank" rel="noopener noreferrer" href={ENTRY_POINT+"/prep/"+item.filePath}><Button variant="contained">Ouvrir dans une nouvelle fenetre</Button></a> <Button variant="contained" id_media_prep={item['@id']} onClick={deleteThisPic} style={{backgroundColor: "red", marginLeft: "10px"}}>{loadingSuppress?<CircularProgress style={{width: "16px", height: "16px"}}/>:"Supprimer"}</Button></p>
                                    </div>
                            })
                        }
                </Carousel>
            
            </div>
        }
        </Dialog>
    </>) 
}
export default BtnSeeImgPrep;



