import { Button, LinearProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { getPrepPalettes } from "../../Services/Commandes/Commandes";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ButtonPalettePrint from "../../component/Palettes/ButtonPrint";
import { updatePaletteFieldInTable } from "../../Services/Palettes/Palettes";
import UpdateFieldInTable from "../../component/Palettes/UpdateFieldInTable";
import { stepPackedFinish, validatePackedPaletteItem } from "../../Services/Preparation/Preparation";
import { useNavigate } from "react-router-dom";
import { GoToPaletteDetail } from "../Palettes/PaletteDetail";
import ButtonMultiplePrint from "../../component/Palettes/ButtonMultiplePrint";

const BetterUpdateFieldInTable = (props)=>{
    let field = props.field;
    let value = props.value;
    let setterValue = props.setterValue;
    let target = props.target;

    function handleChange(e) {
        
        setterValue(parseFloat(e.target.value));
        if(field === "weight") {
           updatePaletteFieldInTable(target, field, e.target.value*1000);
        }else{
           updatePaletteFieldInTable(target, field, e.target.value);
        }
    }

    return <input style={{textAlign: "center", width: "75px"}} onChange={handleChange} value={value} type="text"/>
}

const EditAndCalculateVolum = (props) =>{
    let item = props.item;
    let target = props.target;
    const [length, setLength] = useState(item.length);
    const [width, setWidth] = useState(item.width);
    const [height, setHeight] = useState(item.height);
    const [volume, setVolume] = useState((item.length * item.width * item.height) / 1000000);


    useEffect(() => {
        
        setVolume((length * width * height) / 1000000);
        
    }, [length, width, height]);

    return (<>
    
                <TableCell align="center"><BetterUpdateFieldInTable field="length" setterValue={setLength} target={target} value={length}/></TableCell>
                <TableCell align="center"><BetterUpdateFieldInTable field="width" setterValue={setWidth} target={target} value={width}/></TableCell>
                <TableCell align="center"><BetterUpdateFieldInTable field="height" setterValue={setHeight} target={target} value={height}/></TableCell>
                <TableCell align="center">{volume.toFixed(2)}m3</TableCell>
    </>);
}

const ButtonPackedUnPacked = (props) =>{
    let item = props.item;
    let ghostReload = props.ghostReload;
    const [isPacked, setisPacked] = useState(item.packed);

    function validatePack(){
        setisPacked(true);
        validatePackedPaletteItem(item['@id'], true).then((response)=>{
            ghostReload();
        })
    }

    function unvalidatePack(){
        setisPacked(false);
        validatePackedPaletteItem(item['@id'], false).then((response)=>{
            ghostReload();
        })
    }

    return (<>
        {isPacked?
            <Button variant="contained" onClick={unvalidatePack} color="error" style={{padding: "10px"}}>DÉBALLER CETTE PALETTE</Button>
            :
            <Button variant="contained" onClick={validatePack} color="success" style={{padding: "10px"}}>VALIDER L'EMBALLAGE DE CETTE PALETTE</Button>
        }
    
    </>)

}

const EmballerSystem = (props) =>{
    let dataPrep = props.data;
    let id_prep = props.id_prep;
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        
        setLoading(true);
        getPrepPalettes("/order_preparations/"+id_prep).then((data)=>{
            setData(data['hydra:member']);
            setLoading(false);
        });
        
    }, [id_prep]);


    function finishPack(){
        stepPackedFinish("/order_preparations/"+id_prep).then((result)=>{
            props.ghostReload();
            navigate('/charger/'+id_prep);
        })
    }

    if(loading) return <LinearProgress/>
    return(<>
        <div style={{textAlign: "center", marginBottom: "5px"}}><ButtonMultiplePrint palette={false} orderPreparation={dataPrep}/></div>
        <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
            <TableRow>
                <TableCell>Numéro général</TableCell>
                <TableCell>Numéro</TableCell>
                <TableCell align="center">Type</TableCell>
                <TableCell align="center">Qté Produits</TableCell>
                <TableCell align="center">Longueur cm</TableCell>
                <TableCell align="center">Largeur cm</TableCell>
                <TableCell align="center">Hauteur cm</TableCell>
                <TableCell align="center">Volume</TableCell>
                <TableCell align="center">Poids</TableCell>
                <TableCell align="center">Actions</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {data.map((item, index) => {
                return (<TableRow
                key={item.number}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                    <TableCell component="th" scope="row">
                        <GoToPaletteDetail id_palette={item['@id'].replace('/palettes/', '')} text={"n°"+item['@id'].replace('/palettes/', '')}/>
                    </TableCell>
                    <TableCell component="th" scope="row">
                        n°{item.number}
                    </TableCell>
                    <TableCell align="center">{item.typeName}</TableCell>
                    <TableCell align="center">{item.products}</TableCell>
                    <EditAndCalculateVolum target={item['@id']} item={item}/>
                    <TableCell align="center"><UpdateFieldInTable target={item['@id']} field="weight" value={item.weight /1000}/> kg</TableCell>
                    <TableCell align="center" style={{display: "flex", gap:"5px", alignItems: "center", flexWrap: "wrap", justifyContent: "center"}}>
                        
                        <ButtonPackedUnPacked item={item} ghostReload={props.ghostReload}/>
                        <ButtonPalettePrint id_palette={item['@id']} palette={false} orderPreparation={dataPrep}/>
                    </TableCell>
                </TableRow>)
            })}
            </TableBody>
        </Table>
        </TableContainer>
        <hr/>
        <div style={{width: "100%", textAlign: "center"}}>
        <Button style={{marginBottom: "7px"}} onClick={finishPack} variant="contained" color={dataPrep.packed.percent === 100? "success": "error"} disabled={dataPrep.packed.percent === 100? false: true}>Valider l'emballage et passer au chargement</Button>
        </div>
    </>)
}
export default EmballerSystem;
