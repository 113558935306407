import { getCurrentOrderPrep, getIdWithEmail, getMyTeams, getMyTeamsDetails } from "../../Services/Auth/Authentification";
import { useState } from "react";
import LinearProgress from '@mui/material/LinearProgress';
import { useEffect } from "react";
import ErrorIcon from '@mui/icons-material/Error';
import { Tooltip } from "@mui/material";
import TeamGroupPresenter from "./TeamGroupPresenter";

const MyTeams = (props) =>{
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [error, setError] = useState(false);
    const [haveprep, setHaveprep] = useState(true);

    let handleReload = props.handleReload;

    useEffect(() => {
        setLoading(true);
        getIdWithEmail().then((user)=>{
            getCurrentOrderPrep(user['hydra:member'][0]['@id']).then(order_prep_id => {
                if(order_prep_id === "error" || order_prep_id === undefined){
                    setHaveprep(false);
                    setLoading(false);
                }else{
                    getMyTeams(order_prep_id).then(teams_receiv => {
                        if(teams_receiv === "error"){
                            setError(true);
                        }else{
                            getMyTeamsDetails(teams_receiv).then(teams_detail_receiv => {
                                if(teams_detail_receiv === "error"){
                                    setError(true);
                                }else{
                                    setError(false);
                                    setData(teams_detail_receiv);
                                }
                                setLoading(false);
                            });
                        }
                        setLoading(false);
                    });
                }
            });
        });
        
    }, [handleReload]);

    if(loading){
        return <LinearProgress style={{width: "100px"}}/>
    }else{
        if(error === false){
            if(haveprep){
                if(Array.isArray(data) && data.length > 0){
                    let component = <TeamGroupPresenter handleReload={handleReload} team={data}/>
                    return(component) 
                }else{
                    console.log("Team Vide");
                    return(<></>);
                }
            }else{
                console.log("Team Vide car aucune préparation en cours");
                return(<></>);
            }
        }else{
            return(<Tooltip title="Erreur lors du chargement de l'équipe" arrow><ErrorIcon style={{color: "red"}} /></Tooltip>);
        }
    }    
    

    
}

export default MyTeams;