import { useEffect, useState } from "react";
import { editUser } from "../../Services/Auth/Authentification";
import { getWareHousesList } from "../../Services/Depots/WareHouse";
import * as React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import WarehouseSelect from "../../component/Depots/WareHouseSelect";

const EditUtilisateur = (props) =>{
  let open = props.open;
  let handleReload = props.handleReload;
  let toggleSnack = props.toggleSnack;
  let handleClose = props.handleClose;

  let id = props.id;
  let email = props.email;
  let lastname = props.lastname;
  let firstname = props.firstname;
  let warehouses = props.warehouses;
  let viewPin = props.viewPin;
  let canControl = props.canControl;

  let handleEmail = props.handleEmail;
  let handleLastname = props.handleLastname;
  let handleFirstname = props.handleFirstname;
  let handleWarehouses = props.handleWarehouses;
  let handleViewPin = props.handleViewPin;
  let handleCanControl = props.handleCanControl;

  
  const [allWarehouse, SetallWarehouse] = useState([]);
  useEffect(() => {
    getWareHousesList().then((warehouse)=>{
        SetallWarehouse([]);
        warehouse["hydra:member"].forEach((item)=>{
          SetallWarehouse(current => [...current, item]);
        });
    });;
  }, [open]);

  function onChangeEmail(e){
    let target = e.target;
    handleEmail(target.value);
  }

  function onChangeLastname(e){
    let target = e.target;
    handleLastname(target.value);
  }

  function onChangeFirstName(e){
    let target = e.target;
    handleFirstname(target.value);
  }

  function onChangeWarehouses(val){
    handleWarehouses(val);
  }

  function onChangeViewPin(e){
    handleViewPin(e.target.value);
  }

  function onChangeCanControl(e){
    handleCanControl(e.target.value);
  }

  function saveToApi(){
    editUser("/users/"+id, email, lastname, firstname, warehouses, viewPin, canControl).then(users => {
        handleReload();
        toggleSnack(true, "Sauvegardé avec succès", "success");
    });
    handleClose();
  }
  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Édition du profil</DialogTitle>
        <DialogContent style={{display: "flex", flexDirection: "column", gap: "10px"}}>
          <TextField id="filled-basic" onInput={onChangeEmail} d={id} label="Email" type="email" value={email} variant="filled" />
          <TextField id="filled-basic" onChange={onChangeLastname} label="Nom" value={lastname} variant="filled" />
          <TextField id="filled-basic" onChange={onChangeFirstName} label="Prénom" value={firstname} variant="filled" />
          <WarehouseSelect allWarehouse={allWarehouse} onChangeWarehouses={onChangeWarehouses} label="warehouses" warehouses={warehouses}/>
          <FormControl>
              <FormLabel id="viewpin-onoff">Possibilité de voir le code PIN des emplacements</FormLabel>
              <RadioGroup
                row
                aria-labelledby="viewpin-onoff"
                name="viewpin-onoff"
                value={viewPin}
                onChange={onChangeViewPin}
              >
                <FormControlLabel value={true} control={<Radio />} label="Oui" />
                <FormControlLabel value={false} control={<Radio />} label="Non" />
              </RadioGroup>
          </FormControl>
          <FormControl>
              <FormLabel id="cancontrol-onoff">Possibilité d'accès à "contrôle-resp"</FormLabel>
              <RadioGroup
                row
                aria-labelledby="cancontrol-onoff"
                name="cancontrol-onoff"
                value={canControl}
                onChange={onChangeCanControl}
              >
                <FormControlLabel value={true} control={<Radio />} label="Oui" />
                <FormControlLabel value={false} control={<Radio />} label="Non" />
              </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button onClick={saveToApi}>Appliquer</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default EditUtilisateur;