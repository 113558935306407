import React, { useState, useEffect } from 'react';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { getDashBoardStats } from '../../Services/Dashboard/Dashboard';

const DashPage = () =>{
    const [loading, setLoading] = useState(true);
    const [totalPrep, setTotalPrep] = useState(0);
    const [totalPrepPasPrisEnCharge, setTotalPrepPasPrisEnCharge] = useState(0);
    const [totalPrepEncours, setTotalPrepEncours] = useState(0);
    const [totalPrepFini, setTotalPrepFini] = useState(0);

    useEffect(() => {
        getDashBoardStats().then(data => {
            if(data !== "error"){
                let total = data["hydra:totalItems"];
                let totalPasPrisEnCharge = 0;
                let totalEncours = 0;
                let totalFini = 0;

                data["hydra:member"].forEach((prep) =>{
                    
                    if(prep["step"] === "/order_preparation_steps/expect"){
                        totalFini++;
                        return;
                    }else if(prep["step"] === "/order_preparation_steps/plan"){
                        totalPasPrisEnCharge++;
                        return;
                    }
                    
                });
                totalEncours = (total - totalPasPrisEnCharge)-totalFini;
                setTotalPrep(total);
                setTotalPrepPasPrisEnCharge(totalPasPrisEnCharge);
                setTotalPrepEncours(totalEncours);
                setTotalPrepFini(totalFini);

                setLoading(false);
            }
        });
    });

    const StylesPaper = {
        height: '100px', 
        verticalAlign: 'center', 
        justifyContent: 'center', 
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center'
    };

    const StylesPaperTotal = {
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
    };

    const StylesPaperOrange = {
        backgroundColor: 'rgba(255, 190, 6, 0.4)',
    };

    const StylesPaperRed = {
        backgroundColor: 'rgba(255, 0, 0, 0.4)',
    }

    const StylesPaperGreen = {
        backgroundColor: 'rgba(3, 255, 0, 0.4)',
    }

    return(
        <>
        <h3>Tableau de bord</h3>
        <hr></hr>
        {loading?
        <Box sx={{ flexGrow: 1}}>
            <Grid container spacing={3}>
                <Grid style={{paddingBottom: "10px"}} item xs={12} md={12}>
                    <Skeleton height={"100px"} variant="rounded"/>
                </Grid>
                <Grid style={{paddingBottom: "10px"}} item xs={6} md={4}>
                    <Skeleton height={"100px"} variant="rounded"/>
                </Grid>
                <Grid style={{paddingBottom: "10px"}} item xs={6} md={4}>
                    <Skeleton height={"100px"} variant="rounded"/>
                </Grid>
                <Grid style={{paddingBottom: "10px"}} item xs={12} md={4}>
                    <Skeleton height={"100px"} variant="rounded"/>
                </Grid>
            </Grid>
        </Box>
        :
        <Box sx={{ flexGrow: 1}}>
            <Grid container spacing={3}>
                <Grid style={{paddingBottom: "10px"}} item xs={12} md={12}>
                <Paper style={Object.assign({}, StylesPaper, StylesPaperTotal)} elevation={3}>Nombre de préparation(s) total<br></br><b>{totalPrep}</b></Paper>
                </Grid>
                <Grid style={{paddingBottom: "10px"}} item xs={6} md={4}>
                <Paper style={Object.assign({}, StylesPaper, StylesPaperRed)} elevation={3}>Nombre de préparation(s) non prise(s) en charge <br></br><b>{totalPrepPasPrisEnCharge}</b></Paper>
                </Grid>
                <Grid style={{paddingBottom: "10px"}} item xs={6} md={4}>
                <Paper style={Object.assign({}, StylesPaper, StylesPaperOrange)} elevation={3}>Nombre de préparation(s) en cours<br></br><b>{totalPrepEncours}</b></Paper>
                </Grid>
                <Grid style={{paddingBottom: "10px"}} item xs={12} md={4}>
                <Paper style={Object.assign({}, StylesPaper, StylesPaperGreen)} elevation={3}>Nombre de préparation(s) finie(s)<br></br><b>{totalPrepFini}</b></Paper>
                </Grid>
            </Grid>
        </Box>
        }
        </>
    )
}
export default DashPage;