import { Chip, CircularProgress } from "@mui/material";
import { useState, useEffect } from "react";
import { getOneTransporteurs } from "../../Services/Transporteurs/Transporteurs";

const TransporteurPresenter = (props) =>{
    let transporteur = props.transporteur;
    const [loading, setLoading] = useState(true);
   
    let component = props.component;

   

    
    if(component === "chip"){
            return <Chip label={transporteur.name}/>
    }else{
            return transporteur.name;
    }

}
export default TransporteurPresenter;