import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ProfilePresenter from '../Profile/ProfilePresenter';
import { Checkbox } from '@mui/material';

const TeamListPresenter = (props) => {
  let users = props.users;
  let handleCheck = props.handlecheck;
  
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="left">Email</TableCell>
            <TableCell align="right">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user) => (
            <TableRow
              key={user.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left"><ProfilePresenter type={"team"} name={user.lastName + " " + user.firstName}/></TableCell>
              <TableCell align="left">{user.email}</TableCell>
              <TableCell align="right"><Checkbox onChange={handleCheck} value={"/users/"+user.id} sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}/></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

/**
 * <TableCell align="left"><ProfilePresenter type={"team"} name={user.lastName + " " + user.firstName}/></TableCell>
              <TableCell align="left">{user.email}</TableCell>
              <TableCell align="right">add</TableCell>
 */

export default TeamListPresenter;