import DeleteIcon from '@mui/icons-material/Delete';
import { RemovePaletteFromNavette } from '../../Services/Palettes/Palettes';
import Tooltip from '@mui/material/Tooltip';
import MoveDownIcon from '@mui/icons-material/MoveDown';

const DeletePaletteFromNavette = (props) =>{

    let id_palette = props.id_palette;
    let handleReload = props.handleReload;
    function deletefromthisnavette(){
        RemovePaletteFromNavette(id_palette).then((data)=>{
            
            handleReload();
            
        });
    }
    return ( <Tooltip title="Supprimer de la navette"><div onClick={deletefromthisnavette} style={{color: "red", border: "1px solid red", borderRadius: "16px", cursor: "pointer", height: "32px", width:"32px", textAlign: "center", margin: "auto"}}><MoveDownIcon style={{position: "relative", top: "5%"}}/></div></Tooltip>)
}
export default DeletePaletteFromNavette;