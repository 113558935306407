import { Fab, LinearProgress, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { getPaletteTypeList } from "../../Services/Palettes/Palettes";
import AddBoxIcon from '@mui/icons-material/AddBox';
import { addNewPaletteToPrep } from "../../Services/Commandes/Commandes";
import AddIcon from '@mui/icons-material/Add';

const AddPalette = (props) => {
    let prepID = props.prepID;
    let handleReload = props.handleReload;
    const [open, setOpen] = useState(false);
    const [paletteList, setPaletteList] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedValue, setSelectedValue] = useState(null);
    const [qtyToAdd, setQtyToAdd] = useState(1);


    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    function selectTypeHandle(e){
      let value = e.target.value;
      setSelectedValue(value);
    }

    function handleQtyToAdd(e){
      let value = e.target.value;
      setQtyToAdd(value);
    }

    useEffect(() => {
        setLoading(true);
        getPaletteTypeList().then((data)=>{
            setPaletteList(data['hydra:member']);
            setLoading(false);
        });
    
    }, [open]);

    function handleAdd() {
      console.log("adde");
      handleClose();
      let promises = [];
      for (let i = 0; i < qtyToAdd; i++) {
        let promise = addNewPaletteToPrep(prepID, selectedValue).then((result) => {
          console.log(i + " -> " + result);
          if (props.selectPaletteAfterAdd) {
            props.selectPaletteAfterAdd(result["@id"]);
          }
          return result;
        });
        promises.push(promise);
      }
      Promise.all(promises).then((results) => {
        handleReload();
      });
    }

    return (<>
    {props.displayFromPrep? 
      <Tooltip title={"Ajouter une palette à cette préparation"}>
          <Fab onClick={handleClickOpen} style={{boxShadow:"unset", marginLeft: "7px", marginRight: "7px"}} size={"small"} color="default" aria-label="Impression">
            <AddIcon/>
          </Fab>
      </Tooltip>
    :
      <Tooltip title={"Ajouter une palette à cette préparation"}>
        <Button startIcon={<AddBoxIcon />} onClick={handleClickOpen} variant="contained" style={{marginTop: "15px", marginBottom: "15px"}}>AJOUTER UNE PALETTE</Button>
      </Tooltip>
    }
    
    <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Ajouter une palette</DialogTitle>
        <DialogContent>
          <DialogContentText>
              Ajouter une palette à cette préparation du type :
          </DialogContentText>
          {

            loading? <LinearProgress/>:<>
            <span style={{display: "flex", width: "100%", justifyContent:"center"}}>
            Quantité : <input min="0" onChange={handleQtyToAdd} value={qtyToAdd} type="number"></input>
            </span>
            <select value={selectedValue} onChange={selectTypeHandle} style={{width: "100%", height: "25px", fontSize: "15px", marginTop: "10px"}}>
              <option disabled selected> -- Séléctionner une palette</option>
              
              {
                paletteList.map((palette)=>{
                  return <option key={palette.name} value={palette['@id']}>{palette.name}</option>;
                })
              }
              
              
            </select>
            </>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Fermer</Button>
          <Button onClick={handleAdd}>Ajouter</Button>
        </DialogActions>
    </Dialog>    

    </>);
}

export default AddPalette;