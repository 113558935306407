import * as React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button } from "@mui/material";
import { editCanaux } from "../../Services/Canaux/Canaux";

const EditCanaux = (props) =>{
  let open = props.open;
  let handleReload = props.handleReload;
  let toggleSnack = props.toggleSnack;
  let handleClose = props.handleClose;

  let name = props.name;
  let code = props.code;
  let id = props.target;
  let colorcode = props.colorcode;
  let hostname = props.hostname;
  let apikey = props.apikey;

  let handleCode = props.handleCode;
  let handleName = props.handleName;
  let handleColor = props.handleColor;
  let handleHostname = props.handleHostname;
  let handleApikey = props.handleApikey;
  




  function onChangeName(e){
    let target = e.target;
    handleName(target.value);
  }

  function onChangeCode(e){
    let target = e.target;
    handleCode(target.value);
  }

  function onChangeColor(e){
    let target = e.target;
    handleColor(target.value);
  }

  function onChangeHostname(e){
    let target = e.target;
    handleHostname(target.value);
  }

  function onChangeApikey(e){
    let target = e.target;
    handleApikey(target.value);
  }


  function saveToApi(){
    editCanaux(id, name, code, colorcode, hostname, apikey).then(agences => {
        handleReload();
        toggleSnack(true, "Sauvegardé avec succès", "success");
    });
    handleClose();
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Édition d'un canal</DialogTitle>
        <DialogContent style={{display: "flex", flexDirection: "column", gap: "10px"}}>
          <TextField id="filled-basic" onChange={onChangeName} label="Nom" value={name} variant="filled" />
          <TextField id="filled-basic" onChange={onChangeCode} label="Code" value={code} variant="filled" />
          <TextField id="filled-basic" onChange={onChangeColor} label="Couleur" value={colorcode} variant="filled" />
          <TextField id="filled-basic" onChange={onChangeHostname} label="Nom de domaine" value={hostname} variant="filled" />
          <TextField id="filled-basic" onChange={onChangeApikey} label="Clée api" value={apikey} variant="filled" />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button onClick={saveToApi}>Appliquer</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default EditCanaux;