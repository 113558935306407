import { useEffect, useState } from "react";
import { Chip, FormControl, InputLabel, LinearProgress, MenuItem, Select } from "@mui/material";

import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import { visuallyHidden } from '@mui/utils';
import {TextField} from "@mui/material";
import ChannelsPresenter from "../../component/Channels/ChannelsPresenter";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { getCommandesList, setPriorityToPrep, setStepToAnnuledPrep, setStepToPreparePrep } from "../../Services/Commandes/Commandes";
import { FaStopCircle, FaPlayCircle, FaPauseCircle, FaTrash, FaExclamationCircle } from 'react-icons/fa';
import ProfilePresenter from "../../component/Profile/ProfilePresenter";
import TeamGroupPresenter from "../../component/Teams/TeamGroupPresenter";
import NavettesArrivedPresenter from "../../component/NavetteDetails/NavettesArrivedPresenter";
import {Button} from "@mui/material";
import EventNoteIcon from '@mui/icons-material/EventNote';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import ActionsDialogue from "./ActionsDialogue";
import ButtonActionStep from "../../component/Button/ButtonActionStep";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import BtnSeeImgPrep from "../../component/Button/BtnSeeImgPrep";
import StepsDisplayer from "../../component/Etapes/StepsDisplayer";
import StepsMultipleSelect from "../../component/Filter/StepsMultipleSelect";
import TransporteurMultipleSelect from "../../component/Filter/TransporteurMultipleSelect";

const UpdatePriorityInList = (props) =>{
  let id_target = props.id_target;
  let priorityprops = props.priority;
  const [priority, setPriority] = useState(priorityprops);

  function UpdatePriority(e){
    let value = e.target.value;
    setPriority(value);
    setPriorityToPrep(id_target, value);
  }

  return (<input type="number" value={priority} onChange={UpdatePriority} style={{ width : "50px"}} />)
};


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
}
  
function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
}
  
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}
  
const headCells = [
    {
      id: 'id',
      numeric: false,
      disablePadding: true,
      textAlign: "left",
      label: 'ID',
    },
    {
      id: 'Fonctions',
      numeric: false,
      disablePadding: false,
      textAlign: "center",
      label: 'Fonctions',
    },
    {
      id: 'channel.code',
      numeric: false,
      disablePadding: false,
      textAlign: "center",
      label: 'Commandes',
    },
    {
      id: 'state.code',
      numeric: false,
      disablePadding: false,
      textAlign: "center",
      label: 'État',
    },
    {
      id: 'carrier.name',
      numeric: false,
      disablePadding: false,
      textAlign: "center",
      label: 'Transport',
    },
    {
      id: 'payment',
      numeric: false,
      disablePadding: false,
      textAlign: "center",
      label: 'Paiement',
    },
    {
      id: 'priority',
      numeric: false,
      disablePadding: false,
      textAlign: "center",
      label: 'Priorité',
    },
    {
      id: 'order_preparations',
      numeric: false,
      disablePadding: false,
      textAlign: "center",
      label: 'Avancement',
    },
    {
      id: 'navettes',
      numeric: false,
      disablePadding: false,
      textAlign: "center",
      label: 'Navettes',
    },
    {
      id: 'assignment.firstName',
      numeric: false,
      disablePadding: false,
      textAlign: "center",
      label: 'Assigné',
    },
    {
      id: 'usersHistories',
      numeric: false,
      disablePadding: false,
      textAlign: "center",
      label: 'Equipe',
    },
    {
      id: 'totalProducts',
      numeric: false,
      disablePadding: false,
      textAlign: "center",
      label: 'Produits',
    },
    {
      id: 'totalProductsQuantity',
      numeric: false,
      disablePadding: false,
      textAlign: "center",
      label: 'Quantité',
    },
    {
      id: 'totalPalettes',
      numeric: false,
      disablePadding: false,
      textAlign: "center",
      label: 'Palettes',
    },
    {
      id: 'spot',
      numeric: false,
      disablePadding: false,
      textAlign: "center",
      label: 'Emplacement',
    },
];
  
function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
      props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
  
    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              style={{textAlign: headCell.textAlign}}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
}
  
EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};
  
function EnhancedTableToolbar(props) {
    const { numSelected } = props;
    let handleSearch = props.handleSearch;
    let searchval = props.searchval;
    let handleSearchCustomer = props.handleSearchCustomer;
    let searchvalCustomer = props.searchvalcustomer;

    let handleSearchCanal = props.handleSearchCanal;
    let searchCanal = props.searchCanal;

    let handleSearchIdPrep = props.handleSearchIdPrep;
    let searchIdPrep = props.searchIdPrep;

    let triggerAction = props.triggerAction;

    
    const [transporteurName, settransporteurName] = React.useState([]);

    function handleClick(e){
       let target = e.target.getAttribute("target");
       triggerAction(target);
    }

    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: '1 1 100%' }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} séléctionné(s)
          </Typography>
        ) : (
          <></>
        )}
  
        {numSelected > 0 ? (
          <>
            <Button target={"aprep"} onClick={handleClick} style={{whiteSpace: "nowrap", width: "20%", backgroundColor: "rgb(46, 125, 50)", borderRadius: "10px 0px 0px 10px"}} variant="contained" startIcon={<EventNoteIcon />}>À PRÉPARER</Button>
            <Button target={"assignnavette"} onClick={handleClick} style={{whiteSpace: "nowrap", width: "20%", backgroundColor: "rgb(25, 118, 210)", borderRadius: "0px 0px 0px 0px"}} variant="contained" startIcon={<LocalShippingIcon />}>NAVETTE</Button>
            <Button target={"setpriority"} onClick={handleClick} style={{whiteSpace: "nowrap", width: "20%", backgroundColor: "rgb(156, 39, 176)", borderRadius: "0px 0px 0px 0px"}} variant="contained" startIcon={<WarningAmberIcon />}>PRIORITÉ</Button>
            <Button target={"assigntouser"} onClick={handleClick} style={{whiteSpace: "nowrap", width: "20%", backgroundColor: "rgb(156, 39, 176)", borderRadius: "0px 0px 0px 0px"}} variant="contained" startIcon={<AssignmentTurnedInIcon />}>ASSIGNER</Button>
            <Button target={"annulerprep"} onClick={handleClick} style={{whiteSpace: "nowrap", width: "20%", backgroundColor: "#e74c3c", borderRadius: "0px 10px 10px 0px"}} variant="contained" startIcon={<WarningAmberIcon />}>ANNULER</Button>
        </>
        ) : (
          <>
            <TextField id="filled-basic" label="ID de prép" value={searchIdPrep} onInput={handleSearchIdPrep} variant="filled" />
            <TextField id="filled-basic" label="Numéro de commande" value={searchval} onInput={handleSearch} variant="filled" style={{marginLeft: "10px"}}  />
            <TextField id="filled-basic" label="Client" value={searchvalCustomer} onInput={handleSearchCustomer} variant="filled" style={{marginLeft: "10px"}} />
            <StepsMultipleSelect setFilterSteps={props.setFilterSteps} filterStepsName={props.filterStepsName} setFilterStepsName={props.setFilterStepsName}/>
            <TransporteurMultipleSelect setFilterTransporteurs={props.setFilterTransporteurs} transporteurName={transporteurName} settransporteurName={settransporteurName}/>
            <FormControl variant="filled" style={{width: "155px"}}>
              <InputLabel id="select-canal-label">Canal</InputLabel>
              <Select
                labelId="select-canal-label"
                id="select-canal"
                value={searchCanal}
                label="Cannal"
                onChange={handleSearchCanal}
              > 
                <MenuItem value={""}>Aucun</MenuItem>
                <MenuItem value={6}>ES</MenuItem>
                <MenuItem value={5}>OC</MenuItem>
              </Select>
            </FormControl>
          </>
        )}
      </Toolbar>
    );
}
  
EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};
  

const CommandesList = (props) =>{
    const [reload, setReload] = useState(false);
    const [search, setSearch] = useState("");
    const [searchCustomer, setSearchCustomer] = useState("");
    const [searchIdPrep, setSearchIdPrep] = useState("");
    const [searchCanal, setSearchCanal] = useState("");

    const [filterSteps, setFilterSteps] = useState("");
    const [filterStepsName, setFilterStepsName] = useState([]);
    const [filterTransporteurs, setFilterTransporteurs] = useState("");

    const filterInject = props.filter;


    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('nom');
    const [selected, setSelected] = useState([]);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const [allChecked, setallChecked] = useState(false);
    
    /***
     * Dialog
     */
    const [open, setOpen] = useState(false);
    const [action, setAction] = useState(null);


    let toggleSnack = props.toggleSnack;

    function handleSearch(e){
      let target = e.target;
      setSearch(target.value);
    }

    function handleSearchCustomer(e){
      let target = e.target;
      setSearchCustomer(target.value);
    }

    function handleSearchIdPrep(e){
      let target = e.target;
      setSearchIdPrep(target.value);
    }

    function handleSearchCanal(e){
      let target = e.target;
      setSearchCanal(target.value);
    }
    
    function handleReload(){
      setReload(!reload);
      setLoading(false);
    }

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    function triggerAction(action2){
      setAction(action2);
      if(action2 !== "aprep" && action2 !== "annulerprep"){
          handleClickOpen();
      }else if(action2 === "annulerprep"){
        selected.forEach((order_prep_id) => {
          setStepToAnnuledPrep(order_prep_id);
        });
        setTimeout(() => {
          handleReload();
        }, 1000)
      }else{
        selected.forEach((order_prep_id) => {
          setStepToPreparePrep(order_prep_id);
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000)
      }
    }

    /***
     * ---------
     */

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
      if(allChecked){
        setSelected([]);
        setallChecked(false);
      }else{
        const newSelected = rows.map((n) => n.id);
        setSelected(newSelected);
        setallChecked(true);
        return;
      }
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };
    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const isSelected = (name) => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    //const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    useEffect(() => {
      
        setLoading(true);
        getCommandesList(search, page, rowsPerPage, filterInject, filterSteps, filterTransporteurs, searchCustomer, searchIdPrep, searchCanal).then(users => {
            if(users === "error"){
                    setLoading(false);
                    setError(true);
            }else{
                    setLoading(false);
                    setRows(users['hydra:member']);
                    setTotal(users['hydra:totalItems']);
            }
        });
      
    }, [reload, search, page, rowsPerPage, filterInject, filterSteps, filterTransporteurs, searchCustomer, searchIdPrep, searchCanal]);

    function LabelDisplayedRows({ from, to, count }) { return `${from}–${to} de ${count !== -1 ? count : `Un peu plus de ${to}`}`; }
    
    
    function deleteProductSelected(){
      //deleteUsersListSelected(selected);
      //handleReload();
      toggleSnack(true, "Désactivé pour le moment, constraint foreign key", "error");
    }


 
    
    if(error){
        return(<p>Erreur lors du chargement de la liste</p>)
    }else{
        return(<>
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <EnhancedTableToolbar setFilterSteps={setFilterSteps} setFilterStepsName={setFilterStepsName} filterStepsName={filterStepsName} setFilterTransporteurs={setFilterTransporteurs} handleSearch={handleSearch} searchval={search} handleSearchCustomer={handleSearchCustomer} searchvalcustomer={searchCustomer} searchCanal={searchCanal} handleSearchCanal={handleSearchCanal} searchIdPrep={searchIdPrep} handleSearchIdPrep={handleSearchIdPrep} deleteUserSelected={deleteProductSelected} triggerAction={triggerAction} numSelected={selected.length} />
                <TableContainer sx={{maxHeight: "60vh"}}>
                  
                {loading ? <LinearProgress/>:""}
                <Table
                    sx={{ minWidth: 750 }}
                    stickyHeader
                    aria-labelledby="tableTitle"
                    size={'medium'}
                >
                    <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={total}
                    />
                    
                    <TableBody>
                    {stableSort(rows, getComparator(order, orderBy))
                        .map((row, index) => {
                        const isItemSelected = isSelected(row.id);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        let icons;
                        if(row.state.icon==="FaStopCircle"){
                            icons = <FaStopCircle style={{color: row.state.color}}/>
                        }else if(row.state.icon==="FaPlayCircle"){
                            icons = <FaPlayCircle style={{color: row.state.color}}/>
                        }else if(row.state.icon==="FaPauseCircle"){
                            icons = <FaPauseCircle style={{color: row.state.color}}/>
                        }else if(row.state.icon==="FaTrash"){
                            icons = <FaTrash style={{color: row.state.color}}/>
                        }else if(row.state.icon === "FaExclamationCircle"){
                            icons = <FaExclamationCircle style={{color: row.state.color}}/>
                        }
                        return (
                            <TableRow
                            hover
                            onClick={(event) => handleClick(event, row.id)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.id}
                            selected={isItemSelected}
                            >
                            <TableCell padding="checkbox">
                                <Checkbox
                                color="primary"
                                checked={isItemSelected}
                                inputProps={{
                                    'aria-labelledby': labelId,
                                }}
                                />
                            </TableCell>
                            <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                            >
                                {row.id}
                            </TableCell>
                            <TableCell align="center"><span style={{display:"flex", gap:"5px", padding: "5px"}}><ButtonActionStep target={"/order_preparations/"+row.id} step={row.step}/><BtnSeeImgPrep data={row.id}/><a href={"/preparation/"+row.id+"/view"}><Button style={{height: "100%"}}><RemoveRedEyeIcon/></Button></a></span></TableCell>
                            <TableCell align="center"><ChannelsPresenter showCustomer={true} channels={[row.channel['@id']]} number={row.orderId} id_prep={row.id}/></TableCell>
                            <TableCell align="center">{icons}</TableCell>
                            <TableCell align="center"><Chip label={row.carrier.name}/></TableCell>
                            <TableCell align="center">{row.payment? <CheckIcon style={{color: "green"}} />:<CloseIcon style={{color: "red"}}/>}</TableCell>
                            <TableCell align="center"><UpdatePriorityInList id_target={row.id} priority={row.priority}/></TableCell>
                            <TableCell align="center"><StepsDisplayer data={row}/></TableCell>
                            <TableCell align="center" style={{display: "column"}}><NavettesArrivedPresenter navettes={row.navettes}/></TableCell>
                            <TableCell align="center">{row.assignment? <ProfilePresenter type={"team"} name={row.assignment.firstName + " " + row.assignment.lastName}/> : ""}</TableCell>
                            <TableCell align="center">{row.usersHistories? <TeamGroupPresenter from={"commandes_list"} team={row.usersHistories}/> : ""}</TableCell>
                            <TableCell align="center">{row.totalProducts}</TableCell>
                            <TableCell align="center">{row.totalProductsQuantity}</TableCell>
                            <TableCell align="center">{row.totalPalettes}</TableCell>
                            <TableCell align="center"><span style={{backgroundColor: row.spot.color, fontWeight: "bold", padding:"5px", borderRadius: "5px"}}>{row.spot.spot}</span></TableCell>
                            </TableRow>
                        );
                        })}
                    
                    </TableBody>
                </Table>
                </TableContainer>
                <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                labelRowsPerPage={"Nombre par page"}
                labelDisplayedRows={LabelDisplayedRows}
                component="div"
                count={total}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <ActionsDialogue handleReload={handleReload} open={open} action={action} selected={selected} handleClose={handleClose}/>
            </Paper>
            </Box>
            
        </>)
    }
   
}
export default CommandesList;





