import { canAccess } from "../../Services/Auth/Authentification";
import { Navigate } from "react-router-dom";
import TransporteursList from "./TransporteursList";
const Transporteurs = (props) =>{
    let permNeeded = props.permNeeded;
    

    if(!canAccess(permNeeded)){
        return(
            <Navigate to={"/"}/>
        )
    }else{
        

        
        return(<>
        
            <h1>Liste des Transporteurs</h1>
            <hr></hr>
            <TransporteursList togglesnack={props.togglesnack}/>
        </>)
    }
}

export default Transporteurs;