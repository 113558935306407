import { CircularProgress, LinearProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { getListSpecificNavette } from "../../Services/Navettes/Navettes";

const NavettesArrivedPresenter = (props) =>{
    let navettes_id = props.navettes;
    let tableDisplay = props.tableDisplay;
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);

    useEffect(() => {
        
        setLoading(true);
        var filter = "";
        navettes_id.forEach((navette_id)=>{
            filter += ("&id[]="+navette_id);
        });
        if(filter != ""){
            getListSpecificNavette(filter).then((data)=>{
                setData(data['hydra:member']);
                setLoading(false);
            });
        }else{
            setLoading(false);
        }
    }, [navettes_id])

    if(loading){
        if(tableDisplay){
            return <LinearProgress/>;
        }else{
            return <CircularProgress/>;
        }
    } 

    if(data !== null){
        return(<>

            {
            tableDisplay ?
            data.map((item)=>{
                return (
                        <>{new Date(Date.parse(item.estimatedAt)).toLocaleDateString("fr") + " à " + new Date(Date.parse(item.estimatedAt)).toLocaleTimeString("fr")} ({item.carrier.name})</>
                
                );
            }) :

            data.map((item)=>{
                const date = new Date(Date.parse(item.estimatedAt));
                const day = date.getDate();
                const month = (date.getMonth() + 1).toString().padStart(2, "0");
                const formattedDate = `${day}/${month}`;
                const formattedTime = date.toLocaleTimeString("fr").substr(0, 5);
                return (
                    <>
                      <a target={"_blank"} rel={"noreferrer"} href={item['@id']} style={{display:"flex"}}>
                        <span style={{display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "lightgrey", borderRight: "1px solid black", padding: "10px", width: "30%", borderRadius: "10px 0px 0px 10px", border: "1px solid black" }}>
                            <span>{formattedDate}</span> 
                            <span>{formattedTime}</span> 
                        </span>
                        <span style={{display: "grid", alignItems: "center", padding: "10px", width: "70%", border: "1px solid black", borderLeft: "0px"}}>
                            {item.carrier.name}
                        </span>
                      </a>
                      <br/><br/>
                    </>
                );
            })
            
            }
        
        </>);
    }else{
        return(<></>);
    }
    
}
export default NavettesArrivedPresenter;