import { CircularProgress } from "@mui/material";
import { useState, useEffect } from "react";
import { getChannels } from "../../Services/Channels/Channels";
import ChannelPresenter from "./ChannelPresenter";

const ChannelsPresenter = (props) =>{
    let channels_ids = props.channels;
    let number = props.number; // only when is one value in channels_id so its a combine with channel code & order number
    let id_prep = props.id_prep;
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);

    useEffect(() => {
        setLoading(true);
        getChannels().then(carriers => {
                    setData(carriers['hydra:member']);
                    setLoading(false);
        });
      
    }, []);
    if(loading){
        return <CircularProgress size="1rem"/>
    }else{
        if(data && data.length > 0){
            return (<>
                {
                    data.map((channel, index)=>{
                        return channels_ids.includes("/channels/"+channel.id)? <ChannelPresenter key={index} showCustomer={props.showCustomer} channel={channel} number={number} id_prep={id_prep}/>: <span key={index}></span>
                    })
    
                }
            
            </>);
        }else{
            return <CircularProgress/> 
        }
        
    }

}
export default ChannelsPresenter;



export const ChannelsPresenterWithoutFetch = (props) =>{
    let channel = props.channel;
    let number = props.number; // only when is one value in channels_id so its a combine with channel code & order number
    let id_prep = props.id_prep;
    let customer = props.customer;
    return <ChannelPresenter showCustomer={true} customerData={customer} channel={channel} number={number} id_prep={id_prep}/>
}