import React, { useState } from "react";
import Router from "./Services/Router/Router";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import { APP_NAME } from "./global-config";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function App() {
  const [openSnack, setOpenSnack] = useState(false);
  const [snackSeverity, setSnackSeverity] = useState("success");
  const [snackMessage, setSnackMessage] = useState("");

  const toggleSnack = (open, message, severity) => {
    setOpenSnack(open);
    setSnackMessage(message);
    setSnackSeverity(severity);
  }

  const TransitionRight = (props) => {
    return <Slide {...props} direction="right" />;
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
  };

  const pageTitle = `${APP_NAME}`;
  document.title = pageTitle;
  
  return (
    <>
      <Snackbar TransitionComponent={TransitionRight} anchorOrigin={{vertical: "top", horizontal:"center"}} open={openSnack} autoHideDuration={2000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={snackSeverity}>
          {snackMessage}
        </Alert>
      </Snackbar>
      <Router toggleSnack={toggleSnack}/>
    </>
    
  );
}

export default App;
