import { canAccess } from "../../Services/Auth/Authentification";
import { Navigate } from "react-router-dom";

import React from "react";
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import NavettesList from "./NavettesList";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
}
  
TabPanel.propTypes = {
children: PropTypes.node,
index: PropTypes.number.isRequired,
value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const Navettes = (props) =>{
    let permNeeded = props.permNeeded;
    let toggleSnack = props.toggleSnack;
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    if(!canAccess(permNeeded)){
        return(
            <Navigate to={"/"}/>
        )
    }else{
        

        
        return(<>
        
            <h1>Navettes</h1><hr></hr>
            <Box sx={{ bgcolor: 'background.paper', width: "100%" }} centered={true}>
                <AppBar position="static">
                    <Tabs
                    value={value}
                    onChange={handleChange}
                    TabIndicatorProps={{style: {background:'white'}}}
                    textColor="inherit"
                    variant="fullWidth"
                    >
                    <Tab label="Liste des navettes programmées" {...a11yProps(0)} />
                    {props.fromLogisticienMenu? <Tab disabled label="Liste des navettes déja parties" {...a11yProps(1)} />: <Tab label="Liste des navettes déja parti" {...a11yProps(1)} />}
                    </Tabs>
                </AppBar>
                    <TabPanel value={value} index={0} dir={theme.direction}>
                        <NavettesList toggleSnack={toggleSnack} fromLogisticienMenu={props.fromLogisticienMenu} alreadysend={true}/>
                    </TabPanel>
                    <TabPanel value={value} index={1} dir={theme.direction}>
                        <NavettesList toggleSnack={toggleSnack} alreadysend={false}/>
                    </TabPanel>
            </Box>
        </>)
    }
}

export default Navettes;