
import { Outlet} from "react-router-dom";
import { Navigate } from "react-router-dom";
import { canAccess } from "../../Services/Auth/Authentification";
import Navbar from "./Navbar";
import AppBar from "../AppBar/AppBar";
import ChatBox from "./ChatBox";

const LayoutPage = (props) => {
  var permNeeded = props.permNeeded;
  if(canAccess(permNeeded)){
    return (
      <>  
          <AppBar/>
          <Navbar outlet={<Outlet/>}/>
          {/**<ChatBox/>**/}
      </>
    )
    
  }else{
    return(
      <Navigate to={"/login"}/>
    )
  }
  
};

export default LayoutPage;