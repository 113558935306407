import { CircularProgress, LinearProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { getAssignedPrep, getCurrentOrderPrep, getIdWithEmail, getOnePrepProposal, getPrepProposal } from "../../Services/Auth/Authentification";
import { getOnePrep } from "../../Services/Commandes/Commandes";
import { getPreparationCurrentStep } from "../../Services/Preparation/Preparation";
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import ChannelPresenter from "../../component/Channels/ChannelPresenter";
import ButtonActionStep from "../../component/Button/ButtonActionStep";
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));
  

const RedirectToPrep = (props) =>{
    let currentStep = props.currentStep;
    
    const [loading, setLoading] = useState(true);
    const [loadingProposal, setLoadingProposal] = useState(true);
    const [orderprepID, setorderprepID] = useState("");
    const [idUser, setidUser] = useState(null);
    const [prepProposal, setPrepProposal] = useState(null);

    
    useEffect(() => {
        getIdWithEmail().then((data)=>{
            setidUser(data['hydra:member'][0]['@id'])
        })
    });

    
    useEffect(()=>{
        const interval = setInterval(() => {
            
            
            getPrepProposal(currentStep).then(prep=>{
                setPrepProposal(prep);
            });
        }, 5000);
        return () => clearInterval(interval);
    }, [currentStep]);


    

    useEffect(() => {
        if(idUser != null){
            setLoadingProposal(true);
            setPrepProposal(null);
            getCurrentOrderPrep(idUser).then(order_prep_id => {
                if(typeof order_prep_id === "undefined" || order_prep_id === null){

                    setorderprepID({"@id": "Aucune préparation"});
                    setLoading(false);
                    
                    getPrepProposal(currentStep).then(prep=>{
                        setPrepProposal(prep);
                        setLoadingProposal(false);
                    });
                }else{

                    getOnePrep(order_prep_id.replace("/order_preparations/","")).then((prep)=>{
                        if(typeof prep['@id'] === 'undefined'){
                            setorderprepID({"@id": "Aucune préparation"});
                        }else{
                            setorderprepID(prep);
                        }
                        
                        setLoading(false);
                        getPrepProposal(currentStep).then(prep=>{
                            setPrepProposal(prep);
                            setLoadingProposal(false);
                        });
                    });
                }
                 
            });
        }
    }, [idUser, currentStep]);

   

    function getToDisplayable(step){
        if(step === "prepare"){
            return "Préparation";
        }else if(step === "control"){
            return "Contrôler";
        }else if(step === "control-responsible"){
            return "Contrôler par un Responsable";
        }else if(step === "pack"){
            return "Emballer";
        }else if(step === "charge"){
            return "Charger";
        }else if(step === "expect"){
            return "Expédié";
        }
    }

    return(<>
            <h1>ÉTAPE : {getToDisplayable(currentStep)}</h1><hr></hr>
            <div>
                <Stack
                    style={{width: "100%", paddingBottom: "50px", alignItems: "center", textAlign: "center", justifyContent: "center"}}
                    direction={{ xs: 'column', sm: 'row' }}
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={{ xs: 1, sm: 2, md: 4 }}
                >
                    <Item>
                        <h1>Votre préparation en cours</h1> 
                        {loading? <CircularProgress  size="1rem" /> : <h2>{orderprepID['@id'] !== ""?orderprepID['@id'].includes("Aucune préparation")?orderprepID['@id']:"n°"+orderprepID['@id'].replace('/order_preparations/', ""):<></>}</h2>}

                        {!loading && !orderprepID['@id'].includes("Aucune préparation")? 
                            <>
                                
                                Client : {orderprepID.customer.lastname} {orderprepID.customer.firstname} <br></br>
                                Numéro : {orderprepID.customer.phoneNumber} <br></br>
                                Société : {orderprepID.customer.company} <br></br>
                                
                                <br></br>
                                    <ChannelPresenter channel={orderprepID.channel} number={orderprepID.orderId}/>
                                <br></br>
                                <br></br>

                                Étape actuelle : {getToDisplayable(orderprepID.currentStep)}<br></br>

                                <ButtonActionStep step={orderprepID.step} target={orderprepID['@id']}/>
                                <table style={{width: "100%"}}>
                                    <thead>
                                        <tr>
                                            <th>Produit(s)</th>
                                            <th>Quantité</th>
                                            <th>Palette(s)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{orderprepID.totalProducts}</td>
                                            <td>{orderprepID.totalProductsQuantity}</td>
                                            <td>{orderprepID.totalPalettes}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <br></br>
                            </>
                                :
                            <></>
                        }
                    </Item>
                    <Item>
                        <h1>Votre préparation suivante</h1>
                        {loadingProposal? <CircularProgress  size="1rem" /> : <h2>{prepProposal['@id'] !== ""?prepProposal['@id'].includes("Aucune préparation")?prepProposal['@id']:"n°"+prepProposal['@id'].replace('/order_preparations/', ""):<></>}</h2>}

                        {!loadingProposal && !prepProposal['@id'].includes("Aucune préparation")? 
                            <>
                                
                                Client : {prepProposal.customer.lastname} {prepProposal.customer.firstname} <br></br>
                                Numéro : {prepProposal.customer.phoneNumber} <br></br>
                                Société : {prepProposal.customer.company} <br></br>
                                
                                <br></br>
                                    <ChannelPresenter channel={prepProposal.channel} number={prepProposal.orderId}/>
                                <br></br>
                                <br></br>

                                Étapes actuel : {getToDisplayable(prepProposal.currentStep)}<br></br>

                                <ButtonActionStep step={prepProposal.step} target={prepProposal['@id']}/>
                                <table style={{width: "100%"}}>
                                    <thead>
                                        <tr>
                                            <th>Produit(s)</th>
                                            <th>Quantité</th>
                                            <th>Palette(s)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{prepProposal.totalProducts}</td>
                                            <td>{prepProposal.totalProductsQuantity}</td>
                                            <td>{prepProposal.totalPalettes}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <br></br>
                            </>
                                :
                            <></>
                        }
                    </Item>
                </Stack>
            </div>
        </>)  
        
    
    
}

export default RedirectToPrep;