import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { setPriorityToPrepItem } from "../../Services/Commandes/Commandes";


const PriorityChanger = (props) =>{
    let oldpriority = props.priority;
    let id_item = props.id_item;
    let handleReload = props.handleReload;
    const [priority, setPriority] = useState(props.priority);
    const [canSave, setCanSave] = useState(false);

    function handlePlus(){
        setPriority(priority+1);
    }

    function handleMinus(){
        setPriority(priority-1);
    }

    function onSave(){
        setPriorityToPrepItem(id_item, priority).then((data)=>{
            handleReload();
        });
    }

    useEffect(() => {
        setCanSave(false);
        if(oldpriority != priority){
            setCanSave(true);
        }
        
    }, [priority]);

    return(<>
    
        
    
        <Box sx={{ flexGrow: 1 }} style={{textAlign: "center", width: "150px"}}>
            <Grid container style={{alignItems: "center"}}>
                <Grid item xs={6} md={6}>
                    <div style={{fontWeight: "bold", width: "100%", fontSize: "25px"}}>{priority}</div>
                </Grid>
                <Grid item xs={6} md={6}>
                    <Grid container>
                        <Grid item xs={6} md={12}>
                            <Button onClick={handlePlus} style={{backgroundColor: "#2ecc71", color:"white", fontWeight: "bold", width: "100%", fontSize: "25px", lineHeight: "30px", borderRadius: "0px"}}>+</Button>
                        </Grid>
                        <Grid item xs={6} md={12}>
                            <Button onClick={handleMinus} style={{backgroundColor: "#e74c3c", color:"white", fontWeight: "bold", width: "100%", fontSize: "25px", lineHeight: "30px", borderRadius: "0px"}}>-</Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6} md={12}>
                    {canSave?  
                    <Button variant="contained" onClick={onSave} style={{backgroundColor: "#3498db", color:"white", fontWeight: "bold", width: "100%", borderRadius: "0px"}}>SAUVEGARDER</Button>
                    :
                    <Button variant="contained" disabled={true} style={{backgroundColor: "#95a5a6", color:"white", fontWeight: "bold", width: "100%", borderRadius: "0px", cursor: "not-allowed"}}>SAUVEGARDER</Button>
                    }
                    
                </Grid>
            </Grid>
        </Box>
    </>);
}

export default PriorityChanger;