import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useState, useEffect } from 'react';
import { LinearProgress } from '@mui/material';
import { getCurrentOrderPrep, getIdWithEmail, getUsersFilteredByGeo, setCurrentOrderPrepForUsersList } from '../../Services/Auth/Authentification';
import TeamListPresenter from './TeamListPresenter';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle style={{marginRight: "30px"}} sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const AddToTeamDialog = (props) => {
  const [loading, setLoading] = useState(true);
  const [haveprep, setHaveprep] = useState(true);
  const [users, setUsers] = useState([]);
  const [checked, setChecked] = useState([]);

  let open = props.toggleOpen;
  let setclose = props.close;
  let handleReload = props.handleReload;

  useEffect(() => {
    getIdWithEmail().then((user)=>{
      getCurrentOrderPrep(user['hydra:member'][0]['@id']).then(order_prep_id => {
        if(order_prep_id === "error" || order_prep_id === undefined){
                setHaveprep(false);
                setLoading(false);
        }else{
                setLoading(false);
                getUsersFilteredByGeo().then(users => {
                    if(users === "error"){
                           setLoading(false);
                    }else{
                            setLoading(false);
                            setUsers(users);
                    }
                });
        }
      });
    });
    
  }, []);

  useEffect(() => {
    setChecked([]);
  }, [open]);

  const handleCheck = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
  };

  function saveCheckedListToTeam(){
    setCurrentOrderPrepForUsersList(checked).then((result)=>{
      handleReload();
      setclose();
    });
    
  }
  return (
    <div>
      <BootstrapDialog
        onClose={setclose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={setclose}>
          Ajout à votre préparation en cours
        </BootstrapDialogTitle>
        <DialogContent dividers>
            {loading ? <LinearProgress/> : (haveprep ? <TeamListPresenter handlecheck={handleCheck} users={users}/>: <p>Aucune préparation en cours</p>)}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={saveCheckedListToTeam}>
            Ajouter à votre préparation
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}

export default AddToTeamDialog;