import { ENTRY_POINT } from "../../global-config";
import { getToken, ValidateToken } from "../Auth/Authentification";

export async function getProductList(search, page, itemperpage){
    const options = {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + getToken()
        }
      };
      
    page = page+1;
      
    let data = await fetch(ENTRY_POINT+"/products?name="+search+"&order%5Bid%5D=ASC&page="+page+"&itemsPerPage="+itemperpage, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => {
            console.error(error);
        });
    
    if(ValidateToken(data) || data !== undefined){
        return data;
    }else{
        return "error";
    }
}

export function editProduct(id, name, packagingUnit, packagingType, packagingQuantity, weight, length, width, height, comment){
   
    const options = {
        method: 'PUT',
        headers: {
        'Content-Type': 'application/ld+json',
        Authorization: 'Bearer ' + getToken()
        },
        body: '{"name":"'+name+'", "packagingUnit":"'+packagingUnit+'", "packagingType":"'+packagingType+'", "packagingQuantity":'+parseInt(packagingQuantity)+', "weight":'+parseInt(weight)+', "length":'+parseInt(length)+', "width":'+parseInt(width)+', "height":'+parseInt(height)+', "comment":"'+comment+'"}'
    };
  
    let data = fetch(ENTRY_POINT+"/products/"+id, options)
            .then((response) => response.json())
            .then(data => {
                return data;
            })
            .catch(error => {
                console.error(error);
            });


    
    if(ValidateToken(data)){
        return data;
    }else{
        return "error";
    }
}

export async function getOneProduct(id_product){
    const options = {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + getToken()
        }
      };
      
      
    let data = await fetch(ENTRY_POINT+id_product, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => {
            console.error(error);
        });
    
    if(ValidateToken(data) || data !== undefined){
        return data;
    }else{
        return "error";
    }
}
