import { Fab, LinearProgress, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {library} from "@fortawesome/fontawesome-svg-core";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
    faPen
} from "@fortawesome/free-solid-svg-icons";
import { getPaletteTypeList } from "../../Services/Palettes/Palettes";
library.add(faPen);

const ChangeType = (props) => {
    let actual_palette_id = props.actual_palette.type['@id'];
    const [open, setOpen] = useState(false);
    const [paletteList, setPaletteList] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedValue, setSelectedValue] = useState(actual_palette_id);


    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    function selectTypeHandle(e){
      let value = e.target.value;
      setSelectedValue(value);
    }

    useEffect(() => {
        setLoading(true);
        setSelectedValue(actual_palette_id);
        getPaletteTypeList().then((data)=>{
            setPaletteList(data['hydra:member']);
            setLoading(false);
        });
    
    }, [open]);

    function handleSave(){
      alert("Action non autorisé pour le moment");
      handleClose();
    }

    return (<>
    <Tooltip title={"Changer le type de la palette"}>
        <Fab style={{boxShadow:"unset"}} size={"small"} color="default" aria-label="Type" onClick={handleClickOpen}>
            <FontAwesomeIcon icon="fa-pen"/>
        </Fab>
    </Tooltip>
    <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Changement de Type</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Séléctionner le nouveau type de palette pour celle ci
          </DialogContentText>
          {

            loading? <LinearProgress/>:

            <select value={selectedValue} onChange={selectTypeHandle} style={{width: "100%", height: "25px", fontSize: "15px", marginTop: "10px"}}>
              
              {
                paletteList.map((palette)=>{
                  return <option key={palette.name} value={palette['@id']}>{palette.name}</option>;
                })
              }
              
              
            </select>
          
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Fermer</Button>
          <Button onClick={handleSave}>Sauvegarder</Button>
        </DialogActions>
    </Dialog>    

    </>);
}

export default ChangeType;