import { Button } from "@mui/material";
import { useState } from "react";
import { validateControlPaletteItem, validateControlRespPaletteItem } from "../../Services/Preparation/Preparation";

const ValidateProduct = (props) =>{
    let isStepResp = props.isStepResp;
    let id_palette_item = props.id_palette_item;
    let setNumberOfValidate = props.setNumberOfValidate;
    let numberOfValidate = props.numberOfValidate;

    const [defaultValue, setDefaultValue] = useState(props.defaultValue);
    const [isLoading, setIsLoading] = useState(false);

    function handleValidate(){
        if(isLoading) return;
        setIsLoading(true);
        if(defaultValue){
            setDefaultValue(!defaultValue);
            setNumberOfValidate(numberOfValidate - 1)
            if(isStepResp){
                validateControlRespPaletteItem(id_palette_item, "false").then((response) => {
                    setDefaultValue(response.controlledResponsible);
                    setIsLoading(false);
                }); 
            }else{
                validateControlPaletteItem(id_palette_item, "false").then((response) => {
                    setDefaultValue(response.controlled);
                    setIsLoading(false);
                }); 
            }
            
        }else{
            setDefaultValue(!defaultValue);
            setNumberOfValidate(numberOfValidate + 1);
            if(isStepResp){
                validateControlRespPaletteItem(id_palette_item, "true").then((response) => {
                    setDefaultValue(response.controlledResponsible);
                    setIsLoading(false);
                }); 
            }else{
                validateControlPaletteItem(id_palette_item, "true").then((response) => {
                    setDefaultValue(response.controlled);
                    setIsLoading(false);
                });
            }
            
        }
    }

    if(defaultValue){
        return (<Button onClick={handleValidate} variant="contained" color="error">Invalider</Button>)
    }else{
        return (<Button onClick={handleValidate} variant="contained"  color="success">Valider</Button>)
    }
}
export default ValidateProduct;
