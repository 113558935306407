import { useParams } from "react-router-dom";
import PreparationDisplayer from "../Preparation/PreparationDisplayer";

const EmballerShowWithoutRestriction = (props) => {

    let { id } = useParams();

    return (<>
        <PreparationDisplayer step={"pack"} id_prep={id}/>  
    </>)
}

export default EmballerShowWithoutRestriction;