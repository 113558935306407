import { canAccess } from "../../Services/Auth/Authentification";
import { Navigate } from "react-router-dom";
import CatalogueList from "./CatalogueList";
const Catalogue = (props) =>{
    let permNeeded = props.permNeeded;
    

    if(!canAccess(permNeeded)){
        return(
            <Navigate to={"/"}/>
        )
    }else{
        

        
        return(<>
        
            <h1>Liste du catalogue</h1>
            <hr></hr>
            <CatalogueList toggleSnack={props.toggleSnack}/>
        </>)
    }
}

export default Catalogue;