
import { getRoles, removeCurrentOrderPrep } from "../../Services/Auth/Authentification";
import { Avatar, IconButton, ListItemIcon, Menu, MenuItem, Tooltip } from "@mui/material";

import Logout from "@mui/icons-material/Logout";
import { useState } from "react";

function stringToColor(props) {
    
    var color = "";
    var roles = getRoles();
    if(props.type != null && props.type === "team"){
      color = "grey"
    }else{
      if(roles.includes("ROLE_ADMIN")){
        color = "red";
      }else if(roles.includes("ROLE_RESPONSABLE")){
        color = "orange";
      }else if(roles.includes("ROLE_LOGISTICIEN")){
        color = "blue";
      }else{
        color = "green";
      }
    }
    

  
    return color;
}

function stringAvatar(props) {
    return {
      sx: {
        bgcolor: stringToColor(props),
        border: 0,
      },
      style: {border: "0px"},
      children: `${props.name.split(' ')[0][0]}${props.name.split(' ')[1][0]}`,
    };
}

const ProfilePresenter = (props) =>{
    let canRemove = props.canRemove;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const LgOut = () =>{
      removeCurrentOrderPrep(props.id_user).then((result)=>{
          props.handleReload();
      });   
    }


    if(canRemove){
      
      return(
        <>
          <Tooltip title={props.name}>
          <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              style={{marginLeft: "0px"}}
            >
              <Avatar {...stringAvatar(props)} />
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0
                }
              }
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem onClick={LgOut}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Retirer de mon équipe
            </MenuItem>
        </Menu>
        </> );
    }
    return(<Tooltip title={props.name}><Avatar {...stringAvatar(props)} /></Tooltip>)
}

export default ProfilePresenter;