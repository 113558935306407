import { ENTRY_POINT } from "../../global-config";
import { getToken, ValidateToken } from "../Auth/Authentification";

export async function getNavettesList(page, itemperpage, alreadysend, selectedCarrieresFilter, labelName = ""){

    const options = {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + getToken()
        }
      };
    page = page+1;

    var filterLabelName = "";
    if(labelName != ""){
        filterLabelName = "&Label="+labelName;
    }

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    
    today = yyyy + '-' + mm + '-' + dd;
    if(alreadysend){

        alreadysend = "&charged=0";
    }else{
        

        alreadysend = "&charged=1";
    }

    var filtercarriername = "";
    selectedCarrieresFilter.selected.forEach((carrier_name)=>{
        filtercarriername += "&carrier.name[]="+carrier_name
    });



    let data = await fetch(ENTRY_POINT+"/navettes?order%5Bid%5D=ASC&page="+page+"&itemsPerPage="+itemperpage+filtercarriername+alreadysend+filterLabelName, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => {
            console.error(error);
        });
    
    if(ValidateToken(data) || data !== undefined){
        return data;
    }else{
        return "error";
    }
}

export async function createNavettes(carrier, estimatedAt, label){
    const options = {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + getToken(),
          'Content-Type': 'application/json',
        },
        body: '{"carrier":"'+carrier+'","estimatedAt":"'+estimatedAt+'","Label":"'+label+'"}'
    };
      
    let data = await fetch(ENTRY_POINT+"/navettes", options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => {
            console.error(error);
        });
    
    if(ValidateToken(data) || data !== undefined){
        return data;
    }else{
        return "error";
    }
}

export async function getOneNavette(id_navette){
    const options = {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + getToken()
        }
      };
      
    let data = await fetch(ENTRY_POINT+"/navettes/"+id_navette, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => {
            console.error(error);
        });
    
    if(ValidateToken(data) || data !== undefined){
        return data;
    }else{
        return "error";
    }
}


export async function getPalettesInNavette(id_navette){
    const options = {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + getToken()
        }
      };
      
    let data = await fetch(ENTRY_POINT+"/palettes?navette.id[]="+id_navette+"&page=1&itemsPerPage=999", options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => {
            console.error(error);
        });
    
    if(ValidateToken(data) || data !== undefined){
        return data;
    }else{
        return "error";
    }
}

export async function getPalettesWithoutNavette(){
    const options = {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + getToken()
        }
      };
      
    let data = await fetch(ENTRY_POINT+"/palettes?exists[navette]=false&packed=true&controlledResponsible=true&controlled=true", options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => {
            console.error(error);
        });
    
    if(ValidateToken(data) || data !== undefined){
        return data;
    }else{
        return "error";
    }
}

export async function setChargedNavette(id_navette, charged){
    const options = {
        method: 'PUT',
        headers: {
         'Content-Type': 'application/ld+json',
          Authorization: 'Bearer ' + getToken()
        },
        body: '{"charged": '+charged+'}'
      };
      
    let data = await fetch(ENTRY_POINT+id_navette, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => {
            console.error(error);
        });
    
    if(ValidateToken(data) || data !== undefined){
        return data;
    }else{
        return "error";
    }
}

export async function getListSpecificNavette(filter){
    const options = {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + getToken()
        }
      };
      
    let data = await fetch(ENTRY_POINT+"/navettes?"+filter, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => {
            console.error(error);
        });
    
    if(ValidateToken(data) || data !== undefined){
        return data;
    }else{
        return "error";
    }
}

export function deleteNavetteListSelected(navette_list){
    const options = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer '+getToken()
        }
      };
    const promises = navette_list.map(navette => {
        return fetch(ENTRY_POINT+navette, options)
            .then(response => response.json());
    });
    
    return Promise.all(promises);
}

export async function changeLabelNavette(id_navette, label){
    const options = {
        method: 'PUT',
        headers: {
         'Content-Type': 'application/ld+json',
          Authorization: 'Bearer ' + getToken()
        },
        body: '{"Label": "'+label+'"}'
      };
      
    let data = await fetch(ENTRY_POINT+id_navette, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => {
            console.error(error);
        });
    
    if(ValidateToken(data) || data !== undefined){
        return data;
    }else{
        return "error";
    }
}
