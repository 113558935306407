import * as React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button } from "@mui/material";
import { editAgences } from "../../Services/Agences/Agences";

const EditAgences = (props) =>{
  let open = props.open;
  let handleReload = props.handleReload;
  let toggleSnack = props.toggleSnack;
  let handleClose = props.handleClose;

  let name = props.name;
  let code = props.code;
  let id = props.target;
  let address = props.address;
  let phone = props.phone;

  let handleCode = props.handleCode;
  let handleName = props.handleName;
  let handleAddress = props.handleAddress;
  let handlePhone = props.handlePhone;
  




  function onChangeName(e){
    let target = e.target;
    handleName(target.value);
  }

  function onChangeCode(e){
    let target = e.target;
    handleCode(target.value);
  }

  function onChangeAddress(e){
    let target = e.target;
    handleAddress(target.value);
  }

  function onChangePhone(e){
    let target = e.target;
    handlePhone(target.value);
  }


  function saveToApi(){
    editAgences(id, name, code, address, phone).then(agences => {
        handleReload();
        toggleSnack(true, "Sauvegardé avec succès", "success");
    });
    handleClose();
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Édition d'agence</DialogTitle>
        <DialogContent style={{display: "flex", flexDirection: "column", gap: "10px"}}>
          <TextField id="filled-basic" onChange={onChangeName} label="Nom" value={name} variant="filled" />
          <TextField id="filled-basic" onChange={onChangeCode} label="Code" value={code} variant="filled" />
          <TextField id="filled-basic" onChange={onChangeAddress} label="Adresse" value={address} variant="filled" />
          <TextField id="filled-basic" onChange={onChangePhone} label="Téléphone" value={phone} variant="filled" />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button onClick={saveToApi}>Appliquer</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default EditAgences;