import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getOnePalette } from "../../Services/Palettes/Palettes";
import LinearProgress from '@mui/material/LinearProgress';
import ChannelsPresenter from "../../component/Channels/ChannelsPresenter";
import { Grid } from "@mui/material";
import ProductsListToControl from "../Controler/ProductsListToControl";
import ButtonPalettePrint from "../../component/Palettes/ButtonPrint";
import DisplayAttribAndCustomFromPaletteItem from "../../component/Product/DisplayAttribAndCustomFromPaletteItem";
import { getOnePrep } from "../../Services/Commandes/Commandes";

const PaletteDetail = (props) => {
    let { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [palette, setPalette] = useState(null);
    const [prep_data, setPrepData] = useState(null);
    
    useEffect(() => {
        setLoading(true);
        getOnePalette('/palettes/'+id).then(palette=>{

            console.log(palette);
            setPalette(palette);
            getOnePrep(palette.orderPreparation['@id'].replace("/order_preparations/", "")).then(prep=>{
                setPrepData(prep);
                console.log(prep);
                setLoading(false);
            })
        });
    }, [id]);

    const styles = {
        display: "flex",
        flexDirection: "column",
        fontSize: "18px", 
        fontWeight: "700", 
        WebkitBoxPack: "center", 
        justifyContent: "center", 
        alignItems: "center", 
        width: "100px",
        height: "100px", 
        minWidth: "100px", 
        minHeight: "100px", 
        marginTop: "8px",
        marginLeft: "auto",
        marginRight: "auto",
    }


    return (<>
  
        
        {loading ? <LinearProgress/>:
        <>
        <Grid container spacing={2}>
                <Grid item xs={1} md={2}>
                </Grid>
                <Grid item xs={10} md={8} style={{textAlign: "center"}}>
                {loading ?<></>:<ChannelsPresenter channels={[palette.orderPreparation.channel]} number={palette.orderPreparation.orderId} id_prep={palette.orderPreparation['@id'].replace("/order_preparations/", "")} showCustomer={true}/>}
                <span style={{fontWeight: "bold", fontSize: "18px", display: "flex", alignItems: "center", justifyContent: "center",color: "white", backgroundColor: "grey", margin: "0px", padding: "10px", borderRadius: "5px 5px 0px 0px", marginTop: "15px"}}>Palette n°{id} - {palette.weight / 1000}kg - {palette.volume}m3 - <ButtonPalettePrint id_palette={palette['@id']} palette={false} orderPreparation={prep_data}/></span>
                { palette.items.map((item, index) => {
                return <div style={{width: "100%", border: "1px solid black", display:"flex", padding:"10px", alignItems: "center", borderTop: "0px"}} key={index}>
                        <div style={{display:"flex", flexDirection: "column", width: "60%"}}>{item.product.name} <div><DisplayAttribAndCustomFromPaletteItem id_palette_item={item['@id']}/></div></div>
                        <div style={{...styles, border: "2px solid rgb(9, 88, 167)", backgroundColor: "rgb(25, 118, 210)", color:"white"}}><span>{item.quantity}</span> <span>{item.quantityToString.toUpperCase()}</span></div>
                        </div>
                })}
                </Grid>
                <Grid item xs={1} md={2}>
                    
                </Grid>
            </Grid>
        
        
        </>}
    </>);
}

export const GoToPaletteDetail = (props) => {
    const id_palette = props.id_palette;
    const text = props.text;
    const navigate = useNavigate();

    function goto(){
        navigate("/palette/"+id_palette+"/view");
    }

    return (
        <>
            <span style={{cursor: "pointer", padding: "3px", backgroundColor: "lightgrey", border: "1px solid black", borderRadius: "25px"}} onClick={goto}>{text}</span>
        </>
    )
}

export default PaletteDetail;