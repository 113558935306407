import { CircularProgress } from "@mui/material";
import { useState, useEffect } from "react";
import { getMediaObjectSrc } from "../../Services/MediaObject/MediaObject";
import {ENTRY_POINT} from "../../global-config";
import placeholder from "./../../img/placeholder.png";

const MediaObjectPresenter = (props) =>{
    const [srcImg, setSrcImg] = useState("");
    const [loading, setLoading] = useState(true);
    let width = props.width;
    let height = props.height;
    let MediaObjectId = props.mediaobjectid;

    const onImageError = (e) => {
        e.target.src = placeholder;
    }  

    useEffect(() => {
      
        setLoading(true);
        if(MediaObjectId !== "" && MediaObjectId !== undefined){
            
            getMediaObjectSrc(MediaObjectId).then(urlMedia => {
                setSrcImg(urlMedia);
                setLoading(false);
            });
        }
    }, [MediaObjectId]);

    if(props.type==="reconstructUrl"){
        return <img src={ENTRY_POINT+ "/media/"+props.ean+".png"} alt="Product" onError={onImageError} width={width} height={height}/>
    }

    if(MediaObjectId === "" || MediaObjectId === undefined){
        return <img alt={"placeholder:"+width+"x"+height} src={placeholder} />
    }

    if(loading) return <CircularProgress/>

    return(<>
        <img alt={srcImg} src={srcImg} onError={onImageError} width={width} height={height}/>
    </>)
}
export default MediaObjectPresenter;