import { BrowserRouter, Routes, Route } from "react-router-dom";

import Layout from "./../../Pages/Layout/Layout.js";
import DashPage from "./../../Pages/Dash/Dash.js";
import LoginPage from "./../../Pages/Login/Login.js";
import NoPage from "./../../Pages/NoPage/Nopage.js";

import RedirectToPrep from "../../Pages/Preparation/RedirectToPrep.js";
import Preparation from "../../Pages/Preparation/Preparation.js";
import Utilisateur from "../../Pages/Utilisateurs/Utilisateurs.js";



import StateCommande from "../../Pages/StateCommande/StateCommande.js";
import StepCommande from "../../Pages/StepCommande/StepCommande.js";
import Emplacements from "../../Pages/Emplacements/Emplacements.js";
import Transporteurs from "../../Pages/Transporteurs/Transporteurs.js";
import Canaux from "../../Pages/Canaux/Canaux.js";
import Agences from "../../Pages/Agences/Agences.js";
import Marques from "../../Pages/Marques/Marques.js";
import Souffrances from "../../Pages/Souffrances/Souffrances.js";
import Depots from "../../Pages/Depots/Depots.js";
import Catalogue from "../../Pages/Catalogue/Catalogue.js";
import Navettes from "../../Pages/Navettes/Navettes.js";
import NavetteDetails from "../../Pages/Navettes/NavetteDetails.js";
import Palettes from "../../Pages/Palettes/Palettes.js";
import Commandes from "../../Pages/Commandes/Commandes.js";
import CommandeDetails from "../../Pages/Commandes/CommandeDetails.js";
import PaletteDetail from "../../Pages/Palettes/PaletteDetail.js";
import NavetteRespDetails from "../../Pages/Navettes/NavetteRespDetails.js";
import EmballerList from "../../Pages/Emballer/EmballerList.js";
import EmballerShowWithoutRestriction from "../../Pages/Emballer/EmballerShowWithoutRestriction.js";


const Router = (props) => {
    

    return(
        <BrowserRouter>
            <Routes>
                <Route path="login" element={<LoginPage toggleSnack={props.toggleSnack}/>} />
                <Route path="/" element={<Layout permNeeded={['ROLE_USER', 'ROLE_LOGISTICIEN']}/>}>
                    <Route index element={<DashPage />} />
                    <Route path="preparation" element={<RedirectToPrep currentStep={"prepare"} />} />
                    <Route path="preparation/:id" element={<Preparation step={"prepare"}  toggleSnack={props.toggleSnack}/>} />
                    <Route path="controler" element={<RedirectToPrep currentStep={"control"} />} />
                    <Route path="controler/:id" element={<Preparation step={"control"}  toggleSnack={props.toggleSnack}/>} />
                    <Route path="controler-resp" element={<RedirectToPrep currentStep={"control-responsible"} />} />
                    <Route path="controler-resp/:id" element={<Preparation step={"control-responsible"}  toggleSnack={props.toggleSnack}/>} />


                    <Route path="emballer" element={<EmballerList/>} />
                    <Route path="emballer/:id" element={<EmballerShowWithoutRestriction/>} />
                    {/** <Route path="emballer" element={<RedirectToPrep currentStep={"pack"} />} />
                    <Route path="emballer/:id" element={<Preparation step={"pack"}  toggleSnack={props.toggleSnack}/>} /> **/}
                    
                    
                    <Route path="charger" element={<Navettes fromLogisticienMenu={true} toggleSnack={props.toggleSnack} permNeeded={['ROLE_USER']} />} />
                    <Route path="charger/:id" element={<Navettes fromLogisticienMenu={true} toggleSnack={props.toggleSnack} permNeeded={['ROLE_USER']} />} />
                    
                    {/** <Route path="charger" element={<RedirectToPrep currentStep={"charge"} />} />
                    <Route path="charger/:id" element={<Preparation step={"charge"}  toggleSnack={props.toggleSnack}/>} /> **/}

                    <Route path="preparations" element={<Commandes toggleSnack={props.toggleSnack} permNeeded={['ROLE_RESPONSABLE', 'ROLE_ADMIN']} />} />
                    <Route path="preparation/:id/view" element={<CommandeDetails toggleSnack={props.toggleSnack} permNeeded={['ROLE_RESPONSABLE', 'ROLE_ADMIN']} />} />
                    <Route path="palettes" element={<Palettes toggleSnack={props.toggleSnack} permNeeded={['ROLE_RESPONSABLE', 'ROLE_ADMIN']} />} />
                    <Route path="palette/:id/view" element={<PaletteDetail toggleSnack={props.toggleSnack} />} />
                    <Route path="navettes" element={<Navettes toggleSnack={props.toggleSnack} permNeeded={['ROLE_RESPONSABLE', 'ROLE_ADMIN']} />} />
                    <Route path="navettes/:id" element={<NavetteDetails toggleSnack={props.toggleSnack} permNeeded={['ROLE_USER']} />} />
                    <Route path="navettes/:id/details" element={<NavetteRespDetails toggleSnack={props.toggleSnack} permNeeded={['ROLE_RESPONSABLE', 'ROLE_ADMIN']} />} />
                    <Route path="catalogue" element={<Catalogue toggleSnack={props.toggleSnack} permNeeded={['ROLE_RESPONSABLE', 'ROLE_ADMIN']} />} />
                    <Route path="depots" element={<Depots toggleSnack={props.toggleSnack} permNeeded={['ROLE_RESPONSABLE', 'ROLE_ADMIN']} />} />
                    <Route path="souffrances" element={<Souffrances toggleSnack={props.toggleSnack} permNeeded={['ROLE_RESPONSABLE', 'ROLE_ADMIN']} />} />

                    <Route path="utilisateurs" element={<Utilisateur toggleSnack={props.toggleSnack} permNeeded={['ROLE_RESPONSABLE', 'ROLE_ADMIN']} />} />
                    <Route path="etats" element={<StateCommande toggleSnack={props.toggleSnack} permNeeded={['ROLE_RESPONSABLE', 'ROLE_ADMIN']} />} />
                    <Route path="etapes" element={<StepCommande toggleSnack={props.toggleSnack} permNeeded={['ROLE_RESPONSABLE', 'ROLE_ADMIN']} />} />
                    <Route path="emplacements" element={<Emplacements toggleSnack={props.toggleSnack} permNeeded={['ROLE_RESPONSABLE', 'ROLE_ADMIN']} />} />
                    <Route path="transporteurs" element={<Transporteurs toggleSnack={props.toggleSnack} permNeeded={['ROLE_RESPONSABLE', 'ROLE_ADMIN']} />} />
                    <Route path="canaux" element={<Canaux toggleSnack={props.toggleSnack} permNeeded={['ROLE_RESPONSABLE', 'ROLE_ADMIN']} />} />
                    <Route path="agences" element={<Agences toggleSnack={props.toggleSnack} permNeeded={['ROLE_RESPONSABLE', 'ROLE_ADMIN']} />} />
                    <Route path="marques" element={<Marques toggleSnack={props.toggleSnack} permNeeded={['ROLE_RESPONSABLE', 'ROLE_ADMIN']} />} />
                    <Route path="*" element={<NoPage />} />
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export default Router;