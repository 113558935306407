import { Button, CircularProgress, LinearProgress } from "@mui/material";
import { useEffect, useState } from "react";
import {Link, useParams} from "react-router-dom";
import { canAccess, getAssignedPrep, getCurrentOrderPrep, getIdWithEmail, getOnePrepProposal, getPrepProposal } from "../../Services/Auth/Authentification";
import { getOnePrep, setCurrentUserPrep, startPrep, stopPrep } from "../../Services/Commandes/Commandes";
import { getPreparationCurrentStep } from "../../Services/Preparation/Preparation";

import {Routes, Route, useNavigate} from 'react-router-dom';

import { styled } from '@mui/material/styles';
import PreparationDisplayer from "./PreparationDisplayer";
const Preparation = (props) =>{
    
    let { id } = useParams();
    let step = props.step;
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [idUser, setidUser] = useState(null);
    const [prepProposal, setPrepProposal] = useState(null);
    const [currentPrep, setCurrentPrep] = useState(null);
    const [canControl , setCanControl] = useState(false);



    useEffect(() => {
        getIdWithEmail().then((data)=>{
            setidUser(data['hydra:member'][0]['@id']);
            setCanControl(data['hydra:member'][0]['canControl']);
        })
    });

    function checkAccessToControl(){
        if(step === "control-responsible"){
            if(canControl){
                return true;
            }else if(canAccess(['ROLE_RESPONSABLE', 'ROLE_ADMIN'])){
                return true;
            }else{
                return false;
            }
        }else{
            return true;
        }
    }

    useEffect(() => {
        if(idUser != null){
            if (!checkAccessToControl()) {
                console.log("redirection en cours 1");
                navigate("/preparation");
            }
            setLoading(true);
            let cprep;
            let pprep;
            getCurrentOrderPrep(idUser).then(order_prep_id => {
                if(typeof order_prep_id === "undefined" || order_prep_id === null){

                    setCurrentPrep({"@id": "Aucune préparation"});
                    cprep = {"@id": "Aucune préparation"};
                    getPrepProposal(step).then((prep_proposal) => {
                        if(typeof prep_proposal === 'undefined'){
                            setPrepProposal({"@id": "Aucune préparation disponible"});
                            pprep = {"@id": "Aucune préparation disponible"};
                        }else{
                            setPrepProposal(prep_proposal);
                            pprep = prep_proposal;
                        }
                        setLoading(false);
                        if((typeof pprep["@id"] !== "undefined" && pprep["@id"].replace("/order_preparations/","") === id) || (typeof cprep["@id"] !== "undefined" && cprep["@id"].replace("/order_preparations/","") === id) || canAccess(['ROLE_RESPONSABLE', 'ROLE_ADMIN'])){
                            setCurrentUserPrep(idUser,"/order_preparations/"+id);
                            startPrep("/order_preparations/"+id);
                        }
                    })
                }else{

                    getOnePrep(order_prep_id.replace("/order_preparations/","")).then((prep)=>{
                        if(typeof prep['@id'] === 'undefined'){
                            setCurrentPrep({"@id": "Aucune préparation"});
                            cprep = {"@id": "Aucune préparation"};
                        }else{
                            setCurrentPrep(prep);
                            cprep = prep;
                        }
                        getPrepProposal(step).then((prep_proposal) => {
                            if(typeof prep_proposal === 'undefined'){
                                setPrepProposal({"@id": "Aucune préparation disponible"});
                                pprep = {"@id": "Aucune préparation disponible"};
                            }else{
                                setPrepProposal(prep_proposal);
                                pprep = prep_proposal;
                            }
                            setLoading(false);
                            if((typeof pprep["@id"] !== "undefined" && pprep["@id"].replace("/order_preparations/","") === id) || (typeof cprep["@id"] !== "undefined" && cprep["@id"].replace("/order_preparations/","") === id) || canAccess(['ROLE_RESPONSABLE', 'ROLE_ADMIN'])){
                                stopPrep(cprep["@id"]);
                                setCurrentUserPrep(idUser,"/order_preparations/"+id);
                                startPrep("/order_preparations/"+id);
                            }
                        })   
                    });
                }
                
               
            });
            
        }
    }, [id, idUser]);

    let className = "MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium css-sghohy-MuiButtonBase-root-MuiButton-root";

    function canAccessToPrep(prepProposal, currentPrep){
        if((typeof prepProposal["@id"] !== "undefined" && prepProposal["@id"].replace("/order_preparations/","") === id) || (typeof currentPrep["@id"] !== "undefined" && currentPrep["@id"].replace("/order_preparations/","") === id)  || checkAccessToControl()){
            console.log("can access");
            return true;
        }
        console.log("can't access");
        return false;
    }

    return <>
        
        
        
        

        {loading? <LinearProgress />:
        
            canAccessToPrep(prepProposal, currentPrep)?
                <>
                    <PreparationDisplayer step={step} toggleSnack={props.toggleSnack} canControl={canControl} id_prep={id}/>                
                </>
                :
                
                
                
                <p>Vous ne pouvez pas accèder à cette préparation <Button style={{display: "none"}} variant="contained"/><Link className={className} style={{ backgroundColor: "orange", color: "white", margin: "10px" }} to={'/preparation/'}>RETOUR AU PRÉPARATION</Link></p>
        






            
        
        }
        
    </>
}

export default Preparation;