import { Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useEffect, useState } from 'react';
import ButtonPalettePrint from '../../component/Palettes/ButtonPrint';
import { stepControlFinish, stepControlRespFinish, validateControlPaletteItem, validateControlRespPaletteItem } from '../../Services/Preparation/Preparation';
import ProductsListToControl from './ProductsListToControl';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { canAccess } from '../../Services/Auth/Authentification';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { stopPrep } from '../../Services/Commandes/Commandes';
import ButtonMultiplePrint from '../../component/Palettes/ButtonMultiplePrint';

const ControleSystem = (props) =>{
    let data_prep = props.data;
    let isStepResp = props.isStepResp;
    let palettes = props.palettes_prep;
    const navigate = useNavigate();
    const [selectedPalette, setSelectedPalette] = useState(palettes[0]);
    const [numberOfSelectedPalette, setNumberOfSelectedPalette] = useState(0);
    const [selectedPaletteIsControlled, setSelectedPaletteIsControlled] = useState(isStepResp?palettes[0].controlledResponsible:palettes[0].controlled);
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        if(isStepResp){
            stepControlRespFinish(data_prep['@id']).then((result)=>{
                setOpen(true);
                stopPrep(data_prep['@id']);
                props.ghostReload();
            });
        }else{
            stepControlFinish(data_prep['@id']).then((result)=>{
                setOpen(true);
                stopPrep(data_prep['@id']);
                props.ghostReload();
            });
        }
        
    };
  
    const handleClose = () => {
      setOpen(false);
    };
    const styles = {
        display: "flex",
        flexDirection: "column",
        fontSize: "18px", 
        fontWeight: "700", 
        WebkitBoxPack: "center", 
        justifyContent: "center", 
        alignItems: "center", 
        width: "100px",
        height: "100px", 
        minWidth: "100px", 
        minHeight: "100px", 
        marginTop: "8px",
        marginLeft: "auto",
        marginRight: "auto",
    }


    function validateThisPalette(){
        setSelectedPaletteIsControlled(true);
        if(isStepResp){
            validateControlRespPaletteItem(selectedPalette['@id'], "true").then((response)=>{
                props.ghostReload();
                let test = parseInt(numberOfSelectedPalette)+1
                if(palettes.length > test){
                    setNumberOfSelectedPalette(test)
                }
            })
        }else{
            validateControlPaletteItem(selectedPalette['@id'], "true").then((response)=>{
                props.ghostReload();
                let test = parseInt(numberOfSelectedPalette)+1
                if(palettes.length > test){
                    setNumberOfSelectedPalette(test)
                }
            })
        }
        
    }

    function unValidateThisPalette(){
        setSelectedPaletteIsControlled(false);
        
        if(isStepResp){
            validateControlRespPaletteItem(selectedPalette['@id'], "false").then((response)=>{
                props.ghostReload();
            })
        }else{
            validateControlPaletteItem(selectedPalette['@id'], "false").then((response)=>{
                props.ghostReload();
            })
        }
    }

    function selectPalette(e){
        let valueOfIndex = e.target.closest(".squareSwitch").getAttribute("indexofpalette");
        setNumberOfSelectedPalette(valueOfIndex);

    }

    function goToRespPage(){
        //navigate("/controler-resp/"+data_prep.id);
        window.location.href= "/controler-resp/"+data_prep.id;
        handleClose();
    }

    useEffect(() => {
        setSelectedPalette(palettes[numberOfSelectedPalette]);
        setNumberOfSelectedPalette(numberOfSelectedPalette);
        setSelectedPaletteIsControlled(isStepResp?palettes[numberOfSelectedPalette].controlledResponsible:palettes[numberOfSelectedPalette].controlled);
    
    }, [numberOfSelectedPalette]);
    return (<>
    
    <Grid container spacing={2}>
        <Grid item xs={1} md={2}>
            
        </Grid>
        <Grid item xs={10} md={8} style={{textAlign: "center"}}>
            <ButtonMultiplePrint palette={false} orderPreparation={data_prep}/>
            <span style={{fontWeight: "bold", fontSize: "18px", display: "flex", alignItems: "center", justifyContent: "center",color: "white", backgroundColor: "grey", margin: "0px", padding: "10px", borderRadius: "5px 5px 0px 0px", marginTop: 10}}>Contrôle {isStepResp?"Responsable": " "} de la palette n°{selectedPalette.number} <ButtonPalettePrint id_palette={selectedPalette['@id']} palette={false} orderPreparation={data_prep}/></span>
            <ProductsListToControl isStepResp={isStepResp} unValidateThisPalette={unValidateThisPalette} validateThisPalette={validateThisPalette} paletteIsControled={selectedPaletteIsControlled} selectedPalette={selectedPalette['@id']}/> 
        </Grid>
        <Grid item xs={1} md={2}>
            
        </Grid>
    </Grid>
    
    <hr/>
    <Grid container spacing={2}>
            <Grid item xs={12} md={10}>
                <b>Palettes</b><br/>
                <div style={{width: "100%", overflow: "auto", display: "flex", gap: "15px"}}>
                {
                    palettes.map((palette, index)=>{
                    return ((isStepResp?palette.controlledResponsible:palette.controlled) === true ? <><span onClick={selectPalette} className="squareSwitch" indexofpalette={index} style={{...styles, marginLeft: "initial", marginRight: "initial", color: "rgb(0, 151, 0)", border: "2px solid rgb(43, 175, 43)", backgroundColor: "rgb(204, 255, 204)", cursor:"pointer"}}><span>n°{palette.number}</span><span style={{fontSize: "12px"}}>{palette.typeName}</span></span><br/><br/></>:<><span className="squareSwitch" onClick={selectPalette} indexofpalette={index} style={{...styles, marginLeft: "initial", marginRight: "initial",color: "rgb(153, 0, 0)", border: "2px solid rgb(153, 0, 0)", backgroundColor: "rgb(255, 204, 204)", cursor:"pointer"}}><span>n°{palette.number}</span><span style={{fontSize: "12px"}}>{palette.typeName}</span></span><br/><br/></>)  
                    })
                }
                </div>
            </Grid>
            <Grid item xs={12} md={2} style={{textAlign: "center"}}>
                <Button onClick={handleClickOpen} disabled={data_prep?(isStepResp? data_prep.controlledResponsible.percent: data_prep.controlled.percent) === 100? false:true:true} variant="contained" color="success">{isStepResp?"VALIDER LE CONTRÔLE":"VALIDER L'AUTO-CONTRÔLE"}</Button>
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Contrôle Responsable</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {isStepResp?"Vous avez contrôler cette préparation.":"Cette préparation est passée sous le controle d'un responsable."}
                            {isStepResp? <>
                            
                                <div style={{textAlign: "center", display: "flex", gap: "15px", justifyContent: "center"}}><Link to="/controler-resp"><Button style={{marginBottom: "5px"}} variant="contained">Retourner aux préparations à contrôler</Button></Link><Link to={"/emballer/"+data_prep.id}><Button style={{marginBottom: "5px"}} variant="contained">Aller à l'emballage de cette préparation</Button></Link></div>
                            </>:
                            
                            <>
                            <div style={{textAlign: "center", display: "flex", gap: "15px", justifyContent: "center"}}><Link to="/preparation"><Button variant="contained">Retourner aux préparations</Button></Link><Link to="/emballer"><Button variant="contained">Aller aux emballages</Button></Link></div>
                            {canAccess(["ROLE_RESPONSABLE","ROLE_ADMIN"])? <div style={{textAlign: "center"}}><hr/><Button onClick={goToRespPage} color="success" style={{marginBottom: "5px"}} variant="contained">JE SUIS RESPONSABLE PROCÉDER AU CONTRÔLE</Button></div>: <></>}
                            </>
                            }
                        </DialogContentText>
                    
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleClose}>Fermer</Button>
                    </DialogActions>
                </Dialog>
            </Grid>
    </Grid>

    
    
    </>);

}
export default ControleSystem;
