import * as React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button } from "@mui/material";
import { editEmplacements } from '../../Services/Emplacements/Emplacements';
const EditEmplacements = (props) =>{
  let open = props.open;
  let handleReload = props.handleReload;
  let toggleSnack = props.toggleSnack;
  let handleClose = props.handleClose;

 
  let id = props.target;
  let code = props.code;
  let comment = props.comment;
  let pin = props.pin;
  let priority = props.priority;
  let quantity = props.quantity;

  let handleCode = props.handleCode;
  let handleComment = props.handleComment
  let handlePin = props.handlePin
  let handlePriority = props.handlePriority
  let handleQuantity = props.handleQuantity
  
  function onChangeCode(e){
    let target = e.target;
    handleCode(target.value);
  }
  function onChangeComment(e){
    let target = e.target;
    handleComment(target.value);
  }
  function onChangePin(e){
    let target = e.target;
    handlePin(target.value);
  }
  function onChangePriority(e){
    let target = e.target;
    handlePriority(target.value);
  }
  function onChangeQuantity(e){
    let target = e.target;
    handleQuantity(target.value);
  }
  function saveToApi(){
    editEmplacements(id, code, comment, pin, priority, quantity).then(emplacement => {
        handleReload();
        toggleSnack(true, "Sauvegardé avec succès", "success");
    });
    handleClose();
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Édition d'un canal</DialogTitle>
        <DialogContent style={{display: "flex", flexDirection: "column", gap: "10px"}}>
          <TextField id="filled-basic" onChange={onChangeCode} label="Code" value={code} variant="filled" />
          <TextField id="filled-basic" type="number" onChange={onChangePin} label="Code Pin" value={pin} variant="filled" />
          <TextField id="filled-basic" type="number" onChange={onChangePriority} label="Priorité" value={priority} variant="filled" />
          <TextField id="filled-basic" type="number" onChange={onChangeQuantity} label="Quantité" value={quantity} variant="filled" />
          Commentaire:<textarea id="filled-basic" onChange={onChangeComment} label="Commentaire" value={comment} variant="filled" />

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button onClick={saveToApi}>Appliquer</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default EditEmplacements;