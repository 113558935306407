

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { canAccess } from "../../Services/Auth/Authentification";
import { getOnePrep } from "../../Services/Commandes/Commandes";

const ChannelPresenter = (props) =>{
    const navigate = useNavigate();
    let channel_data = props.channel;

    let number = props.number;

    let id_prep = props.id_prep;
    
    const [customerDetails, setCustomerDetails] = useState(null);

    let displayCustomer = props.showCustomer;
    useEffect(() => {
        if(displayCustomer){
            if(props.customerData){
                let testIfCompany = props.customerData.company === ""? "" : "\n"+props.customerData.company;
                setCustomerDetails(props.customerData.firstname + " " + props.customerData.lastname + testIfCompany);
            }else{
                getOnePrep(id_prep).then((result)=>{
                    let testIfCompany = result.customer.company === ""? "" : "\n"+result.customer.company;
                    setCustomerDetails(result.customer.firstname + " " + result.customer.lastname + testIfCompany);
                });
            }
        }
    }, [displayCustomer])

    function clickWithPrep(e) {
        let id_prep_clicked = e.target.closest(".GoToCommande").getAttribute("id_prep");
        navigate("/preparation/"+id_prep_clicked+"/view");
    }

    if(id_prep !== "" && typeof id_prep !== "undefined"){
        if(number !== "" && typeof number !== "undefined" && canAccess(['ROLE_RESPONSABLE', 'ROLE_ADMIN'])){
            return(
                <span onClick={clickWithPrep} className="GoToCommande" id_prep={id_prep} style={{border: "1px solid "+channel_data.color, borderRadius: "12px 0px 0px 12px", cursor:"pointer", display: "inline-flex"}}>
                    <span key={channel_data.code} style={{backgroundColor: channel_data.color, fontWeight: "bold", padding: "10px 10px 10px 8px", borderRadius: "10px 0px 0px 10px", display:"flex", alignItems: "center", flexDirection: "column", paddingRight: "10px" }}>
                        <span style={{fontSize: "15px"}}>{channel_data.code}</span> <span style={{fontSize: "10px"}}><b>{number}</b></span>
                    </span>
                        <span style={{paddingLeft: "5px", paddingRight: "5px", display:"flex", flexDirection: "column", alignItems: "center", justifyContent: "space-around", whiteSpace: "nowrap", width: "100%", textAlign: "left"}}>
                            {displayCustomer? <span style={{display:"flex", flexDirection: "column", width: "100%"}}>{customerDetails?customerDetails.split('\n').map(str => <span>{str}</span>) : <></>}</span> : <></>}
                        </span>
                </span>
            )
        }else{
            return(
                <span className="GoToCommande" id_prep={id_prep} style={{border: "1px solid "+channel_data.color, borderRadius: "12px 0px 0px 12px", cursor:"pointer", display: "flex"}}>
                    <span key={channel_data.code} style={{backgroundColor: channel_data.color, fontWeight: "bold", padding: "10px 10px 10px 8px", borderRadius: "10px 0px 0px 10px", display:"flex", alignItems: "center", flexDirection: "column", paddingRight: "10px" }}>
                        <span style={{fontSize: "15px"}}>{channel_data.code}</span> <span style={{fontSize: "10px"}}><b>{number}</b></span>
                    </span>
                    <span style={{paddingLeft: "5px", paddingRight: "5px", display:"flex", flexDirection: "column", alignItems: "center", justifyContent: "space-around", whiteSpace: "nowrap", width: "100%", textAlign: "left"}}>
                        {displayCustomer? <span style={{display:"flex", flexDirection: "column", width: "100%"}}>{customerDetails?customerDetails.split('\n').map(str => <span>{str}</span>) : <></>}</span> : <></>}
                    </span>
                </span>
            )
        }
    }

    if(number !== "" && typeof number !== "undefined"){
        return(
            <span style={{border: "1px solid "+channel_data.color, paddingTop: "10px", paddingBottom: "10px", borderRadius: "12px 0px 0px 12px"}}>
                <span key={channel_data.code} style={{backgroundColor: channel_data.color, fontWeight: "bold", padding: "10px 8px", borderRadius: "10px 0px 0px 10px" }}>
                    {channel_data.code}
                </span>
                <span style={{paddingLeft: "5px", paddingRight: "5px"}}>{number} {customerDetails}</span>
            </span>
        )
    }

    return(
        <span key={channel_data.code} style={{backgroundColor: channel_data.color, fontWeight: "bold", padding: "10px 8px", borderRadius: "10px", margin: "2px" }}>
            {channel_data.code}
        </span>
    )
}

export default ChannelPresenter;