
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '@mui/material';
import { Fab, Tooltip } from "@mui/material";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {library} from "@fortawesome/fontawesome-svg-core";
import {
    faTrash
} from "@fortawesome/free-solid-svg-icons";
import { deleteOnePalette } from '../../Services/Palettes/Palettes';
library.add(faTrash);

class DeleteButtonWithConfirm extends Component {
  state = {
    showDialog: false
  };

  handleClick = () => {
    this.setState({ showDialog: true });
  };

  handleCloseClick = () => {
    this.setState({ showDialog: false });
  };




  handleDelete = (event) => {
    event.preventDefault();
    this.setState({ showDialog: false });
    deleteOnePalette(this.props.id_palette).then((data)=>{
      if(data instanceof SyntaxError){
          this.props.handleReload();
          for (var i = 0; i < this.props.listOfPalette.length; i++) {
            if (this.props.listOfPalette[i]['@id'] !== this.props.id_palette){
              this.props.setSelectedPalette(this.props.listOfPalette[i]['@id']);
              break;
            }
          }
          this.props.handleReloadPrepItemSelected();
      }else{
        if(data["hydra:description"].includes("SQLSTATE[23000]")) {
          this.props.toggleSnack(true, "La palette n'est pas vide impossible de la supprimé", "error");
          this.props.handleReload();
        } 
      }
    })  
  };

  render() {
    const { showDialog } = this.state;
    const { label = 'ra.action.delete', classes = {}, className } = this.props;
    return (
      <Fragment>
        <Tooltip title={"Supprimer cette palette"}>
            <Fab onClick={this.handleClick} lab style={{boxShadow:"unset", marginLeft: "8px"}} size={"small"} color="default" aria-label="Type">
                <FontAwesomeIcon icon="fa-trash"/>
            </Fab>
        </Tooltip>
        <Dialog fullWidth open={showDialog} onClose={this.handleCloseClick} aria-label="Etes vous sur?">
          <DialogTitle>Êtes-vous sûr de vouloir supprimer cette palette ? </DialogTitle>
          <DialogContent>
            <div>
              Vos actions seront enregistrées.
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDelete} label={label} style={{color: "red"}}  key="button">
              <DeleteIcon /> Supprimer
            </Button>
            <Button label="ra.action.cancel" style={{color: "grey"}} onClick={this.handleCloseClick}>
              <CancelIcon /> Annuler
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

DeleteButtonWithConfirm.propTypes = {
  id_palette: PropTypes.string,
  handleReload: PropTypes.func,
};

DeleteButtonWithConfirm.defaultProps = {
  id_palette: "0",
  handleReload: null,
};

export default DeleteButtonWithConfirm;
