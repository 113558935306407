import { canAccess } from "../../Services/Auth/Authentification";
import { Navigate } from "react-router-dom";

import StateList from "./StateList";

const StateCommande = (props) => {
    let permNeeded = props.permNeeded;
    

    if(!canAccess(permNeeded)){
        return(
            <Navigate to={"/"}/>
        )
    }else{
        

        
        return(<>
        
            <h1>Liste des États</h1>
            <hr></hr>
            <StateList/>
            
        </>)
    }
}
export default StateCommande;