import { getUsersFilteredByGeo } from "../../Services/Auth/Authentification";
import { useState, useEffect } from "react";
import { LinearProgress } from "@mui/material";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React from "react";
import {ImageList} from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import QRCode from "react-qr-code";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));
  
function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;
  
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
}
  
BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const UtilisateurListQR = () =>{
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [userqrselect, setUserQr] = React.useState("");
    const [userselect, setUserSelect] = React.useState("");

    const handleClickOpen = (e) => {
        let target = e.target;
        setUserQr(target.getAttribute("qrcode"));
        setUserSelect(target.getAttribute("user"));
        setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };

    useEffect(() => {
      
        setLoading(true);
        getUsersFilteredByGeo("Default").then(users => {
            if(users === "error"){
                    setLoading(false);
                    setError(true);
            }else{
                    setLoading(false);
                    setUsers(users['hydra:member']);
            }
        });
      
    }, []);

    if(loading) return <LinearProgress/>
    if(error) return "Erreur lors du chargement"

    return (
        <>
        <ImageList sx={{ width: "100%"}} cols={5} rowHeight={200}>
            {users.map((user) => (
                <Box>
                    <Card variant="outlined">

                        <CardContent>
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            {user.email}
                            </Typography>
                            <Typography variant="h5" component="div">
                            {user.firstName} {user.lastName}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button user={user.firstName+" "+user.lastName} qrcode={user.quickConnectionKey} onClick={handleClickOpen} size="small">Voir le QR CODE</Button>
                        </CardActions>

                    </Card>
                </Box>
            ))}
        </ImageList>
        <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
        >
            <BootstrapDialogTitle id="customized-dialog-title" style={{marginRight: "50px"}} onClose={handleClose}>
            {userselect}
            </BootstrapDialogTitle>
            <DialogContent style={{textAlign: "center"}} dividers>
                <QRCode value={userqrselect} />
            </DialogContent>
            <DialogActions>
            <Button autoFocus onClick={handleClose}>
                Fermer
            </Button>
            </DialogActions>
        </BootstrapDialog>
        </>
    );
}

export default UtilisateurListQR;