import { canAccess } from "../../Services/Auth/Authentification";
import { Navigate } from "react-router-dom";
import {useParams} from "react-router-dom";
import NavetteDetailsTablePaletteInside from "../../component/NavetteDetails/NavetteDetailsTablePaletteInside";
import NavetteDetailsTablePaletteWithoutNavette from "../../component/NavetteDetails/NavetteDetailsTablePaletteWithoutNavette";
import { useState } from "react";

const NavetteDetails = (props) =>{
    let permNeeded = props.permNeeded;
    const [reloadInside, setReloadInside] = useState(false);
    const [reloadWithoutPal, setReloadWithoutPal] = useState(false);

    function handleReloadInside(){
        setReloadInside(!reloadInside);
    }

    function handleReloadWithoutPal(){
        setReloadWithoutPal(!reloadWithoutPal);
    }

    let { id } = useParams();
    if(!canAccess(permNeeded)){
        return(
            <Navigate to={"/"}/>
        )
    }else{
        

        
        return(
            <>
            <NavetteDetailsTablePaletteInside handleReloadWithoutPal={handleReloadWithoutPal} reloadInside={reloadInside} id_navette={id}/>
            <hr></hr>
            {canAccess(['ROLE_RESPONSABLE', 'ROLE_ADMIN'])?  
            <NavetteDetailsTablePaletteWithoutNavette handleReloadInside={handleReloadInside} reloadWithoutPal={reloadWithoutPal} id_navette={id}/>
            :
            <></>
            }
        </>)
    }
}

export default NavetteDetails;