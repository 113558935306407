import { canAccess } from "../../Services/Auth/Authentification";
import { Navigate } from "react-router-dom";

import AgencesList from "./AgencesList";
const Agences = (props) =>{
    let permNeeded = props.permNeeded;
    let toggleSnack = props.toggleSnack;

    if(!canAccess(permNeeded)){
        return(
            <Navigate to={"/"}/>
        )
    }else{
        

        
        return(<>
        
            <h1>Liste des Agences</h1>
            <hr></hr>
            <AgencesList toggleSnack={toggleSnack}/>
        </>)
    }
}

export default Agences;