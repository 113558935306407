import { canAccess } from "../../Services/Auth/Authentification";
import { Navigate } from "react-router-dom";
import {useParams} from "react-router-dom";
import React, { useEffect, useState } from "react";
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { assignNavetteToPrep, getOnePrep, setPriorityToPrep } from "../../Services/Commandes/Commandes";
import BtnInfoPrep from "../../component/Button/BtnInfoPrep";
import { LinearProgress, Chip, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Paper, Slide, TextField } from "@mui/material";
import BtnMemoPrep from "../../component/Button/BtnMemoPrep";
import BtnCaptureImagePrep from "../../component/Button/BtnCaptureImagePrep";
import ChannelsPresenter from "../../component/Channels/ChannelsPresenter";
import { Downloadpdf } from "../../component/Button/btnDownloadPDFfromPresta";
import ButtonCustomerPopup from "../../component/Button/ButtonCustomerPopup";
import ButtonTrackingPopup from "../../component/Button/ButtonTrackingPopup";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import ButtonActionStep from "../../component/Button/ButtonActionStep";
import StepSpecificDisplayer from "../../component/Etapes/StepSpecificDisplayer";
import AssignmentIcon from '@mui/icons-material/Assignment';
import EventNoteIcon from '@mui/icons-material/EventNote';
import NavettesArrivedPresenter from "../../component/NavetteDetails/NavettesArrivedPresenter";
import CommandesDetailsProductsList from "./CommandeDetailsProductsList";
import CommandesDetailsPalettesList from "./CommandeDetailsPalettesList";
import BtnSeeImgPrep from "../../component/Button/BtnSeeImgPrep";
import { getListSpecificNavette } from "../../Services/Navettes/Navettes";
import { SelectTransporteur } from "../Navettes/CreateNavette";
import { createNavettes } from '../../Services/Navettes/Navettes';
import ButtonCommunicationAdv from "../../component/Button/ButtonCommunicationAdv";

const UpdatePriorityInList = (props) =>{
    let id_target = props.id_target;
    let priorityprops = props.priority;
    const [priority, setPriority] = useState(priorityprops);
  
    function UpdatePriority(e){
      let value = e.target.value;
      setPriority(value);
      setPriorityToPrep(id_target, value);
    }
  
    return (<input type="number" value={priority} onChange={UpdatePriority} style={{ width : "50px"}} />)
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
  
const AttribOrCreateNav = (props) => {
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState(0);

    const [navettesList, setNavettesList] = useState(null);
    const [loading, setLoading] = useState(true);
    const [navetteSelected, setNavetteSelected] = useState(null);

    let id_prep = props.id_prep;
    let handleReload = props.handleReload;
    let currentNavettes = props.currentNavettes;
    var today = new Date();
    var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    var time = today.getHours() + ":" + today.getMinutes();
    var dateTime = date+'T'+time;
    const [timeselect, setTimeselect] = React.useState(dateTime);
    const [transporteur, setTransporteur] = React.useState('');

    useEffect(() => {
        getListSpecificNavette("&charged=0").then((navettes)=>{
            setNavettesList(navettes['hydra:member']);
            setLoading(false);
        });
    }, []);

    const Styles = {
        select: {padding: "10px", width: "100%"},
        number: {padding: "10px", width: "100%", textAlign: "center"}
    }
    

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    function handleChangeNavetteSelect(e){
        let value = e.target.value;
        setNavetteSelected(value);
    }

    
    const handleChangeTransporteur = (event) => {
        setTransporteur(event.target.value);
    };

    const handleChangeTime = (event) => {
        setTimeselect(event.target.value);
    };
  
    function createNavetteToApi(){
        createNavettes(transporteur, timeselect).then(result=>{
            currentNavettes.push(result['@id']);
            assignNavetteToPrep(id_prep, JSON.stringify(currentNavettes), true).then(resultassign =>{
                handleReload();
                handleClose();
            });
        });
    }

    function assignNavette(){
        currentNavettes.push(navetteSelected);
        assignNavetteToPrep(id_prep, JSON.stringify(currentNavettes), true).then(resultassign =>{
            handleReload();
            handleClose();
        });
    }

    return (
      <div>
        <Button variant="outlined" onClick={handleClickOpen}>
          Navettes +
        </Button>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Attribuer" {...a11yProps(0)} />
                <Tab label="Créer" {...a11yProps(1)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0} style={{textAlign: "center"}}>
                    {loading? <><b>Chargement en cours...</b></> : 
                    
                    <>
                        <select value={navetteSelected} onChange={handleChangeNavetteSelect} style={Styles.select}>
                            <option selected disabled>-- Choisir une navette</option>
                            {navettesList.map((navette)=>{
                                return <option value={navette['@id']}>{"#"+navette['@id'].replace("/navettes/", "")} - {navette.carrier.name} - {new Date(Date.parse(navette.estimatedAt)).toLocaleDateString("fr") + " à " + new Date(Date.parse(navette.estimatedAt)).toLocaleTimeString("fr")}</option>;
                            })}
                        </select>
                        <Button onClick={assignNavette} variant="contained" style={{marginTop: "10px"}}>Attribuer à cette préparation</Button>
                    </>
                    }
            </TabPanel>
            <TabPanel value={value} index={1} style={{textAlign: "center"}}>
                <SelectTransporteur handleChange={handleChangeTransporteur} transporteur={transporteur}/>
                <TextField
                autoFocus
                id="datetime-local"
                label="Arrivée de la navette"
                margin="dense"
                type="datetime-local"
                fullWidth
                variant="standard"
                defaultValue={timeselect}
                onChange={handleChangeTime}
                InputLabelProps={{
                  shrink: true,
                }}
                />
                <Button onClick={createNavetteToApi} variant="contained" style={{marginTop: "10px"}}>Créer et attribuer à cette préparation</Button>
            </TabPanel>
          </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Fermer</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const CommandeDetails = (props) =>{
    let permNeeded = props.permNeeded;
    let { id } = useParams();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [value, setValue] = useState(0);

    const [reload, setReload] = useState(false);

    function handleReload(){
        setReload(!reload);
    }

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    useEffect(() => {
        setLoading(true);
        getOnePrep(id).then((data)=>{
            setData(data);
            setLoading(false);
        })
    
    }, [id, reload])

    function onMouseEnter(e){
        e.target.style.backgroundColor = 'rgba(0, 0, 0, 0.05)';
    }
    function onMouseLeave(e){
        e.target.style.backgroundColor = 'white';
    }
    function navigateToNavette(e){
        let target = e.target.parentNode.getAttribute("target");
        window.location.href = target;
        e.stopPropagation();
    }

    if(!canAccess(permNeeded)){
        return(
            <Navigate to={"/"}/>
        )
    }else{
        
        if(loading){return <LinearProgress/>}
        
        return(<>

            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2} style={{alignItems: "center"}}>
                    <Grid item xs={6} md={4}>
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={1} style={{alignItems: "center"}}>
                                <Grid item xs={12} md={6}>
                                    <h1><b>Préparation n°{id}</b></h1>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    {/*<BtnInfoPrep comment={data.comment}/>*/}
                                    {/*<BtnMemoPrep id={id} memo={data.memo}/>*/}
                                    <BtnCaptureImagePrep data={data}/>
                                    <BtnSeeImgPrep data={id}/>
                                    <ButtonCommunicationAdv id={id}/>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={6} md={1} style={{height: "48px", overflow: "hidden"}}>
                        <ChannelsPresenter channels={[data.channel['@id']]} number={data.orderId}/>
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <Box>
                            <Grid container style={{alignItems: "center"}}>
                                <Grid item xs={3} md={3}>
                                    <Downloadpdf sx={{m: 1}} type={"Invoice"} preparation={data} />
                                </Grid>
                                <Grid item xs={3} md={3}>
                                    <Downloadpdf sx={{m: 1}} type={"DeliverySlip"} preparation={data}/> 
                                </Grid>
                                <Grid item xs={3} md={3}>
                                    <ButtonCustomerPopup sx={{m: 1}} preparation={data}/>  
                                </Grid>
                                <Grid item xs={3} md={3}>
                                    <ButtonTrackingPopup sx={{m: 1}} preparation={data} />
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Box>
                            <Grid container style={{alignItems: "center"}}>
                                <Grid item xs={12} md={6}>
                                    <Chip avatar={<LocalShippingIcon />} label={data.carrier.name}/>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <CreditCardIcon/> {data.payment? <CheckIcon style={{color: "green"}} />:<CloseIcon style={{color: "red"}}/>}
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    Priorité : <b><UpdatePriorityInList id_target={id} priority={data.priority}/></b>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <div style={{marginBottom: "10px"}}><ButtonActionStep target={"/order_preparations/"+id} step={data.step}/></div>
                    </Grid>
                </Grid>
            </Box>
            
            <hr></hr>

            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2} style={{alignItems: "stretch"}}>
                    <Grid item xs={6} md={1} style={{textAlign: "center",justifyContent: "center", display: "flex", flexDirection: "column"}}>
                            <span style={{display: "flex", alignItems: "center", gap: "2px"}}><EventNoteIcon/>Planification</span>
                            <StepSpecificDisplayer data={data} stepSelected={"plan"}/>
                    </Grid>
                    <Grid item xs={6} md={1} style={{textAlign: "center",justifyContent: "center", display: "flex", flexDirection: "column"}}>
                            <span style={{display: "flex", alignItems: "center", gap: "2px"}}><AssignmentIcon/>Préparation</span>
                            <StepSpecificDisplayer data={data} stepSelected={"prepare"}/>
                    </Grid>
                    <Grid item xs={6} md={1} style={{textAlign: "center",justifyContent: "center", display: "flex", flexDirection: "column"}}>
                            <span style={{display: "flex", alignItems: "center", gap: "2px"}}><AssignmentIcon/>Controle</span>
                            <StepSpecificDisplayer data={data} stepSelected={"control"}/>
                    </Grid>
                    <Grid item xs={6} md={1} style={{textAlign: "center", justifyContent: "center", display: "flex", flexDirection: "column"}}>
                            <span style={{display: "flex", alignItems: "center", gap: "2px"}}><AssignmentIcon/>Emballage</span>
                            <StepSpecificDisplayer data={data} stepSelected={"pack"}/>
                    </Grid>
                    <Grid item xs={12} md={1} style={{textAlign: "center", justifyContent: "center", display: "flex", flexDirection: "column"}}>
                            <span style={{display: "flex", alignItems: "center", gap: "2px"}}><LocalShippingIcon/>Chargement</span>
                            <StepSpecificDisplayer data={data} stepSelected={"charge"}/>
                    </Grid>
                    <Grid item xs={12} md={4} style={{borderLeft: "1px black solid", borderRight: "1px solid black", alignItems: "center", display: "grid"}}>      
                            <Box>
                                <Grid container style={{textAlign: "center"}}>
                                    <Grid item xs={12} md={4}>
                                        <span style={{fontWeight: "bold"}}>PRODUITS</span><br></br>
                                        {data.totalProducts}
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <span style={{fontWeight: "bold"}}>QUANTITÉS</span><br></br>
                                        {data.totalProductsQuantity}
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <span style={{fontWeight: "bold"}}>PALETTES</span><br></br>  
                                        {data.totalPalettes}
                                    </Grid>
                                </Grid>
                            </Box>
                    </Grid>
                    <Grid item xs={12} md={1} style={{ display: "flex",alignItems: "center", justifyContent: "center"}}>
                        <span style={{backgroundColor: data.spot.color, fontWeight: "bold", padding:"5px", borderRadius: "5px"}}>{data.spot.spot}</span>
                    </Grid>
                    <Grid item xs={12} md={2} style={{ display: "flex",alignItems: "center", justifyContent: "center", flexDirection: "column", borderLeft: "1px solid black", gap: "5px"}}>
                        <AttribOrCreateNav id_prep={id} handleReload={handleReload} currentNavettes={data.navettes}/>
                        {data.navettes.map((navette)=>{
                          return <Chip onClick={navigateToNavette} target={navette} label={<NavettesArrivedPresenter tableDisplay={true} navettes={[navette]} />} />
                        })}
                    </Grid>
                </Grid>
            </Box>
            <hr></hr>
            {/**<Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={5}>
                    </Grid>
                    <Grid item xs={12} md={2}>
                    <table style={{borderCollapse: "collapse"}}>
                        <th>Navettes</th>
                    <tbody>
                        {data.navettes.map((navette)=>{
                          return <tr onClick={navigateToNavette} target={navette} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} style={{borderTop: "1px solid grey", borderBottom: "1px solid grey", cursor: "pointer"}}><td><NavettesArrivedPresenter tableDisplay={true} navettes={[navette]} /></td></tr>
                        })}
                    </tbody>
                    </table>
                    </Grid>
                    <Grid item xs={12} md={5}>
                    </Grid>
                </Grid>
            </Box>**/}

            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="tabs commandes"  variant="fullWidth">
                    <Tab label="Produits" {...a11yProps(0)} />
                    <Tab label="Palettes" {...a11yProps(1)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <CommandesDetailsProductsList order_prep_id={'/order_preparations/'+id}/>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <CommandesDetailsPalettesList orderPreparation={data} order_prep_id={'/order_preparations/'+id}/>
                </TabPanel>
            </Box>
        </>)
    }
}

export default CommandeDetails;