import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import FilterListIcon from '@mui/icons-material/FilterList';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { useState } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { CircularProgress, LinearProgress, Tooltip } from '@mui/material';
import { getTransporteursList } from '../../Services/Transporteurs/Transporteurs';
import { useEffect } from 'react';
import {Checkbox} from '@mui/material';
import {FormGroup} from '@mui/material';
import {deleteNavetteListSelected, getNavettesList} from '../../Services/Navettes/Navettes';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { visuallyHidden } from '@mui/utils';
import {Button, TextField} from "@mui/material";
import TransporteurPresenter from '../../component/Transporteurs/TransporteurPresenter';
import ChannelsPresenter from '../../component/Channels/ChannelsPresenter';
import AgencesListPresenter from '../../component/Agences/AgencesListPresenter';
import CreateNavette from './CreateNavette';
import { Navigate } from 'react-router-dom';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  width: "100%",
  border: "1px solid rgba(0,0,0,0.53)",
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));



const TitleFilter = (props) => {
    
    let children = props.children;

    return (
        <>
        <span style={{display: "flex", alignItems: "center", textAlign: "center", justifyContent: "center", borderBottom: "1px solid grey",...props.style}}>
            {children}
        </span>
        </>
    );
};

const FilterItem = (props) => {
    let children = props.children;
    return(
        <>
        <span style={{width: "100%", textAlign: "center"}}>
            {children}
        </span>
        </>
    );
};


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
}
  
function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
}
  
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}



const headCells = [
    {
      id: '@id',
      numeric: false,
      disablePadding: true,
      textAlign: "left",
      label: 'ID',
    },
    {
      id: 'Label',
      numeric: false,
      disablePadding: false,
      textAlign: "center",
      label: 'Label',
    },
    {
      id: 'carrier',
      numeric: false,
      disablePadding: false,
      textAlign: "center",
      label: 'Transporteur',
    },
    {
      id: 'palettes',
      numeric: false,
      disablePadding: false,
      textAlign: "center",
      label: 'Palettes',
    },
    {
      id: 'createdAt',
      numeric: false,
      disablePadding: false,
      textAlign: "center",
      label: 'Date de création',
    },
    {
      id: 'estimatedAt',
      numeric: false,
      disablePadding: false,
      textAlign: "center",
      label: 'Arrivée le',
    },
    {
      id: 'charged',
      numeric: false,
      disablePadding: false,
      textAlign: "center",
      label: 'Chargé',
    },
    {
      id: 'channels',
      numeric: false,
      disablePadding: false,
      textAlign: "center",
      label: 'Channels',
    },
    {
      id: 'agences',
      numeric: false,
      disablePadding: false,
      textAlign: "center",
      label: 'Agences',
    },
    {
      id: 'actions',
      numeric: false,
      disablePadding: false,
      textAlign: "center",
      label: 'Actions',
    },
];
  
function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
      props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
  
    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              style={{textAlign: headCell.textAlign}}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
}
  
EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};
  
function EnhancedTableToolbar(props) {
    const { numSelected } = props;
    let deleteUserSelected = props.deleteUserSelected;
    let setInputLabelSearch = props.setInputLabelSearch;
    let inputLabelSearch = props.inputLabelSearch;

    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: '1 1 100%' }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} séléctionné(s)
          </Typography>
        ) : (
          <span></span>
        )}
  
        {numSelected > 0 ? (
          <Tooltip title="Supprimer">
            <IconButton onClick={deleteUserSelected}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <>
            <CreateNavette handleReload={props.handleReload}/>
            <TextField id="filled-basic" label="Label" variant="filled" value={inputLabelSearch} onChange={e => setInputLabelSearch(e.target.value)} style={{marginLeft: "10px"}} />
          </>
        )}
      </Toolbar>
    );
}
  
EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};
  

const FiltreisCharged = ['Non Chargé', "Charger"];

const NavettesList = (props) =>{
    const [rows, setRows] = useState([]);
    const [error, setError] = useState(false);
    const [reload, setReload] = useState(false);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('nom');
    const [selected, setSelected] = useState([]);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [inputLabelSearch, setInputLabelSearch] = useState("");

    let toggleSnack = props.toggleSnack;

    function handleReload(){
      setReload(!reload);
    }

    const [loadingCarriers, setLoadingCarriers] = useState(false);

    const [loading, setLoading] = useState(false);

    const [valueisCharged, setValueisCharged] = useState('');

    const [carriers, setCarriers] = useState([]);

    const [navettes, setNavettes] = useState([]);

    const [selectedCarrieresFilter, setSelectedCarrieresFilter] = useState({
        selected: []
    });

    const tableRef = React.useRef(null);

    const handleChangeSelectedCarrieres = (e) => {
        const { value, checked } = e.target;
        const { selected } = selectedCarrieresFilter;

         
        if (checked) {
            setSelectedCarrieresFilter({
                selected: [...selected, value]
            });
        }
      
        else {
            setSelectedCarrieresFilter({
                selected: selected.filter((e) => e !== value),
            });
        }
    };


    function handleChangeisCharged(event){
        setValueisCharged(event.target.value);
    };

    function resetRadioisCharged(){
        setValueisCharged('');
    }

    useEffect(() => {
      
        setLoadingCarriers(true);
        getTransporteursList().then(carriers => {
                    setLoadingCarriers(false);
                    setCarriers(carriers['hydra:member']);
        });
      
    }, []);



    /**
     Listing
     */

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
    if (event.target.checked) {
        const newSelected = rows.map((n) => n['@id']);
        setSelected(newSelected);
        return;
    }
    setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };
    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const isSelected = (name) => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    //const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    useEffect(() => {
      
        setLoading(true);
        
        getNavettesList(page, rowsPerPage, props.alreadysend, selectedCarrieresFilter, inputLabelSearch).then(navettes => {
          if(navettes === "error"){
                  setLoading(false);
                  setError(true);
          }else{
                  setLoading(false);
                  setRows(navettes['hydra:member']);
                  setTotal(navettes['hydra:totalItems']);
          }
        });
      
    }, [reload, page, rowsPerPage, props.alreadysend, valueisCharged, selectedCarrieresFilter, inputLabelSearch]);

    function LabelDisplayedRows({ from, to, count }) { return `${from}–${to} de ${count !== -1 ? count : `Un peu plus de ${to}`}`; }
    
    
    function deleteProductSelected(){
      deleteNavetteListSelected(selected).then(results=>{
        let canDelete = true;
        console.log(results);
        results.forEach((result) => {
            if(result['hydra:description'].includes("Integrity constraint violation")){
                canDelete = false;
            }
        });
        if(canDelete){
          toggleSnack(true, "Navette(s) supprimé avec succès", "success");
        }else{
          
          toggleSnack(true, "Une ou plusieurs navettes ne sont pas vide et donc ne peuvent être supprimés", "error");
        }
        
        handleReload();
      });
      //
      //toggleSnack(true, "Désactivé pour le moment, constraint foreign key", "error");
    }


    function redirectToNavette(e){
      window.location.href = e.target.getAttribute("target");
      e.stopPropagation()
    }

     //-------

    return(<>
    
    <Grid container spacing={2} style={{display: 'flex', alignItems: 'stretch'}}>
        <Grid item xs={12} md={2}>
            <Item style={{height: "100%", maxHeight: "100%"}}>
                <TitleFilter style={{marginTop: "10px"}}><LocalShippingIcon/><span>TRANSPORTEURS</span></TitleFilter>
                <FormGroup style={{width: "100%", textAlign: "center", marginLeft: "auto", marginRight: "auto", overflowY: "auto", display: "list-item", flex:1, maxHeight: tableRef.current ? tableRef.current.clientHeight : "100%"}}>
                {loadingCarriers ? <CircularProgress style={{marginTop: "15px"}}/> :
                    carriers.map((carrieres, index)=>{
                        return <FormControlLabel key={index} style={{borderBottom: "1px dashed grey", width:"100%", textOverflow: "ellipsis", whiteSpace: "nowrap"}} control={<Checkbox value={carrieres.name} onChange={handleChangeSelectedCarrieres}/>} label={<span style={{fontSize: "14px"}}>{carrieres.name}</span>} />
                    })
                }
                </FormGroup>
            </Item>
        </Grid>
        <Grid item xs={12} md={10}>
            <Item>
                <TitleFilter><FilterListIcon/><span>Tableau</span></TitleFilter>
               

                    <Box sx={{ width: '100%' }}>
                    <Paper sx={{ width: '100%', mb: 2 }}
                        ref={tableRef}>
                    <EnhancedTableToolbar deleteUserSelected={deleteProductSelected}
                        handleReload={handleReload} numSelected={selected.length} inputLabelSearch={inputLabelSearch} setInputLabelSearch={setInputLabelSearch}/>
                    <TableContainer>
                    
                    {loading ? <LinearProgress/>:""}
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                    >
                        <EnhancedTableHead
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={total}
                        />
                        
                        <TableBody>
                        {stableSort(rows, getComparator(order, orderBy))
                            .map((row, index) => {
                            const isItemSelected = isSelected(row['@id']);
                            const labelId = `enhanced-table-checkbox-${index}`;
                            return (
                                <TableRow
                                hover
                                onClick={(event) => handleClick(event, row['@id'])}
                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={row['@id']}
                                selected={isItemSelected}
                                >
                                <TableCell padding="checkbox">
                                    <Checkbox
                                    color="primary"
                                    checked={isItemSelected}
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                    />
                                </TableCell>
                                <TableCell
                                    component="th"
                                    id={labelId}
                                    scope="row"
                                    padding="none"
                                >
                                    {row['@id'].replace("/navettes/", "")}
                                </TableCell>
                                <TableCell align="left">{row.Label? row.Label : ""}</TableCell>
                                <TableCell align="center"><TransporteurPresenter transporteur={row.carrier}/></TableCell>
                                <TableCell align="center">{row.palettes.length}</TableCell>
                                <TableCell align="center">{new Date(Date.parse(row.createdAt)).toLocaleDateString("fr") + " à " + new Date(Date.parse(row.createdAt)).toLocaleTimeString("fr")}</TableCell>
                                <TableCell align="center">{new Date(Date.parse(row.estimatedAt)).toLocaleDateString("fr") + " à " + new Date(Date.parse(row.estimatedAt)).toLocaleTimeString("fr")}</TableCell>
                                <TableCell align="center">{row.charged? <CheckIcon style={{color: "green"}} />:<CloseIcon style={{color: "red"}}/>}</TableCell>
                                <TableCell align="center"><ChannelsPresenter channels={row.channels}/></TableCell>
                                <TableCell align="center"><AgencesListPresenter agences={row.agences}/></TableCell>
                                <TableCell align="right"><Button target={row['@id']} variant="contained" onClick={redirectToNavette} endIcon={<LocalShippingIcon />}>Charger</Button></TableCell>
                                </TableRow>
                            );
                            })}
                        
                        </TableBody>
                    </Table>
                    </TableContainer>
                    <TablePagination
                    rowsPerPageOptions={[10, 20, 30]}
                    labelRowsPerPage={"Nombre par page"}
                    labelDisplayedRows={LabelDisplayedRows}
                    component="div"
                    count={total}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
                </Box>

                   
            </Item>
            
        </Grid>
    </Grid>
    
    </>)
}

export default NavettesList;