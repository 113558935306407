import { Button } from "@mui/material";

function isElectron() {
    // Renderer process
    if (typeof window !== 'undefined' && typeof window.process === 'object' && window.process.type === 'renderer') {
        return true;
    }

    // Main process
    if (typeof process !== 'undefined' && typeof process.versions === 'object' && !!process.versions.electron) {
        return true;
    }

    // Detect the user agent when the `nodeIntegration` option is set to true
    if (typeof navigator === 'object' && typeof navigator.userAgent === 'string' && navigator.userAgent.indexOf('Electron') >= 0) {
        return true;
    }

    return false;
}
const AppBar = () =>{

    function CloseApp(){
        window.close();
    }

    function MaximizeApp(){
        window.moveTo(0, 0);
        window.resizeTo(window.screen.width, window.screen.height);
    }

    if(isElectron()){
       
        return(
            <div id="appBar" style={{display: "flex", width:"100%", justifyContent:"right", textAlign:"right", flexDirection:"row", gap:"5px", "-webkit-app-region": "drag"}}>
                <Button onClick={MaximizeApp} id="appMaximise" style={{color: "black", backgroundColor: "white", "-webkit-app-region": "no-drag"}}>🗖</Button>
                <Button onClick={CloseApp} id="appClose" style={{color: "black", backgroundColor: "white", "-webkit-app-region": "no-drag"}}>X</Button>
                <style>{"#spaceappBar header{margin-top:37px!important;}"}</style>
            </div>
        )
    }else{
        return(
            <></>
        )
    }
};

export default AppBar;