import { Button, LinearProgress } from "@mui/material";
import { useEffect, useState } from "react";
import DisplayAttribAndCustomFromPaletteItem from "../../component/Product/DisplayAttribAndCustomFromPaletteItem";
import ValidateProduct from "../../component/Product/ValidateProduct";
import CompassToSpot from "../../component/ProposalContent/CompassToSpot";
import { getOnePalette } from "../../Services/Palettes/Palettes";

const ProductsListToControl = (props) =>{
    let isStepResp = props.isStepResp;
    let selectedPalette = props.selectedPalette;
    let paletteIsControled = props.paletteIsControled;
    let validateThisPalette = props.validateThisPalette;
    let unValidateThisPalette = props.unValidateThisPalette;
    const [paletteData, setPaletteData] = useState([]);
    const [numberOfValidate, setNumberOfValidate] = useState(0);
    const [numberOfNeedValidate, setNumberOfNeedValidate] = useState(9999);
    const [loading, setLoading] = useState(true);


    const styles = {
        display: "flex",
        flexDirection: "column",
        fontSize: "18px", 
        fontWeight: "700", 
        WebkitBoxPack: "center", 
        justifyContent: "center", 
        alignItems: "center", 
        width: "100px",
        height: "100px", 
        minWidth: "100px", 
        minHeight: "100px", 
        marginTop: "8px",
        marginLeft: "auto",
        marginRight: "auto",
    }
    useEffect(() => {
        setLoading(true);
        getOnePalette(selectedPalette).then((data) => {
            setPaletteData(data);
            setNumberOfValidate(0);
            setNumberOfNeedValidate(data.items.length);
            let numberValid = 0;
            data.items.forEach((item) => {
                if(isStepResp){
                    if(item.controlledResponsible){
                        numberValid++;
                    }
                }else{
                    if(item.controlled){
                        numberValid++;
                    }
                }
                
            })
            setNumberOfValidate(numberValid);
            setLoading(false);
        });
    }, [selectedPalette]);

    return (
        <>

            {!loading?
            paletteData.items.map((item, index) => {
                return <div style={{width: "100%", border: "1px solid black", display:"flex", padding:"10px", alignItems: "center", borderTop: "0px"}} key={index}>
                        <div style={{display:"flex", flexDirection: "column", width: "60%"}}>{item.product.name} <div><DisplayAttribAndCustomFromPaletteItem id_palette_item={item['@id']}/></div></div>
                        <div style={{...styles, border: "2px solid rgb(9, 88, 167)", backgroundColor: "rgb(25, 118, 210)", color:"white"}}><span>{item.quantity}</span> <span>{item.quantityToString.toUpperCase()}</span></div> 
                        {
                            !paletteIsControled?<ValidateProduct isStepResp={isStepResp} numberOfValidate={numberOfValidate} setNumberOfValidate={setNumberOfValidate} defaultValue={isStepResp?item.controlledResponsible:item.controlled} id_palette_item={item['@id']}/>:<></>
                        }
                        </div>
            })
            : <><LinearProgress/></>
            }
            {
            paletteIsControled? <Button style={{marginTop: "5px", marginBottom: "5px"}} onClick={unValidateThisPalette} variant="contained" color="error">Invalider cette Palette</Button> :
            numberOfValidate === numberOfNeedValidate? 
                <Button style={{marginTop: "5px", marginBottom: "5px"}} onClick={validateThisPalette} variant="contained" color="success">Valider cette Palette et passer à la suivante</Button>:
                <Button style={{marginTop: "5px", marginBottom: "5px"}} variant="contained" color="success" disabled={true}>Valider cette Palette et passer à la suivante</Button>
        
            }
        </> 
    )
}
export default ProductsListToControl;
