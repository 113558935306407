import { Button, Chip, Divider, LinearProgress, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { getOnePrep, pausePrep, stopPrep } from "../../Services/Commandes/Commandes";
import Grid from '@mui/material/Grid';
import { Link } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BtnInfoPrep from "../../component/Button/BtnInfoPrep";
import BtnMemoPrep from "../../component/Button/BtnMemoPrep";
import BtnCaptureImagePrep from "../../component/Button/BtnCaptureImagePrep";
import ChannelsPresenter from "../../component/Channels/ChannelsPresenter";
import { FaStopCircle, FaPlayCircle, FaPauseCircle, FaTrash, FaExclamationCircle } from 'react-icons/fa';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import StepsDisplayer from "../../component/Etapes/StepsDisplayer";
import PreparationsPriorityList from "../../component/Commandes/PreparationsPriorityList";
import WarningIcon from '@mui/icons-material/Warning';
import AddPalette from "../../component/Palettes/AddPalette";
import PreparationSystem from "./PreparationSystem";
import ControleSystem from "../Controler/ControleSystem";
import { canAccess } from "../../Services/Auth/Authentification";
import { useNavigate } from 'react-router-dom';
import EmballerSystem from "../Emballer/EmballerSystem";
import ChargerSystem from "../Charger/ChargerSystem";
import ButtonCommunicationAdv from "../../component/Button/ButtonCommunicationAdv";

const PreparationDisplayer = (props) =>{
    let id_prep = props.id_prep;
    let step = props.step;
    let canControl = props.canControl;
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [reload, setReload] = useState(false);

    function handleReload(){
        setReload(!reload);
    }

    useEffect(() => {
        setLoading(true);
        getOnePrep(id_prep).then((data) => {
            setData(data);
            setLoading(false);
        });
    
    }, [id_prep, reload]);

    function ghostReload(){
        getOnePrep(id_prep).then((data) => {
            setData(data);
            setLoading(false);
        });
    }

    if (loading) {
        return <><h1>Préparation n°{id_prep}</h1><hr></hr><LinearProgress/></>
    }
    let icons;
    if(data.state.icon==="FaStopCircle"){
        icons = <div style={{display: "inline-flex", alignItems: "center", gap: "5px", justifyContent: "center", verticalAlign: "middle"}}><FaStopCircle  size={24} color={data.state.color}/> Stoppé</div>
    }else if(data.state.icon==="FaPlayCircle"){
        icons = <span style={{display: "inline-flex", alignItems: "center", gap: "5px", justifyContent: "center", verticalAlign: "middle"}}><FaPlayCircle size={24} color={data.state.color}/><span>En cours</span></span>
    }else if(data.state.icon==="FaPauseCircle"){
        icons = <div style={{display: "inline-flex", alignItems: "center", gap: "5px", justifyContent: "center", verticalAlign: "middle"}}><FaPauseCircle size={24} color={data.state.color}/> En Pause</div>
    }else if(data.state.icon==="FaTrash"){
        icons = <div style={{display: "inline-flex", alignItems: "center", gap: "5px", justifyContent: "center", verticalAlign: "middle"}}><FaTrash size={24} color={data.state.color}/> Annulé</div>
    }else if(data.state.icon === "FaExclamationCircle"){
        icons = <div style={{display: "inline-flex", alignItems: "center", gap: "5px", justifyContent: "center", verticalAlign: "middle"}}><FaExclamationCircle size={24} color={data.state.color}/> Erreur</div>
    }

    function pausePrephandle(){
        pausePrep("/order_preparations/"+id_prep);
    }

    function stopPrephandle(){
        stopPrep("/order_preparations/"+id_prep);
    }

    function renderSwitch() {
        switch(step) {
          case 'prepare':
            return (
                <>
                {data.palettes.length > 0? 

                <PreparationSystem ghostReload={ghostReload} palettes_prep={data.palettes} toggleSnack={props.toggleSnack}  data={data}/>

                : 
                <>
                <div style={{backgroundColor: "#c0392b", width:"100%", color:"white", display:"flex", justifyContent: "space-between", paddingRight: "10px"}}>
                <span style={{fontWeight: "bold", textAlign: "center", padding:"20px", fontSize: "24px", display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <WarningIcon/>Créer une palette <span style={{fontSize: "16px"}}>(Aucune palette pour cette préparation n'est attribué)</span>
                </span> 
                <AddPalette prepID={"/order_preparations/"+id_prep} handleReload={handleReload}/>
                </div>
                <PreparationsPriorityList type="preview" id_prep={id_prep}/>
                </>
                }
                </>
            )
          case 'control':
                return(<ControleSystem isStepResp={false} ghostReload={ghostReload} data={data} palettes_prep={data.palettes} toggleSnack={props.toggleSnack}/>)
          case 'control-responsible':
                if(canControl){
                    return(<ControleSystem isStepResp={true} ghostReload={ghostReload} data={data} palettes_prep={data.palettes} toggleSnack={props.toggleSnack}/>)
                }else if(canAccess(["ROLE_RESPONSABLE", "ROLE_ADMIN"])){
                    return(<ControleSystem isStepResp={true} ghostReload={ghostReload} data={data} palettes_prep={data.palettes} toggleSnack={props.toggleSnack}/>)
                }else{
                    return navigate("/preparation");
                }
          case 'pack':
                return <EmballerSystem ghostReload={ghostReload} data={data} id_prep={id_prep}/>

          case 'charge':
                return <ChargerSystem ghostReload={ghostReload} data={data} id_prep={id_prep}/>
          default:
            return 'Oula d\'oû tu viens? Un petit souci c\'est passé en venant ici';
        }
    }

    return (
    <>
    <Grid container spacing={1} style={{alignItems: "center"}}>
        <Grid item xs={12} md={4} style={{paddingBottom: "5px"}}>
            <Tooltip title="Retour" style={{marginRight: "10px"}}><Link to="/preparation"><ArrowBackIcon/></Link></Tooltip>
            <span style={{fontWeight: "bold", fontSize: "20px"}}>Préparation n°{id_prep}</span>
            <span style={{marginLeft: "5px", marginRight: "5px"}}><ChannelsPresenter channels={[data.channel['@id']]} number={data.orderId}/></span>
            <Tooltip title={data.carrier.name}><Chip avatar={<LocalShippingIcon />} label={data.carrier.name}/></Tooltip>
        </Grid>
        <Grid item xs={12} md={4} style={{justifyContent: "center", textAlign: "center"}}>
            {/*<BtnInfoPrep comment={data.comment}/>*/}
            {/*<BtnMemoPrep id={id_prep} memo={data.memo}/>*/}
            <ButtonCommunicationAdv id={id_prep}/>
            <BtnCaptureImagePrep data={data}/>
            <PreparationsPriorityList handleReload={handleReload} id_prep={"/order_preparations/"+id_prep}/>
            <Tooltip style={{marginLeft: "20px"}} title="Mettre en pause"><Link to="/preparation" onClick={pausePrephandle}><Button style={{color: "white", margin: "5px", padding: "10px", minWidth: "unset", backgroundColor: "#1976d2"}}><FaPauseCircle size={24} /></Button></Link></Tooltip>
            <Tooltip title="Arrêter la préparation"><Link to="/preparation" onClick={stopPrephandle}><Button style={{color: "white", margin: "5px", padding: "10px", minWidth: "unset", backgroundColor: "#1976d2"}}><FaStopCircle size={24} /></Button></Link></Tooltip>
            <Tooltip style={{width: "100px", display: "none"}} title="Status en cours">{icons}</Tooltip>
        </Grid>
        <Grid item xs={12} md={2} style={{textAlign: "right"}}>
            <span style={{display: "inline-flex"}}>
            <table style={{marginLeft: "auto", marginRight: "auto", borderCollapse: "collapse", textAlign: "center"}}>
                <thead>
                    <tr>
                        <th style={{borderRight: "1px solid grey"}}>PDT&nbsp;</th>
                        <th style={{borderRight: "1px solid grey"}}>QTE&nbsp;</th>
                        <th>PAL&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style={{borderRight: "1px solid grey"}}>{data.totalProducts}&nbsp;</td>
                        <td style={{borderRight: "1px solid grey"}}>{data.totalProductsQuantity}&nbsp;</td>
                        <td>{data.totalPalettes}</td>
                    </tr>
                </tbody>
            </table>
            </span>
        </Grid>
        <Grid item xs={12} md={2} style={{textAlign: "right"}}>
            <span style={{marginRight: "25px"}}><StepsDisplayer data={data}/></span>
        </Grid>
        
    </Grid>
    <hr></hr>
        {renderSwitch()}
    </>)

}
export default PreparationDisplayer;