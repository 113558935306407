
import {Chip} from "@mui/material";
const DisplayerProductAttrib = (props) =>{
 
    return(
        <>
        
        {
          props.product.map((attrib) => <Chip label={attrib[0] + " : " + attrib[1]}/>)
        }
        
        </>
  
    );
}
export default DisplayerProductAttrib;