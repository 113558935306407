import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useState, useEffect } from 'react';
import { getCommandesList } from '../../Services/Commandes/Commandes';
import ChannelsPresenter from '../../component/Channels/ChannelsPresenter';
import StepsDisplayer from '../../component/Etapes/StepsDisplayer';
import ButtonActionStep from '../../component/Button/ButtonActionStep';
import { Chip } from '@mui/material';




export default function EmballerList() {

    const [rows, setRows] = useState([]);

    useEffect(() => {
        getCommandesList("", 0, 999, "", "&step[]=/order_preparation_steps/pack&state=/order_preparation_states/stopped&order[priority]=ASC", "", "", "", "").then(result=>{
            setRows(result['hydra:member']);
        });
    }, [])
    return (
        <>
        <h1>Emballer</h1><hr/>
        <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
            <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Numéro de commande</TableCell>
                <TableCell>Client</TableCell>
                <TableCell>Avancement</TableCell>
                <TableCell>Priorité</TableCell>
                <TableCell>Transport</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {rows.map((row) => (
                <TableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                <TableCell component="th" scope="row">
                    {row.name}<ButtonActionStep target={"/order_preparations/"+row.id} step={row.step}/>
                </TableCell>
                <TableCell><ChannelsPresenter channels={[row.channel['@id']]} number={row.orderId} id_prep={row.id}/></TableCell>
                <TableCell>{row.customer['company'] !== ""? row.customer['firstname'] + " " + row.customer['lastname']+ " (" + row.customer['company'] + ")" :  row.customer['firstname'] + " " + row.customer['lastname']}</TableCell>
                <TableCell><StepsDisplayer data={row}/></TableCell>
                <TableCell>{row.priority}</TableCell>
                <TableCell><Chip label={row.carrier.name}/></TableCell>
                </TableRow>
            ))}
            </TableBody>
        </Table>
        </TableContainer>
        </>
    );
}