import { ENTRY_POINT } from "../../global-config";
import { getToken, ValidateToken } from "../Auth/Authentification";

export async function getAgencesList(){
    const options = {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + getToken()
        }
      };
      
    let data = await fetch(ENTRY_POINT+"/agencies", options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => {
            console.error(error);
        });
    
    if(ValidateToken(data) || data !== undefined){
        return data;
    }else{
        return "error";
    }
}

export function editAgences(id, name, code, address, phone){
    const options = {
        method: 'PUT',
        headers: {
        'Content-Type': 'application/ld+json',
        Authorization: 'Bearer ' + getToken()
        },
        body: '{"name":"'+name+'", "code":"'+code+'", "address":"'+address+'", "phone":"'+phone+'"}'
    };
  
    let data = fetch(ENTRY_POINT+id, options)
            .then((response) => response.json())
            .then(data => {
                return data;
            })
            .catch(error => {
                console.error(error);
            });


    
    if(ValidateToken(data)){
        return data;
    }else{
        return "error";
    }
}

export async function getOneAgence(id_agence){
    const options = {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + getToken()
        }
      };
      
    let data = await fetch(ENTRY_POINT+id_agence, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => {
            console.error(error);
        });
    
    if(ValidateToken(data) || data !== undefined){
        return data;
    }else{
        return "error";
    }
}