import { ENTRY_POINT } from "../../global-config";
import { getToken, ValidateToken } from "../Auth/Authentification";

export async function getCommandesList(search, page, itemperpage, filterInject, filterSteps, filterTransporteurs, searchCustomer, searchIdPrep, searchCanal){
    const options = {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + getToken()
        }
      };
    if(filterSteps !== ""){
        filterInject = "";
    }
    page = page+1;

    if(searchCustomer !== ""){
        searchCustomer = "&filterClient="+searchCustomer;
    }

    if(searchIdPrep !== ""){
        searchIdPrep = "&id="+searchIdPrep
    }

    if(searchCanal !== ""){
        searchCanal = "&channel="+searchCanal
    }
      
    let data = await fetch(ENTRY_POINT+"/order_preparations?orderId[]="+search+"&order%5Bid%5D=DESC&page="+page+"&itemsPerPage="+itemperpage+filterInject+filterSteps+filterTransporteurs + searchCustomer + searchIdPrep + searchCanal, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => {
            console.error(error);
        });
    
    if(ValidateToken(data) || data !== undefined){
        return data;
    }else{
        return "error";
    }
}

export function assignNavetteToPrep(id_prep, navette, isList = false){
    let options = {
        method: 'PATCH',
        headers: {
        'Content-Type': 'application/merge-patch+json',
        Authorization: 'Bearer ' + getToken()
        },
        body: '{"navettes":["'+navette+'"]}'
    };

    if(isList){
        options = {
            method: 'PATCH',
            headers: {
            'Content-Type': 'application/merge-patch+json',
            Authorization: 'Bearer ' + getToken()
            },
            body: '{"navettes": '+navette+'}'
        };
    }
  
    let data = fetch(ENTRY_POINT+"/order_preparations/"+id_prep, options)
            .then((response) => response.json())
            .then(data => {
                return data;
            })
            .catch(error => {
                console.error(error);
            });


    
    if(ValidateToken(data)){
        return data;
    }else{
        return "error";
    }
}

export function setPriorityToPrep(id_prep, priority){
    const options = {
        method: 'PATCH',
        headers: {
        'Content-Type': 'application/merge-patch+json',
        Authorization: 'Bearer ' + getToken()
        },
        body: '{"priority":'+priority+'}'
    };
  
    let data = fetch(ENTRY_POINT+"/order_preparations/"+id_prep, options)
            .then((response) => response.json())
            .then(data => {
                return data;
            })
            .catch(error => {
                console.error(error);
            });


    
    if(ValidateToken(data)){
        return data;
    }else{
        return "error";
    }
}

export function assignPrepToUser(id_prep, assignment){
    const options = {
        method: 'PATCH',
        headers: {
        'Content-Type': 'application/merge-patch+json',
        Authorization: 'Bearer ' + getToken()
        },
        body: '{"assignment":"'+assignment+'"}'
    };

    let data = fetch(ENTRY_POINT+"/order_preparations/"+id_prep, options)
            .then((response) => response.json())
            .then(data => {
                return data;
            })
            .catch(error => {
                console.error(error);
            });



    if(ValidateToken(data)){
        return data;
    }else{
        return "error";
    }
}

export function setStepToPreparePrep(id_prep){
    const options = {
        method: 'PATCH',
        headers: {
        'Content-Type': 'application/merge-patch+json',
        Authorization: 'Bearer ' + getToken()
        },
        body: '{"step":"/order_preparation_steps/prepare"}'
    };
  
    let data = fetch(ENTRY_POINT+"/order_preparations/"+id_prep, options)
            .then((response) => response.json())
            .then(data => {
                return data;
            })
            .catch(error => {
                console.error(error);
            });


    
    if(ValidateToken(data)){
        return data;
    }else{
        return "error";
    }
}

export function setStepToAnnuledPrep(id_prep){
    const options = {
        method: 'PATCH',
        headers: {
        'Content-Type': 'application/merge-patch+json',
        Authorization: 'Bearer ' + getToken()
        },
        body: '{"step":"/order_preparation_steps/canceled"}'
    };
  
    let data = fetch(ENTRY_POINT+"/order_preparations/"+id_prep, options)
            .then((response) => response.json())
            .then(data => {
                return data;
            })
            .catch(error => {
                console.error(error);
            });


    
    if(ValidateToken(data)){
        return data;
    }else{
        return "error";
    }
}

export async function getOnePrep(id_prep){
    const options = {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + getToken()
        }
      };
      
    let data = await fetch(ENTRY_POINT+"/order_preparations/"+id_prep, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => {
            console.error(error);
        });
    
    if(ValidateToken(data) || data !== undefined){
        return data;
    }else{
        return "error";
    }
}

export function setMemoPrep(id_prep, memo){
    const options = {
        method: 'PATCH',
        headers: {
        'Content-Type': 'application/merge-patch+json',
        Authorization: 'Bearer ' + getToken()
        },
        body: '{"memo":"'+memo+'"}'
    };
  
    let data = fetch(ENTRY_POINT+"/order_preparations/"+id_prep, options)
            .then((response) => response.json())
            .then(data => {
                return data;
            })
            .catch(error => {
                console.error(error);
            });


    
    if(ValidateToken(data)){
        return data;
    }else{
        return "error";
    }
}

export async function getPrepItems(id_prep){
    const options = {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + getToken()
        }
      };
      
    let data = await fetch(ENTRY_POINT+"/order_preparation_items?order[priority]=ASC&page=1&itemsPerPage=100&orderPreparation.id[]="+id_prep, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => {
            console.error(error);
        });
    
    if(ValidateToken(data) || data !== undefined){
        return data;
    }else{
        return "error";
    }
}

export async function getOnePrepItem(id_prep_item){
    const options = {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + getToken()
        }
      };
      
    let data = await fetch(ENTRY_POINT+id_prep_item, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => {
            console.error(error);
        });
    
    if(ValidateToken(data) || data !== undefined){
        return data;
    }else{
        return "error";
    }
}

export async function getPrepPalettes(id_prep){
    const options = {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + getToken()
        }
      };
      
    let data = await fetch(ENTRY_POINT+"/palettes?orderPreparation.id[]="+id_prep, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => {
            console.error(error);
        });
    
    if(ValidateToken(data) || data !== undefined){
        return data;
    }else{
        return "error";
    }
}


export function setPriorityToPrepItem(id_prep_item, priority){
    const options = {
        method: 'PUT',
        headers: {
        'Content-Type': 'application/ld+json',
        Authorization: 'Bearer ' + getToken()
        },
        body: '{"priority":'+priority+'}'
    };
  
    let data = fetch(ENTRY_POINT+id_prep_item, options)
            .then((response) => response.json())
            .then(data => {
                return data;
            })
            .catch(error => {
                console.error(error);
            });


    
    if(ValidateToken(data)){
        return data;
    }else{
        return "error";
    }
}

export function addNewPaletteToPrep(id_prep, type){
    const options = {
        method: 'POST',
        headers: {
        'Content-Type': 'application/ld+json',
        Authorization: 'Bearer ' + getToken()
        },
        body: '{"orderPreparation":"'+id_prep+'", "type":"'+type+'"}'
    };
  
    let data = fetch(ENTRY_POINT+"/palettes", options)
            .then((response) => response.json())
            .then(data => {
                return data;
            })
            .catch(error => {
                console.error(error);
            });


    
    if(ValidateToken(data)){
        return data;
    }else{
        return "error";
    }
}

export function setStepPrep(id_prep, step){
    const options = {
        method: 'PATCH',
        headers: {
        'Content-Type': 'application/merge-patch+json',
        Authorization: 'Bearer ' + getToken()
        },
        body: '{"step":"'+step+'"}'
    };
  
    let data = fetch(ENTRY_POINT+id_prep, options)
            .then((response) => response.json())
            .then(data => {
                return data;
            })
            .catch(error => {
                console.error(error);
            });


    
    if(ValidateToken(data)){
        return data;
    }else{
        return "error";
    }
}


export async function getStatsAPreparer(){
    const options = {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + getToken()
        }
      };
      
    let data = await fetch(ENTRY_POINT+"/order_preparations?step[]=/order_preparation_steps/prepare&state[]=/order_preparation_states/stopped", options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => {
            console.error(error);
        });
    
    if(ValidateToken(data) || data !== undefined){
        return data["hydra:totalItems"];
    }else{
        return "error";
    }
}

export async function getStatsAController(){
    const options = {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + getToken()
        }
      };
      
    let data = await fetch(ENTRY_POINT+"/order_preparations?step[]=/order_preparation_steps/control&state[]=/order_preparation_states/stopped", options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => {
            console.error(error);
        });
    
    if(ValidateToken(data) || data !== undefined){
        return data["hydra:totalItems"];
    }else{
        return "error";
    }
}

export async function getStatsAControllerResp(){
    const options = {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + getToken()
        }
      };
      
    let data = await fetch(ENTRY_POINT+"/order_preparations?step[]=/order_preparation_steps/control-responsible&state[]=/order_preparation_states/stopped", options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => {
            console.error(error);
        });
    
    if(ValidateToken(data) || data !== undefined){
        return data["hydra:totalItems"];
    }else{
        return "error";
    }
}

export async function getStatsAEmballer(){
    const options = {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + getToken()
        }
      };
      
    let data = await fetch(ENTRY_POINT+"/order_preparations?step[]=/order_preparation_steps/pack&state[]=/order_preparation_states/stopped", options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => {
            console.error(error);
        });
    
    if(ValidateToken(data) || data !== undefined){
        return data["hydra:totalItems"];
    }else{
        return "error";
    }
}

export async function getStatsACharger(){
    const options = {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + getToken()
        }
      };
      
    let data = await fetch(ENTRY_POINT+"/order_preparations?step[]=/order_preparation_steps/charge&state[]=/order_preparation_states/stopped", options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => {
            console.error(error);
        });
    
    if(ValidateToken(data) || data !== undefined){
        return data["hydra:totalItems"];
    }else{
        return "error";
    }
}

export async function stopPrep(id_prep){
    const options = {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/merge-patch+json',
          Authorization: 'Bearer ' + getToken()
        },
        body: '{"state":"/order_preparation_states/stopped"}'
      };
      
    let data = await fetch(ENTRY_POINT+id_prep, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => {
            console.error(error);
        });
    
    if(ValidateToken(data) || data !== undefined){
        return data["hydra:totalItems"];
    }else{
        return "error";
    }
}

export async function startPrep(id_prep){
    const options = {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/merge-patch+json',
          Authorization: 'Bearer ' + getToken()
        },
        body: '{"state":"/order_preparation_states/started"}'
      };
      
    let data = await fetch(ENTRY_POINT+id_prep, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => {
            console.error(error);
        });
    
    if(ValidateToken(data) || data !== undefined){
        return data["hydra:totalItems"];
    }else{
        return "error";
    }
}

export async function pausePrep(id_prep){
    const options = {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/merge-patch+json',
          Authorization: 'Bearer ' + getToken()
        },
        body: '{"state":"/order_preparation_states/pause"}'
      };
      
    let data = await fetch(ENTRY_POINT+id_prep, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => {
            console.error(error);
        });
    
    if(ValidateToken(data) || data !== undefined){
        return data["hydra:totalItems"];
    }else{
        return "error";
    }
}
export async function setCurrentUserPrep(id_user, id_prep){
    const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/ld+json',
          Authorization: 'Bearer ' + getToken()
        },
        body: '{"currentOrderPreparation":"'+id_prep+'"}'
      };
      
    let data = await fetch(ENTRY_POINT+id_user, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => {
            console.error(error);
        });
    
    if(ValidateToken(data) || data !== undefined){
        return data["hydra:totalItems"];
    }else{
        return "error";
    }
}

export async function getStepsList(){
    const options = {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + getToken()
        }
      };
      
      
    let data = await fetch(ENTRY_POINT+"/order_preparation_steps", options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => {
            console.error(error);
        });
    
    if(ValidateToken(data) || data !== undefined){
        return data['hydra:member'];
    }else{
        return "error";
    }
}


export async function getCommunicationList(id_prep){
    const options = {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + getToken()
        }
      };
      
      
    let data = await fetch(ENTRY_POINT+"/communication_advs?prepId="+id_prep, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => {
            console.error(error);
        });
    
    if(ValidateToken(data) || data !== undefined){
        return data['hydra:member'];
    }else{
        return "error";
    }
}


export function addMessageToCommunication(id_prep, message, user){
    const options = {
        method: 'POST',
        headers: {
        'Content-Type': 'application/ld+json',
        Authorization: 'Bearer ' + getToken()
        },
        body: '{"prepId":"'+id_prep+'", "message":"'+message+'", "fromUsertype":"logistique", "user":"'+user+'"}'
    };
  
    let data = fetch(ENTRY_POINT+"/communication_advs", options)
            .then((response) => response.json())
            .then(data => {
                return data;
            })
            .catch(error => {
                console.error(error);
            });


    
    if(ValidateToken(data)){
        return data;
    }else{
        return "error";
    }
}

export function setDoneCommunication(id_com, user){
    const options = {
        method: 'PUT',
        headers: {
        'Content-Type': 'application/ld+json',
        Authorization: 'Bearer ' + getToken()
        },
        body: '{"doneBy":"'+user+'"}'
    };
  
    let data = fetch(ENTRY_POINT+id_com, options)
            .then((response) => response.json())
            .then(data => {
                return data;
            })
            .catch(error => {
                console.error(error);
            });


    
    if(ValidateToken(data)){
        return data;
    }else{
        return "error";
    }
}