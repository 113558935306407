import * as React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button } from "@mui/material";
import { editTransporteurs } from "../../Services/Transporteurs/Transporteurs";

const EditTransporteur = (props) =>{
  let open = props.open;
  let handleReload = props.handleReload;
  let toggleSnack = props.toggleSnack;
  let handleClose = props.handleClose;

  let name = props.name;
  let id = props.target;
  let maxWeight = props.maxWeight;
  let color = props.color;


  let handleName = props.handleName;
  let handleMaxWeight = props.handleMaxWeight;
  let handleColor = props.handleColor;
  




  function onChangeName(e){
    let target = e.target;
    handleName(target.value);
  }

  function onChangeColor(e){
    let target = e.target;
    handleColor(target.value);
  }

  function onChangeMaxWeight(e){
    let target = e.target;
    handleMaxWeight(target.value * 1000);
  }


  function saveToApi(){
    editTransporteurs(id, name, color, maxWeight).then(transporteur => {
        handleReload();
        toggleSnack(true, "Sauvegardé avec succès", "success");
    });
    handleClose();
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Édition d'un transporteur</DialogTitle>
        <DialogContent style={{display: "flex", flexDirection: "column", gap: "10px"}}>
          <TextField id="filled-basic" onChange={onChangeName} label="Nom" value={name} variant="filled" />
          <TextField id="filled-basic" onChange={onChangeColor} label="Couleur" value={color} variant="filled" />
          <TextField id="filled-basic" type="number" onChange={onChangeMaxWeight} label="Poids maximal (kg)" value={maxWeight/1000} variant="filled" />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button onClick={saveToApi}>Appliquer</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default EditTransporteur;