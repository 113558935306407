
import * as React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button } from "@mui/material";
import { editProduct } from "../../Services/Catalogue/Catalogue";
import './EditProduct.css';

const EditProduct = (props) =>{
  let open = props.open;
  let handleReload = props.handleReload;
  let toggleSnack = props.toggleSnack;
  let handleClose = props.handleClose;

  let id = props.id;
  let name = props.name;
  let packagingUnit = props.packagingUnit;
  let packagingType = props.packagingType;
  let packagingQuantity = props.packagingQuantity;
  let weight = props.weight;
  let length = props.length;
  let width = props.width;
  let height = props.height;
  let comment = props.comment;


  let handleName = props.handleName;
  let handlePackagingUnit = props.handlePackagingUnit;
  let handlePackagingType = props.handlePackagingType;
  let handlePackagingQuantity = props.handlePackagingQuantity;
  let handleWeight = props.handleWeight;
  let handleLength = props.handleLength;
  let handleWidth = props.handleWidth;
  let handleHeight = props.handleHeight;
  let handleComment = props.handleComment;
  


  function onChangeName(e){
    let target = e.target;
    handleName(target.value);
  }
  function onChangePackagingUnit(e){
    let target = e.target;
    handlePackagingUnit(target.value);
  }
  function onChangePackagingType(e){
    let target = e.target;
    handlePackagingType(target.value);
  }
  function onChangePackagingQuantity(e){
    let target = e.target;
    handlePackagingQuantity(target.value);
  }
  function onChangeWeight(e){
    let target = e.target;
    handleWeight(target.value * 1000);
  }
  function onChangeLength(e){
    let target = e.target;
    handleLength(target.value);
  }
  function onChangeWidth(e){
    let target = e.target;
    handleWidth(target.value);
  }
  function onChangeHeight(e){
    let target = e.target;
    handleHeight(target.value);
  }
  function onChangeComment(e){
    let target = e.target;
    handleComment(target.value);
  }



  function saveToApi(){
    editProduct(id, name, packagingUnit, packagingType, packagingQuantity, weight, length, width, height, comment).then(product => {
        handleReload();
        toggleSnack(true, "Sauvegardé avec succès", "success");
    });
    handleClose();
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Édition du produit</DialogTitle>
        <DialogContent style={{display: "flex", flexDirection: "column", gap: "10px"}}>
          <TextField id="filled-basic" onInput={onChangeName} label="Nom" value={name} variant="filled" style={{overflow: "hidden"}}/>
          <TextField id="filled-basic" onInput={onChangePackagingUnit} label="Unité de conditionnement" value={packagingUnit} variant="filled" style={{overflow: "hidden"}} />
          <TextField id="filled-basic" onInput={onChangePackagingType} label="Type de conditionnement" value={packagingType} variant="filled" style={{overflow: "hidden"}} />
          <TextField id="filled-basic" onInput={onChangePackagingQuantity} type="number" label="Quantité de conditionnement" value={packagingQuantity} variant="filled" style={{overflow: "hidden"}} />
          <TextField id="filled-basic" onInput={onChangeWeight} type="number" label="Poids (kg)" value={weight/1000} variant="filled" style={{overflow: "hidden"}} />
          <TextField id="filled-basic" onInput={onChangeLength} type="number" label="Longeur" value={length} variant="filled" style={{overflow: "hidden"}} />
          <TextField id="filled-basic" onInput={onChangeWidth} type="number" label="Largeur" value={width} variant="filled" style={{overflow: "hidden"}} />
          <TextField id="filled-basic" onInput={onChangeHeight} type="number" label="Hauteur" value={height} variant="filled" style={{overflow: "hidden"}} />
          Commentaire:
          <textarea id="filled-basic" onInput={onChangeComment} label="Commentaire" value={comment} variant="filled" />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button onClick={saveToApi}>Appliquer</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default EditProduct;