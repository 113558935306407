import { getPalettesWithoutNavette, setChargedNavette } from "../../Services/Navettes/Navettes";
import { useState, useEffect } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { LinearProgress, Tooltip, Button } from "@mui/material";
import ChannelsPresenter, { ChannelsPresenterWithoutFetch } from "../Channels/ChannelsPresenter";
import MoveUpIcon from '@mui/icons-material/MoveUp';
import { setPaletteInNavette } from "../../Services/Palettes/Palettes";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { GoToPaletteDetail } from "../../Pages/Palettes/PaletteDetail";


const NavetteDetailsTablePaletteWithoutNavette = (props) =>{
    let id_navette = props.id_navette;
    let handleReloadInside = props.handleReloadInside;
    let reloadWithoutPal = props.reloadWithoutPal;
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(true);
    const [reload, setReload] = useState(false);

    function handleReload(){
        setReload(!reload);
    }
    useEffect(() => {
      
        setLoading(true);
        getPalettesWithoutNavette().then(palettes => {
                    setLoading(false);
                    setData(palettes['hydra:member']);
                    
        });
      
    }, [id_navette, reload, reloadWithoutPal]);

    function assignToNavette(e){
        e.stopPropagation();
        const id_palette = e.target.getAttribute("id_palette");
        setPaletteInNavette(id_palette, "/navettes/"+id_navette).then((data)=>{
            handleReloadInside();
            handleReload();
        });
        
    }

    return (<>

    <h1>Palette(s) sans navette:</h1><hr></hr>

    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
                <TableCell>Palette n°</TableCell>
                <TableCell align="right">Commande</TableCell>
                <TableCell align="right">Paiement</TableCell>
                <TableCell align="right">Numéro</TableCell>
                <TableCell align="right">Type</TableCell>
                <TableCell align="right">Qté Produits</TableCell>
                <TableCell align="right">Longueur cm</TableCell>
                <TableCell align="right">Largeur cm</TableCell>
                <TableCell align="right">Hauteur cm</TableCell>
                <TableCell align="right">Volume m³</TableCell>
                <TableCell align="right">Poids</TableCell>
                <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {loading? <LinearProgress/>: data.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                  <GoToPaletteDetail id_palette={row['@id'].replace('/palettes/', '')} text={"n°"+row['@id'].replace('/palettes/', '')}/>
                  </TableCell>
                  <TableCell align="right"><ChannelsPresenterWithoutFetch customer={row.orderPreparation.customer} channel={row.orderPreparation.channel} number={row.orderPreparation.orderId} id_prep={row.orderPreparation['@id'].replace("/order_preparations/", "")}/></TableCell>
                  <TableCell align="right">{row.orderPreparation.payment? <CheckIcon style={{color: "green"}} />:<CloseIcon style={{color: "red"}}/>}</TableCell>
                  <TableCell align="right">{row.number}</TableCell>
                  <TableCell align="right">{row.typeName}</TableCell>
                  <TableCell align="right">{row.products}</TableCell>
                  <TableCell align="right">{row.length}</TableCell>
                  <TableCell align="right">{row.width}</TableCell>
                  <TableCell align="right">{row.height}</TableCell>
                  <TableCell align="right">{parseFloat((row.length/100)*(row.width/100)*(row.height/100)).toFixed(2)}</TableCell>
                  <TableCell align="right">{(row.weight/1000).toFixed(2)}kg</TableCell>
                  <TableCell align="right"><Tooltip title="Assigner à la navette"><Button variant="contained" id_palette={row['@id']} onClick={assignToNavette} color="primary" aria-label="ASSIGN TO NAVETTE" component="label">Ajouter</Button></Tooltip></TableCell>
                </TableRow>
              ))}
        </TableBody>
      </Table>
    </TableContainer>
    
    </>);
}

export default NavetteDetailsTablePaletteWithoutNavette;