import {Badge, Button, Tooltip} from "@mui/material";
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from "react";
import CheckIcon from '@mui/icons-material/Check';
import { addMessageToCommunication, getCommunicationList, setDoneCommunication } from "../../Services/Commandes/Commandes";
import { getFirstname, getLastname } from "../../Services/Auth/Authentification";

const style = {"color":"white","margin":"5px","padding":"10px","minWidth":"unset"};

const LigneChatWithConfirmDone = (props) => {
    
    const [validate, setValidate] = useState(props.message['doneBy']? true: false);

    let message = props.message;

    function validateDone(){
        let id_com = message['@id'];
        setDoneCommunication(id_com, getFirstname() + " " + getLastname()).then(result => {
            props.handleReload();
            setValidate(true);
        });
    }

    return(<>
        <div style={{width: "100%", display:"flex", borderBottom: "1px dotted black", padding:"5px", backgroundColor: validate? '#2ecc71': 'unset'}}>
            <span style={{width: "75%"}}><b>{message['user']}</b> :<br/> {message['message']}</span>
            {!validate?<Button onClick={validateDone} variant="outlined" style={{width: "20%"}}><CheckIcon/></Button>:<span style={{textAlign: "center", width: "20%"}}>Validé par : <b>{props.message['doneBy']}</b></span>}
        </div>
    </>)
}

const ButtonCommunicationAdv = (props) =>{
    let id = props.id;
    const [open, setOpen] = useState(false);
    const [messages, setMessages] = useState([]);
    const [notification, setNotification] = useState(0);

    const [sendMessage, setSendMessage] = useState(null);

    const [reload, setReload] = useState(false);
    
    function handleReload(){
        setReload(!reload);
    }

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    useEffect(() => {
        getCommunicationList("/order_preparations/"+id).then(result=>{
            setMessages(result);
            let counter = 0;
            if(result.length > 0){
                result.forEach(element => {
                    if(!element['doneBy']){
                        counter++;
                    }
                });
            }
            setNotification(counter);
        });
    }, [reload, open, id])

    useEffect(() => {
        const interval = setInterval(() => {
            handleReload();
        }, 10000);

        return () => clearInterval(interval);
    }, []);

    function handleTextArea(e){
        let value = e.target.value;
        setSendMessage(value);
    }

    function saveMessage(){
        if(sendMessage.trim() !== ""){
            addMessageToCommunication("/order_preparations/"+id, sendMessage, getFirstname() + " " + getLastname()).then(result =>{
                setSendMessage("");
                handleReload();
            });
        }
    }

    return (
        <> 
        <Tooltip title="Communication ADV" arrow>
            <Badge badgeContent={notification} color="warning" sx={{ "& .MuiBadge-badge": { top: "10px", right: "10px" } }}>
                <Button style={style} variant="contained" onClick={handleClickOpen} component="label" size="small">
                    <NoteAltIcon />
                </Button>
            </Badge>    
        </Tooltip>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle style={{textAlign: "center"}}>Communication ADV</DialogTitle>
          <DialogContent>
            <DialogContentText>
                <div style={{width: "550px", height: "300px", border: "black 1px solid", overflow: "auto", marginLeft: "auto", marginRight: "auto"}}>
                    {messages.map((message)=>{
                      return <LigneChatWithConfirmDone message={message} handleReload={handleReload}/>;  
                    })}
                </div>
                <div style={{display: "flex", marginTop: "5px"}}>
                    <textarea onChange={handleTextArea} value={sendMessage} style={{resize: "none", width: "80%"}}></textarea>
                    <Button onClick={saveMessage} variant="contained">Envoyer</Button>
                </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button style={{color: "red"}} onClick={handleClose}>Fermer</Button>
          </DialogActions>
        </Dialog>
        </>
    )
}
export default ButtonCommunicationAdv;