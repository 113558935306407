import { canAccess } from "../../Services/Auth/Authentification";
import { Navigate } from "react-router-dom";
import UtilisateurList from "./UtilisateursList";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useState } from "react";
import UtilisateurListQR from "./UtilisateurListQR";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

const Utilisateur = (props) => {
    let permNeeded = props.permNeeded;
    let toggleSnack = props.toggleSnack;
    
    const [value, setValue] = useState(0);

    if(!canAccess(permNeeded)){
        return(
            <Navigate to={"/"}/>
        )
    }else{
        

        const handleChange = (event, newValue) => {
          setValue(newValue);
        };
        return(<>
        
            <h1>Liste des utilisateurs</h1>
            <hr></hr>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Utilisateurs" {...a11yProps(0)} />
                    <Tab label="QRCODE" {...a11yProps(1)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <UtilisateurList toggleSnack={toggleSnack}/>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <UtilisateurListQR/>
            </TabPanel>
        </>)
    }
}
export default Utilisateur;