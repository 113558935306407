import { Button } from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";
import { setStepPrep } from "../../Services/Commandes/Commandes";

const ButtonActionStep = (props) => {
    let defaultStep = props.step;
    let target = props.target;
    const [step, setStep] = useState(defaultStep);

    function handleClick(e) {
        e.preventDefault();

        switch (step) {
            case "/order_preparation_steps/plan":
                setStepPrep(target, "/order_preparation_steps/prepare").then((data) => {
                    setStep("/order_preparation_steps/prepare");
                });
                break;
            case "/order_preparation_steps/expect":
                break;
            case "/order_preparation_steps/souffrance":
                window.location = "/souffrance/"+target.replace("/order_preparations/", "");
                break;
            default:break;
        }

    }

    const stylesBtn = {
        display: "inline-flex",
        WebkitBoxAlign: "center",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        BoxSizing: "border-box",
        outline: "0",
        border: "0",
        cursor: "pointer",
        UserSelect: "none",
        verticalAlign: "middle",
        WebkitAppearance: "none",
        textDecoration: "none",
        fontFamily: "'Roboto','Helvetica','Arial',sans-serif",
        fontWeight: "500",
        fontSize: "0.875rem",
        lineHeight: "1.75",
        letterSpacing: "0.02857em",
        textTransform: "uppercase",
        minWidth: "64px",
        padding: "6px 16px",
        borderRadius: "4px",
        fontColor: "white",
        transition: "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    }


    if (step === "/order_preparation_steps/plan") {
        return <Button onClick={handleClick} style={{backgroundColor: "rgb(55, 214, 122)", margin: "10px", width: "175px" }} variant="contained">MISE EN PRÉPARATION</Button>
    } else if (step === "/order_preparation_steps/prepare") {
        return <><Link style={{...stylesBtn,  backgroundColor: "rgb(255, 138, 101)", color: "white", margin: "10px", width: "175px" }} to={'/preparation/'+target.replace("/order_preparations/", "")}>PRÉPARER</Link></>
    } else if (step === "/order_preparation_steps/control") {
        return <><Link style={{...stylesBtn,  backgroundColor: "rgb(44, 204, 228)", color: "white", margin: "10px", width: "175px" }} to={'/controler/'+target.replace("/order_preparations/", "")}>CONTRÔLER</Link></>
    } else if (step === "/order_preparation_steps/control-responsible") {
        return <><Link style={{...stylesBtn,  backgroundColor: "rgb(34, 158, 177)", color: "white", margin: "10px", width: "175px" }} to={'/controler-resp/'+target.replace("/order_preparations/", "")}>CONTRÔLER - RESPONSABLE</Link></>
    } else if (step === "/order_preparation_steps/pack") {
        return <><Link style={{...stylesBtn,  backgroundColor: "rgb(197, 138, 249)", color: "white", margin: "10px", width: "175px" }} to={'/emballer/'+target.replace("/order_preparations/", "")}>EMBALLAGE</Link></>
    } else if (step === "/order_preparation_steps/charge") {
        return <><Link style={{...stylesBtn,  backgroundColor: "rgb(244, 115, 115)", color: "white", margin: "10px", width: "175px" }} to={'/charger/'+target.replace("/order_preparations/", "")}>CHARGER</Link></>
    } else if (step === "/order_preparation_steps/expect") {
        return <Button onClick={handleClick} style={{ backgroundColor: "rgb(105, 118, 137)", margin: "10px", color: "white", width: "175px" }} variant="contained" disabled={"true"}>EXPEDIÉ</Button>
    } else if (step === "/order_preparation_steps/souffrance") {
        return <Button onClick={handleClick} style={{ backgroundColor: "rgb(255, 138, 260)", margin: "10px", width: "175px" }} variant="contained">EN SOUFFRANCE</Button>
    } else {
        return <Button style={{ backgroundColor: "rgb(100, 100, 100)", margin: "10px", width: "175px" }} variant="contained">{step.replace("/order_preparation_steps/", "")}</Button>;
    }


}

export default ButtonActionStep;