import jwt_decode from "jwt-decode";
import { ENTRY_POINT } from "./../../global-config";


export async function Connect(email, password){
    const options = {
        method: 'POST',
        headers: {'Content-Type': 'application/json', Authorization: 'Basic Og=='},
        body: '{"email":"'+email+'","password":"'+password+'"}'
    };
    
    let data = await fetch(ENTRY_POINT+'/authentication_token', options)
        .then(response => response.json())
        .then(response => {return response;})
        .catch(err => console.error(err));

    return ValidateLogin(data);
}

function ValidateLogin(response){
    if((response.code && response.code === 401) && (response.message && response.message === "Invalid credentials.")){
        return false;
    }else if(response.token){
        return Login(response.token);
    }else{
        return false;
    }
}

export function ValidateToken(data){
    if(data === undefined){
        return false;
    }
    if(data["code"] === 401 && (data["message"] === "Invalid JWT Token" || data["message"] === "Expired JWT Token")){
        console.log("Invalid JWT Token");
        Logout();
        return false;
    }else{
        return true;
    }
}

async function getUserDetails(email, token){
    const options = {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + token
        }
    };
    let data = await fetch(ENTRY_POINT+'/users?email='+email, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => {
            console.error(error);
        });
    return data['hydra:member'][0];
};



function Login(token){
    var decodedTokens = jwt_decode(token);
    var email = decodedTokens.username;
    getUserDetails(email, token).then(data => {
        var lastname = data["lastName"];
        var firstname = data["firstName"];
        var id_user = data["id"];
        if((lastname !== null && lastname !== "") && (firstname !== null && firstname !== "") && (id_user !== null && id_user !== "")){
            localStorage.setItem("token", token);
            
            localStorage.setItem("lastname", lastname);
            localStorage.setItem("firstname", firstname);
            //localStorage.setItem("id", id_user);
            return true;
        }else{
            return false;
        }
    }); 
}

export async function getCurrentOrderPrep(id_user){
    const options = {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + getToken()
        }
    };
    let data = await fetch(ENTRY_POINT+id_user, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => {
            console.error(error);
        });
    
    if(ValidateToken(data) && data !== undefined){
        return data["currentOrderPreparation"];

    }else{
        return "error";
    }
    
}

export async function setCurrentOrderPrepForUsersList(ids_users){
    getIdWithEmail().then((result)=>{
        getCurrentOrderPrep(result['hydra:member'][0]['@id']).then(order_prep_id => {
            console.log('Retour',order_prep_id);
            if(order_prep_id === "error"){
                console.log("Erreur lors de l'ajout à la team");
            }else{
                ids_users.forEach((user) =>{
                    const options = {
                        method: 'PUT',
                        headers: {
                          'Content-Type': 'application/json',
                          Authorization: 'Bearer ' + getToken()
                        },
                        body: '{"currentOrderPreparation":"'+order_prep_id+'"}'
                      };
                      
                      fetch(ENTRY_POINT+user, options)
                      .then((response) => response.json())
                      .then(data => {
                        ValidateToken(data);
                        return data;
                      })
                      .catch(error => {
                          console.error(error);
                      });
                });
            }
        });
    }) 
    
}

export async function removeCurrentOrderPrep(id_user){
    const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + getToken()
        },
        body: '{"currentOrderPreparation":null}'
      };
      
      fetch(ENTRY_POINT+"/users/"+id_user, options)
      .then((response) => response.json())
      .then(data => {
        ValidateToken(data);
        return data;
      })
      .catch(error => {
          console.error(error);
      });
    
}

export async function getMyTeams(order_prep_id){
    const options = {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + getToken()
        }
      };
      
    let data = await fetch(ENTRY_POINT+''+order_prep_id, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => {
            console.error(error);
        });
    
    if(ValidateToken(data)){
        return data['currentUsers'];
    }else{
        return "error";
    }
}

export async function getUsersFilteredByGeo(type){
    type = type || "addTeam";

    var emailUser = getEmail();
    let getDomain = emailUser.substring(emailUser.indexOf('@') + 1);
    
    let partEmail = "@" + getDomain;

    if(getRoles().includes("ROLE_ADMIN")){
        partEmail = "";
    }

    const options = {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + getToken()
        }
      };
      
    let data = await fetch(ENTRY_POINT+'/users?email='+partEmail, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => {
            console.error(error);
        });
    
    if(ValidateToken(data)){
        if(type === "Default"){
          return data;
        }else if(type === "AddTeam"){
          return data['hydra:member'];
        }else{
          return data['hydra:member'];  
        }
    }else{
        return "error";
    }
}

export async function getUsersFilteredByGeoAndEmail(type, nameEmail){
    type = type || "addTeam";

    var emailUser = getEmail();
    let getDomain = emailUser.substring(emailUser.indexOf('@') + 1);
    
    let partEmail = nameEmail+"@" + getDomain;

    if(getRoles().includes("ROLE_ADMIN")){
        partEmail = nameEmail+"";
    }

    const options = {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + getToken()
        }
      };
      
    let data = await fetch(ENTRY_POINT+'/users?email='+partEmail, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => {
            console.error(error);
        });
    
    if(ValidateToken(data)){
        if(type === "Default"){
          return data;
        }else if(type === "AddTeam"){
          return data['hydra:member'];
        }else{
          return data['hydra:member'];  
        }
    }else{
        return "error";
    }
}

export async function getMyTeamsDetails(team){
    var arrayUserDetail = [];

    await team.forEach(async function(user){
        let userDetail = [user.lastName, user.firstName, user.id, user.email];
        arrayUserDetail.push(userDetail);
        
    });

    return arrayUserDetail;
}


export function getLastname(){
  return localStorage.getItem("lastname");
}

export function getFirstname(){
    return localStorage.getItem("firstname");
}

export function getToken(){
    return localStorage.getItem("token");
}



export async function getIdWithEmail(){ //IMPERMUTABLE
    const options = {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + getToken()
        }
      };
      
    let data = await fetch(ENTRY_POINT+"/users?email="+getEmail(), options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => {
            console.error(error);
        });
    
    if(ValidateToken(data) || data !== undefined){
        return data;
    }else{
        return "error";
    }
}

export function getRoles(){
    var token = getToken();
    if(token === null){
        window.location.href = "/login";
    }
    var decodedTokens = jwt_decode(token);

    return decodedTokens.roles;
}

export function getEmail(){
    var token = getToken();
    if(token === null){
        window.location.href = "/login";
    }
    var decodedTokens = jwt_decode(token);

    return decodedTokens.username;
}

export function Logout(){
    localStorage.removeItem("lastname");
    localStorage.removeItem("firstname");
    localStorage.removeItem("token");
    localStorage.removeItem("id");
    window.location.href = "/login";
}

export function loginNeeded(){
    if(localStorage.hasOwnProperty("lastname") && localStorage.hasOwnProperty("firstname") && localStorage.hasOwnProperty("token")){
        return false;
    }else{
        return true;
    }
}
const findOne = (haystack, arr) => {
    return arr.some(v => haystack.includes(v));
};
export function canAccess(required){
    var user_perm = getRoles();
    if(loginNeeded() === false && findOne(user_perm, required)){
        return true;
    }else{
        return false;
    }
    
}


export function editUser(id, email, nom, prenom, warehouses, viewPin, canControl){
    
        const options = {
            method: 'PUT',
            headers: {
            'Content-Type': 'application/ld+json',
            Authorization: 'Bearer ' + getToken()
            },
            body: '{"email":"'+email+'","warehouses":'+JSON.stringify(warehouses)+',"firstName":"'+prenom+'","lastName":"'+nom+'","viewPin":'+viewPin+',"canControl":'+canControl+'}'
        };
      
        let data = fetch(ENTRY_POINT+id, options)
                .then((response) => response.json())
                .then(data => {
                    return data;
                })
                .catch(error => {
                    console.error(error);
                });


        
        if(ValidateToken(data)){
            return data;
        }else{
            return "error";
        }
}

export function deleteUsersListSelected(users_list){

    users_list.forEach((user) =>{
        const options = {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer '+getToken()
            },
            body: 'false'
          };
          
          fetch('https://log-api.dev.3bimport.fr/users/'+user, options)
            .then(response => response.json())
            .then(response => console.log(response))
            .catch(err => console.error(err));
    });
}


export async function getOneUser(id_user){
    const options = {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + getToken()
        }
      };
      
    let data = await fetch(ENTRY_POINT+id_user, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => {
            console.error(error);
        });
    
    if(ValidateToken(data) || data !== undefined){
        return data;
    }else{
        return "error";
    }
}


export async function getAssignedPrep(id_user){
    const options = {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + getToken()
        }
    };
    let data = await fetch(ENTRY_POINT+"/order_preparations?page=1&itemsPerPage=9999&assignment="+id_user, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => {
            console.error(error);
        });
    
    if(ValidateToken(data) && data !== undefined){
        var assignedPrep;
        console.log("Prep", data['hydra:member']);
        data['hydra:member'].forEach((preparation)=>{
            if(preparation.hasOwnProperty('assignment') && preparation.assignment['@id'] === id_user && preparation.step !== "/order_preparation_steps/charge" && preparation.step !== "/order_preparation_steps/expect"){
                assignedPrep = preparation;
            }
        });
        return assignedPrep;

    }else{
        return "error";
    }
    
}


export async function getOnePrepProposal(currentStep){
    const options = {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + getToken()
        }
    };
    let data = await fetch(ENTRY_POINT+"/order_preparations?page=1&itemsPerPage=9999&step[]=/order_preparation_steps/"+currentStep+"&order[priority]=DESC&state[]=/order_preparation_states/stopped", options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => {
            console.error(error);
        });
    
    if(ValidateToken(data) && data !== undefined){
        var selectedPrep;
        var i = 0;
        data['hydra:member'].forEach((preparation)=>{
            
            if(!preparation.hasOwnProperty('assignment')){
                if(i == 0){

                    selectedPrep = preparation;
                    
                    i++;
                }
            }
        });
        return selectedPrep;

    }else{
        return "error";
    }
    
}


export async function getPrepProposal(currentStep){
    const options = {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + getToken()
        }
    };
    let data_id = await fetch(ENTRY_POINT+"/order_preparation/"+currentStep, options)
        .then((response) => response.json())
        .then(data_id => {
            return data_id;
        })
        .catch(error => {
            console.error(error);
        });
    
    if(data_id['id'] === -1){
        return {"@id": "Aucune préparation"};
    }else{
        let data = await fetch(ENTRY_POINT+"/order_preparations/"+data_id['id'], options)
            .then((response) => response.json())
            .then(data => {
                return data;
            })
            .catch(error => {
                console.error(error);
            });
        
        if(ValidateToken(data) && data !== undefined){
            
            return data;
    
        }else{
            return {"@id": "Aucune préparation"};
        }
        
    }
    
}

export async function getTokenLoginScanAndTryLogin(qrcode, toggleSnack){
    
    const request = new Request(`${ENTRY_POINT}/quick_authentification_token`,
        {
            method: 'POST',
            body: JSON.stringify({ key: qrcode }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        }
    );
    let token = await fetch(request)
        .then((response) => {
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            return response.json();
        }).catch(error => {
            toggleSnack(true, "Une erreur est survenue lors de la tentative de connexion","error");
        }).then(({ token }) => {
            toggleSnack(true, "Connexion réussi","success");
            return token;
        });

    Login(token);
}

