import { canAccess } from "../../Services/Auth/Authentification";
import { Navigate } from "react-router-dom";
import CommandesList from "./CommandesList";
import React from "react";
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
}
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const Commandes = (props) =>{
    let permNeeded = props.permNeeded;
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    if(!canAccess(permNeeded)){
        return(
            <Navigate to={"/"}/>
        )
    }else{
        

        
        return(<>
            <div style={{display:"flex", alignItems: "center"}}>  
                <span style={{width: "30%", fontWeight: "bold", fontSize: "25px", textAlign: "center"}}>Liste des préparations</span>
                <AppBar position="static" style={{width: "70%"}}>
                    <Tabs
                    value={value}
                    onChange={handleChange}
                    TabIndicatorProps={{style: {background:'white'}}}
                    textColor="inherit"
                    variant="fullWidth"
                    >
                    <Tab label="En cours / À venir" {...a11yProps(0)} />
                    <Tab label="Terminées" {...a11yProps(1)} />
                    </Tabs>
                </AppBar>
            </div> 
            <hr></hr>
            <Box sx={{ bgcolor: 'background.paper', width: "100%" }} centered={true}>
                    <TabPanel value={value} index={0} dir={theme.direction}>
                        <CommandesList filter={"&step[]=/order_preparation_steps/plan&step[]=/order_preparation_steps/prepare&step[]=/order_preparation_steps/control&step[]=/order_preparation_steps/control-responsible&step[]=/order_preparation_steps/pack&step[]=/order_preparation_steps/charge&step[]=/order_preparation_steps/souffrance"} toggleSnack={props.toggleSnack}/>
                    </TabPanel>
                    <TabPanel value={value} index={1} dir={theme.direction}>
                        <CommandesList filter={"&step[]=/order_preparation_steps/expect"} toggleSnack={props.toggleSnack}/>
                    </TabPanel>
            </Box>
        </>)
    }
}

export default Commandes;