import {Box, CircularProgress, Fab, Tooltip} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as React from "react";
import {library} from "@fortawesome/fontawesome-svg-core";
import {
    faPrint
} from "@fortawesome/free-solid-svg-icons";
import {PDFDownloadLink, Document, Page, Text, View, StyleSheet, Font, Image} from '@react-pdf/renderer';
import {useEffect, useState} from "react";
import JsBarcode from "jsbarcode";
import { getOneCustomer } from "../../Services/Clients/Clients";
import { getOneAddress } from "../../Services/Adresse/Adresse";
import { getOneAgence } from "../../Services/Agences/Agences";
import { getOneMarque } from "../../Services/Marques/Marques";
import { getOnePalette } from "../../Services/Palettes/Palettes";

library.add(faPrint);


const styles = StyleSheet.create({
    h1: {fontWeight: 'bold', textTransform: 'uppercase', fontSize: 14},
    h1Centered: { display: "flex", justifyContent: 'center', textTransform: 'uppercase', fontSize: 14, margin: 'auto'},
    h1Centered2: { display: "flex", justifyContent: 'center', textTransform: 'uppercase', fontSize: 14, margin: 'auto', marginBottom: "30px", marginTop: "5px"},
    text: {fontSize: 20, marginBottom: '5px', textTransform: 'uppercase'},
    page: {margin: '15px'},
    section: {height: '46%', width: '96%', border: '2px solid black', padding: '15px'},
    sectionFlex: {height: '46%', display: "flex", flexDirection: "row", marginTop: '15px'},
    sectionExpeditor: {height: '100%', width: '54%', border: '2px solid black', marginRight: '15px', padding: '15px'},
    sectionDetails: {height: '100%', width: '40%', border: '2px solid black', padding: '5px'},
    box: {display: 'flex', alignItems: 'center', justifyContent: 'center', height: '35px', maxWidth: '100px', width: '100px', border: '2px solid black'},
});
Font.register({
    family: 'Open Sans',
    fonts: [
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 }
    ]
});

const PalettePDF = (props) => {
    const split = props.agency.address.split(',');
    const address = split.slice(0, 2);

    //test
    return (
        <Document>
            {props.palette.length > 0 ? props.palette.map(palette =>{
                console.log(palette);
                const canvas = document.createElement('canvas');
                JsBarcode(canvas, palette.ean);
                const barcode = canvas.toDataURL();
                return (<Page size="A4" orientation="landscape" style={styles.page}>
                        <View style={styles.section}>
                            <Text style={styles.h1}>Destinataire</Text>

                            <View style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                margin: '25px 150px',
                                padding: '25px',
                                border: '2px solid black'
                            }}>
                                <Text style={styles.text}>{props.customer.lastname} {props.customer.firstname} {props.customer.compagny}</Text>
                                <Text style={styles.text}>{props.address.street}</Text>
                                <Text style={styles.text}>{props.address.street2}</Text>
                                
                                <Text style={styles.text}>{props.address.zipcode}, {props.address.city}</Text>
                                <Text style={styles.text}>{props.address.state} {props.address.country}</Text>
                            </View>
                        </View>
                        <View style={styles.sectionFlex}>
                            <View style={styles.sectionExpeditor}>
                                <Text style={styles.h1}>Expéditeur</Text>


                                <View style={{display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '15px'}}>
                                    <Image src={props.brand.logo} style={{maxWidth: '300px', height: 'auto', width: 'auto', margin: 'auto'}}/>
                                    <Text style={styles.text}>{props.brand.name}</Text>
                                    <Text style={styles.text}>{address[0]}</Text>
                                    <Text style={styles.text}>{address[1]}</Text>
                                </View>

                            </View>

                            <View style={styles.sectionDetails}>
                                <View style={{height: '60%'}}>
                                    <Text style={styles.h1Centered2}>Prep N°{props.orderPreparation['@id'].split('/').pop()}</Text>
                                    <View style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginBottom: '15px',
                                        marginTop: '25px'
                                    }}>
                                        <View
                                            style={{borderRight: '1px solid black', paddingRight: '15px', marginRight: '15px'}}>
                                            <View style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: 'space-around',
                                                alignItems: 'center',
                                                marginBottom: '5px'
                                            }}>
                                                <Text style={{marginRight: 'auto'}}>L</Text>
                                                <View style={styles.box}>
                                                    <Text>{palette.length} cm</Text>
                                                </View>
                                            </View>
                                            <View style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: 'space-around',
                                                alignItems: 'center',
                                                marginBottom: '5px'
                                            }}>
                                                <Text style={{marginRight: 'auto'}}>l</Text>
                                                <View style={styles.box}>
                                                    <Text>{palette.width} cm</Text>
                                                </View>
                                            </View>
                                            <View style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: 'space-around',
                                                alignItems: 'center'
                                            }}>
                                                <Text style={{marginRight: 'auto'}}>H</Text>
                                                <View style={styles.box}>
                                                    <Text>{palette.height} cm</Text>
                                                </View>
                                            </View>
                                        </View>
                                        <View>
                                            <View style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: 'space-around',
                                                alignItems: 'center'
                                            }}>
                                                <View style={styles.box}>
                                                    <Text>{palette.volume}</Text>
                                                </View>
                                                <Text style={{marginLeft: '5px'}}>m³</Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>

                                <View style={{
                                    height: '50%',
                                    borderTop: '2px solid black',
                                    marginTop: '25px',
                                    paddingTop: '5px',
                                    paddingBottom: '10px'
                                }}>
                                    <Text style={styles.h1Centered}>PALETTES N°{palette.number}</Text>

                                    <View style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginTop: '22px',
                                    }}>
                                        <View style={styles.box}>
                                            <Text>{palette.number}</Text>
                                        </View>
                                        <Text style={{marginRight: '5px', marginLeft: '5px'}}>/</Text>
                                        <View style={styles.box}>
                                            <Text>{props.orderPreparation.totalPalettes.split(" / ")[1]}</Text>
                                        </View>
                                    </View>
                                    
                                    <View style={{width: '70%', height: '150px', marginTop: '15px', marginLeft:'15%', marginRight: '15%'}}>
                                            <Image src={barcode}/>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </Page>
                )
            }):<></>}
            
        </Document>
    )
};

const ButtonPrint = () => (
    <Tooltip title={"Imprimer les étiquettes de la prép"}>
        <Fab style={{boxShadow:"unset"}} variant="extended" color="default" aria-label="Impression">
            <FontAwesomeIcon sx={{ mr: 1 }} style={{marginRight: "10px"}} icon="fa-print"/> Imprimer les étiquettes des palettes
        </Fab>
    </Tooltip>
)

const ButtonMultiplePrint = (props) => {
    const [address, setAddress] = useState(null);
    const [customer, setCustomer] = useState(null);
    const [agency, setAgency] = useState(null);
    const [brand, setBrand] = useState(null);
    const [loading, setLoading] = useState(true);
     const [palettes, setPalettes] = useState([]);
    useEffect(() => {
        if(customer === null){
            setCustomer(0)
            getOneCustomer(props.orderPreparation.customer['@id']).then((data)=>{
                setCustomer(data);
            });
        } else
        if(address === null){
            setAddress(0)
            getOneAddress(props.orderPreparation.address['@id']).then((data)=>{
                setAddress(data);
            });
        } else
        if(agency === null){
            setAgency(0)
            getOneAgence(props.orderPreparation.agency).then((data)=>{
                setAgency(data);
            });
        } else
        if(brand === null){
            setBrand(0)
            getOneMarque(props.orderPreparation.brand).then((data)=>{
                setBrand(data);
            });
        }
        
    }, [props, customer, address, agency, brand])


    useEffect(() => {
        if (loading === true) {
          setPalettes([]);
          const uniquePalettes = []; 
          props.orderPreparation.palettes.forEach(palette => {
            getOnePalette(palette['@id']).then(data => {
              if (!uniquePalettes.some(p => p['@id'] === data['@id'])) {
                uniquePalettes.push(data); 
              }
              if (uniquePalettes.length === props.orderPreparation.palettes.length) {
                setPalettes(uniquePalettes); 
              }
            });
          });
          setLoading(false);
        }
    }, [loading, props.orderPreparation.palettes]);

    if(address && customer && agency && brand && !loading) {
        const pdf = <PalettePDF orderPreparation={props.orderPreparation} palette={props.palette === false? palettes : props.palette} customer={customer} address={address} agency={agency} brand={brand} />;
        let fileName = props.orderPreparation.name + "_" + props.orderPreparation.orderId + "_palette-all.pdf";

        return (
            <Box sx={{ml: 1}}>
                <PDFDownloadLink document={pdf} fileName={fileName}>
                    {({loading}) => loading ? <CircularProgress size={'1rem'}/> : <ButtonPrint/>}
                </PDFDownloadLink>
            </Box>
        )
    }
    return <CircularProgress sx={{ml: 1}} size={'1rem'} />
};

export default ButtonMultiplePrint;



