
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Button, CircularProgress} from '@mui/material';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import {useState, useEffect} from 'react';
import { getOneCustomer } from '../../Services/Clients/Clients';
import { getOneAddress } from '../../Services/Adresse/Adresse';
export default function ButtonCustomerPopup(props) {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const [nom, setNom] = useState("En cours de chargement");
    const [prenom, setPrenom] = useState("En cours de chargement");
    const [tel, setTel] = useState("En cours de chargement");
    const [email, setEmail] = useState("En cours de chargement");
    const [adresse, setAdresse] = useState("En cours de chargement");


    
    const style = {"color":"white","margin":"5px","padding":"10px","minWidth":"unset"};

    function openDialogClient(){
        setOpen(true);
    }

    function close(){
        setOpen(false);
    }
    useEffect(() => {
            setLoading(true);
            if(props.preparation.customer !== "" && props.preparation.customer !== null && props.preparation.customer !== undefined){
                
                getOneCustomer(props.preparation.customer['@id']).then((data)=>{
                    var email = "Pas encore défini";
                    if(data.email !== "" && data.email !== undefined){
                        email = data.email;
                    }
                    setNom(data.firstname);
                    setPrenom(data.lastname);
                    setTel(data.phoneNumber);
                    setEmail(email);

                    getOneAddress(props.preparation.address['@id']).then((data)=>{
                        setAdresse(data.street + " " + data.street2 + " " + data.zipcode + " " + data.city);
                        setLoading(false);
                    });
                })
               
            }
        
        
    }, [open, props.preparation]);

    const Mailto = ({ email, subject, body, children }) => {
        return (
          <a href={`mailto:${email}?subject=${encodeURIComponent(subject) || ''}&body=${encodeURIComponent(body) || ''}`}>{children}</a>
        );
    };
      
    const TelTo = ({tel, children}) => {
        var concat = "tel:+"+tel;
        return (
            <a href={concat}>{children}</a> 
        );
    }
    return (
        <>  
           <section>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <Tooltip title="Client" arrow>
                            <Button onClick={openDialogClient} style={style} variant="contained" component="label" size="small">
                                <AccountCircleIcon />
                            </Button>
                        </Tooltip>
                        </Stack>
                    <Dialog open={open}>
                        <DialogTitle>Infos du Client</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {loading ? <CircularProgress/> : <>
                                <b>Nom</b>: {nom}<br></br><br></br>
                                <b>Prénom</b>: {prenom}<br></br><br></br>
                                <b>Téléphone</b>: <TelTo tel={tel}>{tel}</TelTo><br></br><br></br>
                                <b>Email</b>: <Mailto email={email} subject={"Préparation " + props.preparation.name} body="Bonjour, ">{email}</Mailto><br></br><br></br>
                                <b>Adresse</b>: {adresse}<br></br></>
                                }
                            </DialogContentText>
                            
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={close}>Fermer</Button>
                        </DialogActions>
                    </Dialog>
            </section>
            
        
        </>
    );
}
    