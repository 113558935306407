import { Avatar, LinearProgress } from "@mui/material";
import { useState, useEffect } from "react";
import { getWareHouseDetail } from "../../Services/Depots/WareHouse";

function stringAvatar(name, color) {
    return {
      sx: {
        bgcolor: `${color}`,
      },
      children: `${name}`,
    };
}
const WareHouseAvatar = (props) =>{
    let warehouse_id = props.id_warehouse
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [name, setName] = useState("N");
    const [color, setColor] = useState("grey");

    useEffect(() => {
        setLoading(true);
        getWareHouseDetail(warehouse_id).then(warehouse => {
            if(warehouse === "error"){
                    setLoading(false);
                    setError(true);
            }else{
                    setLoading(false);
                    setName(warehouse.code);
                    setColor(warehouse.color);
            }
        });
    }, [warehouse_id]);

    if(loading){
        return <LinearProgress/>;
    }else{
        if(error){
            return(<p>Une erreur c'est produite lors du chargement,</p>);
        }else{
            return(<Avatar key={props.id_warehouse} style={{border: "unset"}} {...stringAvatar(name, color)} />);
        }
    }
    
}

export default WareHouseAvatar;