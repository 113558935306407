import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import AddToTeamDialog from './AddToTeamDialog';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import './AddToTeam.css';

function stringAvatar(name) {
    return {
      sx: {
        bgcolor: "#00b894",
      },
      children: <GroupAddIcon/>,
    };
}

const AddToTeam = (props) =>{
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };

    let handleReload = props.handleReload;

    return (
        <>
            <Avatar onClick={handleClickOpen} className={"AddToTeam"} {...stringAvatar('+')} />
            <AddToTeamDialog handleReload={handleReload} toggleOpen={open} close={handleClose}/>
        </>
    );
}
export default AddToTeam;