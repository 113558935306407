import { canAccess } from "../../Services/Auth/Authentification";
import { Navigate } from "react-router-dom";

import StepList from "./StepList";

const StepCommande = (props) => {
    let permNeeded = props.permNeeded;
    

    if(!canAccess(permNeeded)){
        return(
            <Navigate to={"/"}/>
        )
    }else{
        

        
        return(<>
        
            <h1>Liste des Etapes</h1>
            <hr></hr>
            <StepList/>
            
        </>)
    }
}
export default StepCommande;