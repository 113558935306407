import { canAccess } from "../../Services/Auth/Authentification";
import { Navigate } from "react-router-dom";
import PalettesList from "./PalettesList";
const Palettes = (props) =>{
    let permNeeded = props.permNeeded;
    

    if(!canAccess(permNeeded)){
        return(
            <Navigate to={"/"}/>
        )
    }else{
        

        
        return(<>
        
            <h1>Liste des palettes</h1>
            <hr></hr>
            <PalettesList toggleSnack={props.toggleSnack}/>
        </>)
    }
}

export default Palettes;