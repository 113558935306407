import ProfilePresenter from '../Profile/ProfilePresenter.js';
import { AvatarGroup } from '@mui/material';
import { getEmail } from '../../Services/Auth/Authentification.js';

const TeamGroupPresenter = (props) =>{
    let team = props.team;
    return(
        <AvatarGroup style={props.from === "commandes_list"?{}:{width: "250px"}} max={4}>
            {   
                props.from === "commandes_list" ? 
                team.map(function(user){
                    return <ProfilePresenter key={user['@id']} type={"team"} name={user.firstName + " " + user.lastName}/>
                })
                :
                team.map(function(user){
                    if(user[3] === getEmail()){
                        return null;
                    }
                    return <ProfilePresenter handleReload={props.handleReload} canRemove={true} key={user[2]} id_user={user[2]} type={"team"} name={user[0] + " " + user[1]}/>
                })
            }
        </AvatarGroup>
    )
}

export default TeamGroupPresenter;