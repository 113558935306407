import { canAccess } from "../../Services/Auth/Authentification";
import { Navigate } from "react-router-dom";
import {useParams} from "react-router-dom";
import NavetteDetailsTablePaletteInside from "../../component/NavetteDetails/NavetteDetailsTablePaletteInside";
import React, { useEffect, useState } from "react";
import { getOneNavette, getPalettesInNavette } from "../../Services/Navettes/Navettes";
import { Grid, LinearProgress } from "@mui/material";
import { Box } from "@mui/system";
import TransporteurPresenter from "../../component/Transporteurs/TransporteurPresenter";
import ChannelsPresenter from "../../component/Channels/ChannelsPresenter";
import PropTypes from 'prop-types';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { getOnePrep } from "../../Services/Commandes/Commandes";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {useNavigate} from 'react-router-dom';

function Row(props) {
const [paletteRow, setPaletteRow] = useState(props.row);
const [open, setOpen] = React.useState(false);


console.log(paletteRow);
return (
    <React.Fragment>
    <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
        <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
        >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
        {paletteRow.typeName}
        </TableCell>
        <TableCell>{paletteRow['ean']}</TableCell>
        <TableCell>N°{paletteRow['@id'].replace("/palettes/", "")}</TableCell>
        <TableCell>
            <span style={{fontWeight: "bold"}}>Client :</span> {paletteRow.orderPreparationDetails['customer'].lastname} 
            {paletteRow.orderPreparationDetails['customer'].firstname} 
            {paletteRow.orderPreparationDetails['customer']['company'] === ""?"":" ("+paletteRow.orderPreparationDetails['customer']['company']+")"}
            <br/>
            <span style={{fontWeight: "bold"}}>Téléphone :</span> {paletteRow.orderPreparationDetails.customer.phoneNumber}
            <br/>
            <span style={{fontWeight: "bold"}}>Email :</span> {paletteRow.orderPreparationDetails.customer.email}
            <br/>
            <span style={{fontWeight: "bold"}}>Adresse :</span> {paletteRow.orderPreparationDetails.address.street} 
            {" "+paletteRow.orderPreparationDetails.address.zipcode} 
            {" "+paletteRow.orderPreparationDetails.address.city} 
            {" "+paletteRow.orderPreparationDetails.address.country} 
            {" "+paletteRow.orderPreparationDetails.address.state}
            <br/>
            <span style={{fontWeight: "bold"}}>Complément :</span> {paletteRow.orderPreparationDetails.address.street2}
        </TableCell>
        <TableCell style={{whiteSpace: "nowrap"}}>{paletteRow.length} x {paletteRow.width} x {paletteRow.height}</TableCell>
        <TableCell style={{whiteSpace: "nowrap"}}>{(((paletteRow.length/100) * (paletteRow.width/100) * (paletteRow.height/100))).toFixed(2)} m³</TableCell>
        <TableCell style={{whiteSpace: "nowrap"}}>{paletteRow.weight / 1000} kg</TableCell>
    </TableRow>
    <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
        <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
            <Typography variant="h6" gutterBottom component="div">
                Détails
            </Typography>
            <Table size="small" aria-label="details">
                <TableHead>
                    <TableRow>
                        <TableCell>Nom du produit</TableCell>
                        <TableCell>Quantité</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {paletteRow.items.map((item, i)=>{
                        return (
                            <TableRow key={i}>
                                    <TableCell>{item.product.name}</TableCell>
                                    <TableCell>{item.quantity} ({item.quantityToString.toUpperCase()})</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
            </Box>
        </Collapse>
        </TableCell>
    </TableRow>
    </React.Fragment>
);
}
  


 
const NavetteRespDetails = (props) =>{
    let permNeeded = props.permNeeded;
    let { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
	const navigate = useNavigate();
    const goBack = () => {
		navigate(-1);
	}

    useEffect(() => {
        setLoading(true);
        if (id !== null) {
          Promise.all([getOneNavette(id), getPalettesInNavette(id)]).then(
            ([navettes, palettes]) => {
              navettes['palettes'] = palettes['hydra:member'];
              const fetches = navettes['palettes'].map((palette) =>
                getOnePrep(
                  palette['orderPreparation']['@id'].replace("/order_preparations/", "")
                )
              );
              Promise.all(fetches).then((preparations) => {
                navettes['palettes'].forEach((palette, index) => {
                  navettes['palettes'][index]['orderPreparationDetails'] =
                    preparations[index];
                });
                setData(navettes);
                setLoading(false);
              });
            }
          );
        }
    }, [id]);

    if(!canAccess(permNeeded)){
        return(
            <Navigate to={"/"}/>
        )
    }else{
        
        
        return(<>

        {loading? <LinearProgress/> :
            <>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={6} md={3}>
                            <IconButton color="primary" aria-label="Go back" component="label" onClick={goBack}>
                                <ArrowBackIcon/>
                            </IconButton>
                            <h1>Detail de la navette n°{id}</h1>
                        </Grid>
                        <Grid item xs={6} md={3} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                            <ChannelsPresenter channels={data.channels}/>
                        </Grid>
                        <Grid item xs={6} md={3} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', m: 1 }}>
                                    </Box>
                        </Grid>
                        <Grid item xs={6} md={3} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                            <TransporteurPresenter component="chip" transporteur={data.carrier}/>
                        </Grid>
                        <Grid item xs={6} md={2} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                            Commandes: {data.orderPreparations.length}
                        </Grid>
                        <Grid item xs={6} md={2} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                            Palettes: {data.palettes.length}
                        </Grid>
                        <Grid item xs={6} md={2} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                            Poids Total: {data.weight /1000}kg
                        </Grid>
                        <Grid item xs={6} md={2} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                            Volume: {data.volume}m³
                        </Grid>
                        <Grid item xs={6} md={2} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                            Arrivée le: {new Date(Date.parse(data.estimatedAt)).toLocaleDateString("fr") + " à " + new Date(Date.parse(data.estimatedAt)).toLocaleTimeString("fr")}
                        </Grid>
                    </Grid>
                </Box>
                <hr></hr>
                <TableContainer component={Paper}>
                    <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell>Type</TableCell>
                            <TableCell>EAN</TableCell>
                            <TableCell>ID</TableCell>
                            <TableCell>Client</TableCell>
                            <TableCell>Lo x La x H</TableCell>
                            <TableCell>Volume m3</TableCell>
                            <TableCell>Poids</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!loading&&data.palettes.map(palette => (
                            <Row key={palette['@id']} row={palette} />
                        ))}
                    </TableBody>
                    </Table>
                </TableContainer>
            </>
        }
        </>)
    }
}

export default NavetteRespDetails;