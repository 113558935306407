import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { LinearProgress, Tooltip } from '@mui/material';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {useState, useEffect} from 'react';
import { getPrepItems, setPriorityToPrepItem } from '../../Services/Commandes/Commandes';
import MediaObjectPresenter from '../MediaObject/MediaObjectPresenter';
import AttributesToArrayDisplayer from '../Product/AttributeToArrayDisplayer';
import StepSpecificDisplayer from '../Etapes/StepSpecificDisplayer';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';


class CustomListDragable extends React.Component {

    constructor(props) {
      super(props);
      this.state = {...props};
    }

    dragStart(e) {
      this.dragged = e.currentTarget;
      e.dataTransfer.effectAllowed = 'move';
      e.dataTransfer.setData('text/html', this.dragged);
    }


    dragEnd(e) {
      this.dragged.style.display = '';
      var data = this.state.items;
      var from = Number(this.dragged.getAttribute('data-id'));
      var to = Number(this.over.getAttribute('data-id'));
      if(from < to) to--;
      data.splice(to, 0, data.splice(from, 1)[0]);
      this.setState({items: data});
    }
    dragOver(e) {
      e.preventDefault();
      this.dragged.style.display = "none";
      if(e.target.className === 'placeholder') return;
      this.over = e.target.closest("tr");
    }

    arraymove(arr, fromIndex, toIndex) {
      var element = arr[fromIndex];
      arr.splice(fromIndex, 1);
      arr.splice(toIndex, 0, element);
    }


    positionUp(e){
      var new_array = this.state.items.filter(item => item).slice();
      var target = e.target.closest("button");
      var old_position = parseInt(target.getAttribute("valueofi"));
      var new_position = parseInt(target.getAttribute("valueofi")) - 1;
      if(old_position != 0){
        this.arraymove(new_array, old_position, new_position);
        this.setState({items : new_array});
      }
    }
    positionDown(e){
      var new_array = this.state.items.filter(item => item).slice();
      var target = e.target.closest("button");
      var old_position = parseInt(target.getAttribute("valueofi"));
      var new_position = parseInt(target.getAttribute("valueofi")) + 1;
      if(old_position != this.state.items.length){
        this.arraymove(new_array, old_position, new_position);
        this.setState({items : new_array});
      }
    }

      render() {
        
      console.log("items", this.state.items);
      var listItems = this.state.items.map((item, i) => {
        setPriorityToPrepItem(item['@id'], i);
        return (
          <TableRow 
            style={{cursor: "grab"
          }}
            data-id={i}
            key={i}
            draggable='true'
            onDragEnd={this.dragEnd.bind(this)}
            onDragOver={this.dragOver.bind(this)}
            onDragStart={this.dragStart.bind(this)}
            onTouchStart={this.dragStart.bind(this)}
            onTouchEnd={this.dragEnd.bind(this)}>
                <TableCell align="left">
                  <IconButton color="success" valueofi={i} onClick={this.positionUp.bind(this)}>
                    <ArrowDropUpIcon/>
                  </IconButton>
                      {"n°"+(i + 1)}
                  <IconButton color="error" valueofi={i} onClick={this.positionDown.bind(this)}>
                    <ArrowDropDownIcon/>
                  </IconButton>
                </TableCell>
                <TableCell align="center"><span style={{backgroundColor: item.spot.color, fontWeight: "bold", padding:"5px", borderRadius: "5px"}}>{item.spot.spot}</span></TableCell>
                <TableCell align="center"><MediaObjectPresenter height="64" width="64" mediaobjectid={item.product.image?item.product.image['@id']: ""} /></TableCell>
                <TableCell align="center"><span><h3>{item.product.name}</h3><AttributesToArrayDisplayer attributes={item.product.attributesToArray} withCustom={item.custom}/></span></TableCell>
                <TableCell align="center"><StepSpecificDisplayer data={item} stepSelected={"prepare"}/></TableCell>
          </TableRow>
        )
       });
          return (
              <TableBody>
                {listItems}
              </TableBody>
          )
      }
  }

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const PreparationsPriorityList = (props) =>{
    let id_prep = props.id_prep;
    let handleReload = props.handleReload;
    const [open, setOpen] = useState(false);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      setLoading(true);
      getPrepItems(id_prep).then((data) => {
        setData(data['hydra:member']);
        setLoading(false);
      })
    
    }, [id_prep, open]);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    const handleSave = () => {
      handleReload();
    };
    if(props.type === "preview"){
      return (<>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                    <TableRow>
                        <TableCell>Ordre/Priorité</TableCell>
                        <TableCell align="center">Emplacement</TableCell>
                        <TableCell align="center">Image</TableCell>
                        <TableCell align="center">Produit</TableCell>
                        <TableCell align="center">Progression</TableCell>
                    </TableRow>
                    </TableHead>
                    {loading? <LinearProgress style={{width: "100%"}}/> : 
                      <CustomListDragable items={data}/>
                    }
                </Table>
              </TableContainer>
          
              </>)
    }

    return (
      <>
        <Tooltip title="Voir les produits et gerer les priorités"><Button style={{color: "white", margin: "5px", padding: "10px", minWidth: "unset", backgroundColor: "#1976d2"}} onClick={handleClickOpen}><FormatListBulletedIcon/></Button></Tooltip>
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                LISTE DES PRODUITS DE LA COMMANDE
              </Typography>
              <Button autoFocus color="inherit" onClick={handleSave}>
                SAUVEGARDER
              </Button>
            </Toolbar>
          </AppBar>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                <TableRow>
                    <TableCell>Ordre/Priorité</TableCell>
                    <TableCell align="center">Emplacement</TableCell>
                    <TableCell align="center">Image</TableCell>
                    <TableCell align="center">Produit</TableCell>
                    <TableCell align="center">Progression</TableCell>
                </TableRow>
                </TableHead>
                {loading? <LinearProgress style={{width: "100%"}}/> : 
                <CustomListDragable items={data}/>
                }
            </Table>
          </TableContainer>
          
          
        </Dialog>
      </>
    );
}
export default PreparationsPriorityList;