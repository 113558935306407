import { canAccess } from "../../Services/Auth/Authentification";
import { Navigate } from "react-router-dom";
import EmplacementsList from "./EmplacementsList";
import { Box, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import PlanEntrepot from "../../component/Plan/PlanEntrepot";
const Emplacements = (props) =>{
    let permNeeded = props.permNeeded;
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  

    if(!canAccess(permNeeded)){
        return(
            <Navigate to={"/"}/>
        )
    }else{

        return(<>
            <Box sx={{ width: '100%', bgcolor: 'background.paper' }} style={{display: "flex",alignItems: "center", textAlign: "center", gap: "50px"}}>
                
            <h1>Liste des Emplacements</h1>
            <Tabs style={{display:"none"}} value={value} onChange={handleChange} centered>
                <Tab label="Liste" />
                <Tab label="Plan" />
            </Tabs>
            </Box>
            <hr></hr>
            {value === 0? <EmplacementsList toggleSnack={props.toggleSnack}/>: <PlanEntrepot/>}
            
        </>)
    }
}

export default Emplacements;