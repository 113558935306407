import { useEffect, useState } from "react";
import { LinearProgress } from "@mui/material";

import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import { visuallyHidden } from '@mui/utils';
import {TextField} from "@mui/material";
import { getPalettesList } from "../../Services/Palettes/Palettes";
import ChannelsPresenter, { ChannelsPresenterWithoutFetch } from "../../component/Channels/ChannelsPresenter";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { GoToPaletteDetail } from "./PaletteDetail";


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
}
  
function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
}
  
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}
  
const headCells = [
    {
      id: 'ean',
      numeric: false,
      disablePadding: true,
      textAlign: "left",
      label: 'EAN',
    },
    {
      id: 'orderPreparation.orderId',
      numeric: false,
      disablePadding: false,
      textAlign: "center",
      label: 'Commande',
    },
    {
      id: 'number',
      numeric: false,
      disablePadding: false,
      textAlign: "center",
      label: 'Numéro',
    },
    {
      id: 'category.name',
      numeric: false,
      disablePadding: false,
      textAlign: "center",
      label: 'Type',
    },
    {
      id: 'products',
      numeric: false,
      disablePadding: false,
      textAlign: "center",
      label: 'Qtés Produits',
    },
    {
      id: 'length',
      numeric: false,
      disablePadding: false,
      textAlign: "center",
      label: 'Longueur cm',
    },
    {
      id: 'width',
      numeric: false,
      disablePadding: false,
      textAlign: "center",
      label: 'Largeur cm',
    },
    {
      id: 'height',
      numeric: false,
      disablePadding: false,
      textAlign: "center",
      label: 'Hauteur cm',
    },
    {
      id: 'volume',
      numeric: false,
      disablePadding: false,
      textAlign: "center",
      label: 'Volume m3',
    },
    {
      id: 'weight',
      numeric: false,
      disablePadding: false,
      textAlign: "center",
      label: 'Poids (kg)',
    },
    {
      id: 'spot',
      numeric: false,
      disablePadding: false,
      textAlign: "center",
      label: 'Emplacement',
    },
    {
      id: 'controlled',
      numeric: false,
      disablePadding: false,
      textAlign: "center",
      label: 'Contrôlé',
    },
    {
      id: 'packed',
      numeric: false,
      disablePadding: false,
      textAlign: "center",
      label: 'Emballé',
    },
    {
      id: 'charged',
      numeric: false,
      disablePadding: false,
      textAlign: "right",
      label: 'Chargé',
    },
];
  
function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
      props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
  
    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              style={{textAlign: headCell.textAlign}}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
}
  
EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};
  
function EnhancedTableToolbar(props) {
    const { numSelected } = props;
    let deleteUserSelected = props.deleteUserSelected;
    let handleSearch = props.handleSearch;
    let searchval = props.searchval;


    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: '1 1 100%' }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} séléctionné(s)
          </Typography>
        ) : (
          <></>
        )}
  
        {numSelected > 0 ? (
          <Tooltip title="Supprimer">
            <IconButton onClick={deleteUserSelected}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <TextField id="outlined-basic" label="Rechercher" value={searchval} onInput={handleSearch} variant="outlined" />
        )}
      </Toolbar>
    );
}
  
EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};
  

const PalettesList = (props) =>{
    const [reload, setReload] = useState(false);
    const [search, setSearch] = useState("");


    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('nom');
    const [selected, setSelected] = useState([]);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    
    /***
     * Dialog
     */
    const [open, setOpen] = useState(false);


    let toggleSnack = props.toggleSnack;

    function handleSearch(e){
      let target = e.target;
      toggleSnack("true","Aucun filtre sur les palettes", "error");
      setSearch(target.value);
    }
    
    function handleReload(){
      setReload(!reload);
      setLoading(false);
    }

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    


    /***
     * ---------
     */

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
    if (event.target.checked) {
        const newSelected = rows.map((n) => n['@id']);
        setSelected(newSelected);
        return;
    }
    setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };
    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const isSelected = (name) => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    //const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    useEffect(() => {
      
        setLoading(true);
        getPalettesList(search, page, rowsPerPage).then(users => {
            if(users === "error"){
                    setLoading(false);
                    setError(true);
            }else{
                    setLoading(false);
                    setRows(users['hydra:member']);
                    setTotal(users['hydra:totalItems']);
            }
        });
      
    }, [reload, search, page, rowsPerPage]);

    function LabelDisplayedRows({ from, to, count }) { return `${from}–${to} de ${count !== -1 ? count : `Un peu plus de ${to}`}`; }
    
    
    function deleteProductSelected(){
      //deleteUsersListSelected(selected);
      //handleReload();
      toggleSnack(true, "Désactivé pour le moment, constraint foreign key", "error");
    }


    
    if(error){
        return(<p>Erreur lors du chargement de la liste</p>)
    }else{
        return(<>
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <EnhancedTableToolbar handleSearch={handleSearch} searchval={search} deleteUserSelected={deleteProductSelected} numSelected={selected.length} />
                <TableContainer>
                  
                {loading ? <LinearProgress/>:""}
                <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size={'medium'}
                >
                    <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={total}
                    />
                    
                    <TableBody>
                    {stableSort(rows, getComparator(order, orderBy))
                        .map((row, index) => {
                        const isItemSelected = isSelected(row['@id']);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        console.log(row);
                        return (
                            <TableRow
                            hover
                            onClick={(event) => handleClick(event, row['@id'])}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row['@id']}
                            selected={isItemSelected}
                            >
                            <TableCell padding="checkbox">
                                <Checkbox
                                color="primary"
                                checked={isItemSelected}
                                inputProps={{
                                    'aria-labelledby': labelId,
                                }}
                                />
                            </TableCell>
                            <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                            >
                                <GoToPaletteDetail id_palette={row['@id'].replace("/palettes/", "")} text={row.ean}/>
                            </TableCell>
                            <TableCell align="center"><ChannelsPresenterWithoutFetch customer={row.orderPreparation.customer} channel={row.orderPreparation.channel} number={row.orderPreparation.orderId} id_prep={row.orderPreparation['@id'].replace("/order_preparations/", "")}/></TableCell>
                            <TableCell align="center">{row.number}</TableCell>
                            <TableCell align="center">{row.type.name}</TableCell>
                            <TableCell align="center">{row.products}</TableCell>
                            <TableCell align="center">{row.length}</TableCell>
                            <TableCell align="center">{row.width}</TableCell>
                            <TableCell align="center">{row.height}</TableCell>
                            <TableCell align="center">{row.volume.toFixed(2)}</TableCell>
                            <TableCell align="center">{row.weight / 1000}kg</TableCell>
                            <TableCell align="center"><span style={{backgroundColor: row.spot.color, fontWeight: "bold", padding:"5px", borderRadius: "5px"}}>{row.spot.spot}</span></TableCell>
                            <TableCell align="center">{row.controlled? <CheckIcon style={{color: "green"}} />:<CloseIcon style={{color: "red"}}/>}</TableCell>
                            <TableCell align="right">{row.packed? <CheckIcon style={{color: "green"}} />:<CloseIcon style={{color: "red"}}/>}</TableCell>
                            <TableCell align="right">{row.charged? <CheckIcon style={{color: "green"}} />:<CloseIcon style={{color: "red"}}/>}</TableCell>
                            </TableRow>
                        );
                        })}
                    
                    </TableBody>
                </Table>
                </TableContainer>
                <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                labelRowsPerPage={"Nombre par page"}
                labelDisplayedRows={LabelDisplayedRows}
                component="div"
                count={total}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            </Box>
            
        </>)
    }
   
}
export default PalettesList;





