import { createTheme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Button, LinearProgress } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useEffect, useState } from 'react';
import { getNavettesList } from '../../Services/Navettes/Navettes';
import { getOnePalette, setPaletteInNavette, ToggleChargedPalette } from '../../Services/Palettes/Palettes';

const CardPaletteToNavette = (props) =>{

    let item = props.item;
    let navettes = props.navettes;
    let ghostReload = props.ghostReload;
    const [isValidate, setisValidate] = useState(true);
    const [navetteInfo, setNavetteInfo] = useState("Navette n°Erreur");
    
    const theme = useTheme();
    const styles = {
        display: "flex",
        flexDirection: "column",
        fontSize: "18px", 
        fontWeight: "700", 
        WebkitBoxPack: "center", 
        justifyContent: "center", 
        alignItems: "center", 
        width: "100px",
        height: "100px", 
        minWidth: "100px", 
        minHeight: "100px", 
        marginTop: "8px",
        marginLeft: "auto",
        marginRight: "auto",
    }
    const [selectedNavette, setNavette] = useState("");

    const handleChange = (event) => {
        setNavette(event.target.value);
    };
    function validateThisPaletteToNavette(){
        if(selectedNavette !== ""){
            setPaletteInNavette(item['@id'], selectedNavette).then((response)=>{
                ToggleChargedPalette(item['@id'], "true").then((response)=>{
                    ghostReload();
                    setisValidate(true);
                });
            })
        }
    }

    useEffect(() => {
        
        getOnePalette(item['@id']).then((response)=>{
            if(response.charged === true){
                setNavetteInfo("Navette n°"+response.navette.replace("/navettes/", ""));
                setisValidate(true);
            }else{
                setisValidate(false);
            }
        });
        
    }, [item, isValidate]);

    return(
    <Card sx={{ display: 'flex', alignItems: "center" }}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flex: '1 0 auto' }}>
            <span style={isValidate?{...styles, color: "rgb(0, 151, 0)",border: "2px solid rgb(43, 175, 43)", backgroundColor: "rgb(204, 255, 204)"}:{...styles, color: "rgb(153, 0, 0)",border: "2px solid rgb(153, 0, 0)", backgroundColor: "rgb(255, 204, 204)"}}><span>n°{item.number}</span><span style={{fontSize: "12px", textAlign: "center"}}>{item.typeName}</span></span>
        </CardContent>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {!isValidate? 
            <>
                <CardContent sx={{ flex: '1 0 auto'}} >
                    {typeof navettes !== 'undefined' && navettes !== null && navettes.length > 0 ? 
                        <FormControl>
                        <InputLabel style={{top: "8px"}} id="select-navette">Navette</InputLabel>
                        <Select
                            labelId="select-navette"
                            label={"Navette"}
                            value={selectedNavette}
                            style={{width: "150px", marginTop: "8px"}}
                            onChange={handleChange}
                        >
                            <MenuItem value={""} disabled={true}>Choisir une navette</MenuItem>
                            {
                                navettes.map((item, index) => {
                                    return(<MenuItem key={index} value={item['@id']}>n°{item['@id'].replace('/navettes/', '')} {item.carrier.name}</MenuItem>)
                                })

                            }
                        </Select>
                        </FormControl>
                    :
                        <><span>Navette</span><LinearProgress/></>
                    }
                </CardContent>
                <CardContent sx={{ flex: '1 0 auto' }} style={{justifyContent: "center", textAlign: "center"}}>
                    <Button variant="contained" onClick={validateThisPaletteToNavette} color="success">Valider</Button>
                </CardContent>
            </>
            :
            <span style={{paddingRight: "15px"}}>Palette charger <br/>({navetteInfo})</span>
        }
      </Box>
    </Card>
    
    );
    
}

const ChargerSystem = (props) =>{
    let dataprep = props.data;
    let id_prep = props.id_prep;
    let ghostReload = props.ghostReload;
    const [navettes, setNavettes] = useState(null);

    useEffect(() => {
        let selected = [];
        selected["selected"] = [];
        getNavettesList(0, 999, false, selected).then((data) =>{
            setNavettes(data['hydra:member']);
        });
    
    }, [id_prep]);


    return(<>
    
        {
            dataprep.charged.percent === 100?
                <p style={{textAlign: "center", width: "100%"}}>Vous n'avez plus rien à faire sur cette préparation, c'est au Responsable de valider le chargement globale de la navette</p>
            :   <></>
        }
                <div style={{display: "flex", flexWrap: "wrap", flexDirection: "row", gap:"5px", padding: "10px", justifyContent: "center"}}>

                    {
                        dataprep.palettes.map((item, index)=>{
                            return <CardPaletteToNavette key={index} ghostReload={ghostReload} navettes={navettes} item={item}/>
                        })
                    }

                </div>
        
    
    </>)
}
export default ChargerSystem;
