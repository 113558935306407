import { editMarques } from "../../Services/Marques/Marques";
import * as React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button } from "@mui/material";

const EditMarques = (props) =>{
  let open = props.open;
  let handleReload = props.handleReload;
  let toggleSnack = props.toggleSnack;
  let handleClose = props.handleClose;

  let name = props.name;
  let code = props.code;
  let id = props.target;
  let img = props.img;

  let handleCode = props.handleCode;
  let handleName = props.handleName;
  let handleImg = props.handleImg;

  




  function onChangeName(e){
    let target = e.target;
    handleName(target.value);
  }

  function onChangeCode(e){
    let target = e.target;
    handleCode(target.value);
  }

  function onChangeImg(e){
    let target = e.target;
    handleImg(target.value);
  }


  function saveToApi(){
    editMarques(id, name, code).then(brands => {
        handleReload();
        toggleSnack(true, "Sauvegardé avec succès", "success");
    });
    handleClose();
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Édition de la marque</DialogTitle>
        <DialogContent style={{display: "flex", flexDirection: "column", gap: "10px"}}>
          <TextField id="filled-basic" onChange={onChangeName} label="Nom" value={name} variant="filled" />
          <TextField id="filled-basic" onChange={onChangeCode} label="Code" value={code} variant="filled" />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button onClick={saveToApi}>Appliquer</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default EditMarques;