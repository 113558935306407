import { canAccess } from "../../Services/Auth/Authentification";
import { Navigate } from "react-router-dom";

import MarquesList from './MarquesList';

const Marques = (props) => {
    let permNeeded = props.permNeeded;
    

    if(!canAccess(permNeeded)){
        return(
            <Navigate to={"/"}/>
        )
    }else{
        

        
        return(<>
        
            <h1>Liste des Marques</h1>
            <hr></hr>
            <MarquesList/>
            
        </>)
    }
}
export default Marques;