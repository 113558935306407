import EntrepotSys from './EntrepotSys';
import * as React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import CreateIcon from '@mui/icons-material/Create';
import MouseIcon from '@mui/icons-material/Mouse';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import DeleteIcon from '@mui/icons-material/Delete';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import CachedIcon from '@mui/icons-material/Cached';
import SwitchCameraIcon from '@mui/icons-material/SwitchCamera';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from '@mui/icons-material/Search';
import { Alert, Button, FormControl, IconButton, InputLabel, MenuItem, Select, Snackbar, TextField, Tooltip } from '@mui/material';
import {useState, useRef, useEffect} from "react";
import Popover from '@mui/material/Popover';
import ClearIcon from '@mui/icons-material/Clear';
import ReplayIcon from '@mui/icons-material/Replay';
import EditLocationIcon from '@mui/icons-material/EditLocation';
import PrintIcon from '@mui/icons-material/Print';

const Plans = [
  {id: 0, name: "Test", lines: '[{"start":{"x":39,"y":40},"end":{"x":39,"y":660}},{"start":{"x":39,"y":661},"end":{"x":1160,"y":661}},{"start":{"x":1160,"y":661},"end":{"x":1160,"y":40}},{"start":{"x":1160,"y":40},"end":{"x":39,"y":40}},{"start":{"x":39,"y":600},"end":{"x":99,"y":600}},{"start":{"x":39,"y":540},"end":{"x":101,"y":540}},{"start":{"x":1020,"y":40},"end":{"x":1020,"y":100}},{"start":{"x":959,"y":40},"end":{"x":959,"y":100}},{"start":{"x":900,"y":40},"end":{"x":900,"y":100}}]', spots: '[]'},
];

const PlanEntrepot = () => {
  const [mode, setMode] = useState("SELECT");
  const [filterRegex, setfilterRegex] = useState("**-**");
  const [plans, setPlans] = useState(Plans);
  const [selectedPlans, setSelectedPlans] = useState(0);
  const [inputNewPlan, setInputNewPlan] = useState("");
  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const [anchorElNewPlan, setAnchorElNewPlan] = useState(null);
  const [anchorElLoadPlan, setAnchorElLoadPlan] = useState(null);
  const [snackOpen, setSnackOpen] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
    message: '',
    color: 'success'
  });
  const { vertical, horizontal, open, message, color } = snackOpen;

  const toolBarRef= useRef(null);

  const handleFilter = (event) => {
    var value = event.target.value;
    setfilterRegex(value.toUpperCase());
    toolBarRef.current.setRegexFilter(value.toUpperCase());
  };

  const clearRegex = (event) => {
    setfilterRegex("**-**");
    toolBarRef.current.setRegexFilter(null);
  };

  const openFilter = Boolean(anchorElFilter);
  const handleOpenFilter = (event) => {
    setAnchorElFilter(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setAnchorElFilter(null);
  };

  const openNewPlan = Boolean(anchorElNewPlan);
  const handleOpenNewPlan = (event) => {
    setAnchorElNewPlan(event.currentTarget);
  };

  const handleCloseNewPlan = () => {
    setAnchorElNewPlan(null);
  };


  const openLoadPlan = Boolean(anchorElLoadPlan);
  const handleOpenLoadPlan = (event) => {
    setAnchorElLoadPlan(event.currentTarget);
  };

  const handleCloseLoadPlan = () => {
    setAnchorElLoadPlan(null);
  };

  
  const drawerWidth = 60;

  

  function clickSelect(){
    toolBarRef.current.handleSelectButtonClick();
    setMode("SELECT");
    toolBarRef.current.canvasRef.current.style.cursor = "pointer";
  }

  function clickCreate(){
    toolBarRef.current.handleCreateButtonClick();
    toolBarRef.current.canvasRef.current.style.cursor = "crosshair";
    setMode("CREATE");
  }

  function clickCreateSpot(){
    toolBarRef.current.handleCreateSpotButtonClick();
    setMode("CREATESPOT");
    toolBarRef.current.canvasRef.current.style.cursor = "cell";
  }

  function clickMove(){
    toolBarRef.current.handleMoveButtonClick();
    setMode("MOVE");
    toolBarRef.current.canvasRef.current.style.cursor = "move";
  }

  function clickHideNames(){
    toolBarRef.current.toggleViewNames();
  }


  function clickDelete(){
    toolBarRef.current.deleteLigne();
  }

  function saveActualPlan(){
    const newState = { 
                        vertical: 'top',
                        horizontal: 'center',
                        message: "Plan sauvegardé avec succès",
                        color: "success",
                      }
    setSnackOpen({ open: true, ...newState });

    const newPlans = [...plans];
    newPlans[selectedPlans] = {
      ...newPlans[selectedPlans],
      lines: JSON.stringify(toolBarRef.current.lines),
      spots: JSON.stringify(toolBarRef.current.spots),
    };
    setPlans(newPlans);
  }

  function createNewPlan(){
    var newState;
    if(inputNewPlan != ""){
      newState = { 
        vertical: 'top',
        horizontal: 'center',
        message: "Nouveau plan ajouté avec succès",
        color: "success",
      }
      var newPlan = {id: 0, name: inputNewPlan, lines: '[]', spots: '[]'};
      var updatedPlans = [...plans, newPlan];
      setPlans(updatedPlans);
      setSelectedPlans(plans.length);
    }else{
      newState = { 
        vertical: 'top',
        horizontal: 'center',
        message: "Le nom ne peut pas être vide",
        color: "error",
      }
    }
    
    setSnackOpen({ open: true, ...newState });
  }

  function handleInputNewPlan(e){
    setInputNewPlan(e.target.value);
  }


  function onChangeSelectedPlan(e){
    var value = e.target.value;
    setSelectedPlans(value);
  }


  const handleCloseSnack = () => {
    setSnackOpen({ ...snackOpen, open: false });
  };

  function openEditSpot(){
    toolBarRef.current.editSpotsSelected();
  }

  function PrintPlan(){
    const printWindow = window.open();
    printWindow.document.write(`<html><head><title>Impression du plan : `+plans[selectedPlans]['name']+` - `+JSON.parse(plans[selectedPlans]['spots']).length+` emplacement(s)</title></head><body><img src="`+toolBarRef.current.canvasRef.current.toDataURL()+`"></body></html>`);
    printWindow.document.close();
  
    // Lancer l'impression
    printWindow.print();
  }


  const drawer = (
    <div style={{overflow:"hidden"}}>
      <List>
        <ListItem key={"1"} disablePadding>
            <Tooltip title="Séléctionner (simple clique)">
              <ListItemButton onClick={clickSelect}>
                <ListItemIcon>
                  <MouseIcon style={mode === "SELECT" ? {color: "#3498db"} : {color: "unset"}}/>
                </ListItemIcon>
              </ListItemButton>
            </Tooltip>
        </ListItem>
        <ListItem key={"2"} disablePadding>
            <Tooltip title="Crée (clique 2 fois sur le dessin pour placer une ligne/mur)">
              <ListItemButton onClick={clickCreate}>
                <ListItemIcon>
                  <CreateIcon style={mode === "CREATE" ? {color: "#3498db"} : {color: "unset"}} />
                </ListItemIcon>
              </ListItemButton>
            </Tooltip>
        </ListItem>
        <ListItem key={"3"} disablePadding>
            <Tooltip title="Crée un emplacement">
              <ListItemButton onClick={clickCreateSpot}>
                <ListItemIcon>
                  <AllInboxIcon style={mode === "CREATESPOT" ? {color: "#3498db"} : {color: "unset"}} />
                </ListItemIcon>
              </ListItemButton>
            </Tooltip>
        </ListItem>
        <ListItem key={"4"} disablePadding>
            <Tooltip title="Editer le spot (Touche E en ayant séléctionné un spot)">
              <ListItemButton onClick={openEditSpot}>
                <ListItemIcon>
                  <EditLocationIcon/>
                </ListItemIcon>
              </ListItemButton>
            </Tooltip>
        </ListItem>
        <ListItem key={"5"} disablePadding>
            <Tooltip title="Permet de déplacer une ligne séléctionnée">
              <ListItemButton onClick={clickMove}>
                <ListItemIcon>
                  <OpenWithIcon style={mode === "MOVE" ? {color: "#3498db"} : {color: "unset"}} />
                </ListItemIcon>
              </ListItemButton>
            </Tooltip>
        </ListItem>
        <Divider/>
        <ListItem key={"6"} disablePadding>
            <Tooltip title="Cache/montre les noms des emplacements">
              <ListItemButton onClick={clickHideNames}>
                <ListItemIcon>
                  <FilterAltIcon />
                </ListItemIcon>
              </ListItemButton>
            </Tooltip>
        </ListItem>
        <ListItem key={"7"} disablePadding>
            <Tooltip title="Filtre par nom">
              <ListItemButton onClick={handleOpenFilter}>
                <ListItemIcon>
                  <SearchIcon/>
                </ListItemIcon>
              </ListItemButton>
            </Tooltip>
            <Popover
              open={openFilter}
              anchorEl={anchorElFilter}
              onClose={handleCloseFilter}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'left',
              }}
            >
              <Box
              sx={{
                '& > :not(style)': { m: 1, width: '25ch' },
              }}>
                  <TextField onChange={handleFilter} value={filterRegex} id="outlined-basic" label="Filtre ( * = inconnu) (format : **-**)" variant="outlined" />
                  <IconButton color="error" aria-label="clear" onClick={clearRegex} component="label" style={{width: "40px", marginTop: "16px"}}>
                    <ClearIcon />
                  </IconButton>
              </Box>
            </Popover>
        </ListItem>
        <ListItem key={"8"} disablePadding>
          <Tooltip title="Supprime l'élément séléctionné (SUPPR)">
            <ListItemButton onClick={clickDelete}>
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
            </ListItemButton>
          </Tooltip>
        </ListItem>
        <Divider/>
        <ListItem key={"9"} disablePadding>
          <Tooltip title="Nouveau plan">
            <ListItemButton onClick={handleOpenNewPlan}>
              <ListItemIcon>
                <FiberNewIcon/>
              </ListItemIcon>
            </ListItemButton>
          </Tooltip>
          <Popover
              open={openNewPlan}
              anchorEl={anchorElNewPlan}
              onClose={handleCloseNewPlan}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'left',
              }}
            >
              <Box
              sx={{
                '& > :not(style)': { m: 1, width: '25ch' },
              }}>
                  
                  <p style={{width: "100%", fontWeight: "bold"}}>Voulez-vous créer un nouveau plan? (Toutes modifications non sauvegardé seront perdu)</p>
                  <div style={{width: "100%", textAlign: "center"}}><TextField value={inputNewPlan} onChange={handleInputNewPlan} id="outlined-basic" label="Nom du plan" variant="outlined" /></div>
                  <div style={{width: "100%", display:"flex", gap: "10px", justifyContent: "center"}}><Button onClick={createNewPlan} variant="contained" color="success">Oui</Button><Button variant="contained" onClick={saveActualPlan} color="warning">Non, je veux sauvegarder d'abord</Button></div>
              </Box>
            </Popover>
        </ListItem>
        <ListItem key={"10"} disablePadding>
          <Tooltip title="Sauvegarder le plan">
            <ListItemButton onClick={saveActualPlan}>
              <ListItemIcon>
                <SaveAsIcon/>
              </ListItemIcon>
            </ListItemButton>
          </Tooltip>
        </ListItem>
        <ListItem key={"11"} disablePadding>
          <Tooltip title="Recharger les plans depuis l'api">
            <ListItemButton>
              <ListItemIcon>
                <CachedIcon/>
              </ListItemIcon>
            </ListItemButton>
          </Tooltip>
        </ListItem>
        <ListItem key={"12"} disablePadding>
          <Tooltip title="Imprimer le plan actuel">
            <ListItemButton onClick={PrintPlan}>
              <ListItemIcon>
                <PrintIcon/>
              </ListItemIcon>
            </ListItemButton>
          </Tooltip>
        </ListItem>
        <ListItem key={"13"} disablePadding>
          <Tooltip title="Charger un autre plan">
            <ListItemButton onClick={handleOpenLoadPlan}>
              <ListItemIcon>
                <SwitchCameraIcon/>
              </ListItemIcon>
            </ListItemButton>
          </Tooltip>
          <Popover
              open={openLoadPlan}
              anchorEl={anchorElLoadPlan}
              onClose={handleCloseLoadPlan}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'left',
              }}
            >
              <Box
              sx={{
                '& > :not(style)': { m: 1, width: '25ch' },
              }}> 
                  <FormControl fullWidth>
                    <InputLabel id="simple-select-plan">Plans</InputLabel>
                    <Select
                      labelId="simple-select-plan"
                      value={selectedPlans}
                      label="Plans"
                      onChange={onChangeSelectedPlan}
                    >
                      {plans.map((plan, i)=>{
                        return <MenuItem key={"plans"+i} value={i}>{plan["name"]} ({JSON.parse(plan["spots"]).length})</MenuItem>
                      })}
                    </Select>
                  </FormControl>
                  <Tooltip title="Recharger les plan depuis l'api">
                    <IconButton color="success" aria-label="clear" component="label" style={{width: "40px", marginTop: "16px"}}>
                      <ReplayIcon />
                    </IconButton>
                  </Tooltip>
              </Box>
            </Popover>
        </ListItem>
      </List>
    </div>
  );


  return (
    <Box sx={{ display: 'flex' }}>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, position: 'unset' },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` }, overflow: "scroll" }}
      >
        <EntrepotSys planselected={plans[selectedPlans]} ref={toolBarRef}/>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleCloseSnack}
        message="I love snacks"
        key={vertical + horizontal}
      >
          <Alert onClose={handleCloseSnack} severity={color} sx={{ width: '100%' }}>
            {message}
          </Alert>
      </Snackbar>
    </Box>
  );
}

export default PlanEntrepot;
