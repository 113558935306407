
import LinearProgressWithLabelStatic from "../Progress/LinearProgressWithLabelField";

const StepsDisplayer = (props) =>{
    let data = props.data;
    let step = data.currentStep;
    let prepared = data.prepared;
    let controlled = data.controlled;
    let controlledResponsible = data.controlledResponsible;
    let packed = data.packed;
    let charged = data.charged;
    

    let currentStep;
    let currentStepName;
    if(step === "prepare"){
      currentStep = prepared;
      currentStepName = "En cours de préparation";
    }else if(step === "control"){
      currentStep = controlled;
      currentStepName = "En contrôle";
    }else if(step === "control-responsible"){
      currentStep = controlledResponsible;
      currentStepName = "En contrôle par un responsable";
    }else if(step === "pack"){
      currentStep = packed;
      currentStepName = "En cours d'emballage";
    }else if(step === "charge"){
      currentStep = charged;
      currentStepName = "En chargement";
    }else if(step === "expect"){
      currentStep = "Expédié";
      return "Expédié";
    }else if(step === "plan"){
      currentStep = "Planification";
      return "Planification";
    }else{
      currentStep = "error";
      return "error";
    }
  
    if(currentStep !== "error" && currentStep !== "Expédié" && currentStep !== "Planification"){
      return (
        <div style={{textAlign: "center"}}>
          {currentStepName}
  
          <LinearProgressWithLabelStatic info={currentStep}/>
        </div>
  
      );
     
    }
}

export default StepsDisplayer;