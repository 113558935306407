import { LinearProgress } from '@mui/material';
import * as React from 'react';



const WareHouseSelect = (props) =>{
  let allWarehouse = props.allWarehouse;
  let onChangeWarehouses = props.onChangeWarehouses;
  let actualWarehouse = props.warehouses;

  const formatSelectedWarehouse = (v) => [].concat(v.toString().split(','));
  const handleChange = (e) => {
    let value = Array.from(e.target.selectedOptions, option => option.value);
    onChangeWarehouses(value);
  };
  if(allWarehouse.length===0){return <LinearProgress/>}
  return (
    <div>
      
      <select style={{width: "100%"}} defaultValue={formatSelectedWarehouse(actualWarehouse)} onChange={handleChange} multiple={true}>
        {allWarehouse.map((warehouse) => {
              return <option
                      key={warehouse['@id']}
                      value={warehouse['@id']}
                     >
                      {warehouse.name}
                      </option>
        })}
      </select>
    </div>
  );
}

export default WareHouseSelect;