import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { FilledInput } from '@mui/material';
import './fixCssFilter.css';
import { gettransporteursList } from '../../Services/Commandes/Commandes';
import { getTransporteursList } from '../../Services/Transporteurs/Transporteurs';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};



function getStyles(name, transporteurName, theme) {
  return {
    fontWeight:
      transporteurName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function TransporteurMultipleSelect(props) {
  const theme = useTheme();
  const [transporteursList, settransporteurList] = React.useState([]);
  let settransporteurName = props.settransporteurName;
  let transporteurName = props.transporteurName;

  React.useEffect(() => {
    getTransporteursList().then(transporteurs => {
        settransporteurList(transporteurs['hydra:member']);
    })
  }, []);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    settransporteurName(
      typeof value === 'string' ? value.split(',') : value,
    );
    let stringFilter = "";
    value.forEach(transporteur=>{
        stringFilter += "&carrier[]="+transporteur;
    });
    props.setFilterTransporteurs(stringFilter);
  };

  return (
    <div>
      <FormControl sx={{ m: 1, width: 300 }} className="multipleSelect">
        <InputLabel>Transporteurs</InputLabel>
        <Select
          multiple
          margin="dense"
          value={transporteurName}
          onChange={handleChange}
          variant="filled"
          input={<FilledInput margin="dense" label="Transporteurs" />}
          MenuProps={MenuProps}
        >
          {transporteursList.map((transporteur) => (
            <MenuItem
              key={transporteur['@id']}
              value={transporteur['@id']}
              style={getStyles(transporteur, transporteurName, theme)}
            >
              {transporteur['name']}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
