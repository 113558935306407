import { AvatarGroup } from "@mui/material";
import WareHouseAvatar from "./WareHouseAvatar";

const WareHouseListPresenter = (props) =>{
    let listWarehouse = props.list;

    
    return(
    <AvatarGroup max={4}>
        {
            listWarehouse.map((warehouse) =>{
                return <WareHouseAvatar key={warehouse} id_warehouse={warehouse} />
            })
        }
    </AvatarGroup>
    )
}

export default WareHouseListPresenter;