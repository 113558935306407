import { ENTRY_POINT } from "../../global-config";
import { getToken, ValidateToken } from "../Auth/Authentification";

export async function RemovePaletteFromNavette(id_palette){
    const options = {
        method: 'PUT',
        headers: {
         'Content-Type': 'application/ld+json',
          Authorization: 'Bearer ' + getToken()
        },
        body: '{"navette": null, "charged": false}'
      };
      
    let data = await fetch(ENTRY_POINT+id_palette, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => {
            console.error(error);
        });
    
    if(ValidateToken(data) || data !== undefined){
        return data;
    }else{
        return "error";
    }
}

export async function setPaletteInNavette(id_palette, id_navette){
    const options = {
        method: 'PUT',
        headers: {
         'Content-Type': 'application/ld+json',
          Authorization: 'Bearer ' + getToken()
        },
        body: '{"navette": "'+id_navette+'"}'
      };
      
    let data = await fetch(ENTRY_POINT+id_palette, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => {
            console.error(error);
        });
    
    if(ValidateToken(data) || data !== undefined){
        return data;
    }else{
        return "error";
    }
}

export async function ToggleChargedPalette(id_palette, charged){
    const options = {
        method: 'PUT',
        headers: {
         'Content-Type': 'application/ld+json',
          Authorization: 'Bearer ' + getToken()
        },
        body: '{"charged": '+charged+'}'
      };
      
    let data = await fetch(ENTRY_POINT+id_palette, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => {
            console.error(error);
        });
    
    if(ValidateToken(data) || data !== undefined){
        return data;
    }else{
        return "error";
    }
}

export async function getPalettesList(search, page, itemperpage){
    const options = {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + getToken()
        }
      };
      
    page = page+1;
      
    let data = await fetch(ENTRY_POINT+"/palettes?ean="+search+"&order%5Bid%5D=ASC&page="+page+"&itemsPerPage="+itemperpage, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => {
            console.error(error);
        });
    
    if(ValidateToken(data) || data !== undefined){
        return data;
    }else{
        return "error";
    }
}


export async function getOnePalette(id_palette){
    const options = {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + getToken()
        }
      };
      
    let data = await fetch(ENTRY_POINT+id_palette, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => {
            console.error(error);
        });
    
    if(ValidateToken(data) || data !== undefined){
        return data;
    }else{
        return "error";
    }
}

export async function updatePaletteTrackingNumber(id_palette, value){
    const options = {
        method: 'PUT',
        headers: {
         'Content-Type': 'application/ld+json',
          Authorization: 'Bearer ' + getToken()
        },
        body: '{"tackingNumber": "'+value+'"}'
      };
      
    let data = await fetch(ENTRY_POINT+id_palette, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => {
            console.error(error);
        });
    
    if(ValidateToken(data) || data !== undefined){
        return data;
    }else{
        return "error";
    }
}

export async function deleteOnePalette(id_palette){
    const options = {
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + getToken()
        }
      };
      
    let data = await fetch(ENTRY_POINT+id_palette, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => {
            return error;
        });
    
    if(ValidateToken(data) || data !== undefined){
        return data;
    }else{
        return "error";
    }
}

export async function getPaletteTypeList(){
    const options = {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + getToken()
        }
      };
      
    let data = await fetch(ENTRY_POINT+"/products?order[id]=DESC&page=1&itemsPerPage=18&palette=true", options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => {
            console.error(error);
        });
    
    if(ValidateToken(data) || data !== undefined){
        return data;
    }else{
        return "error";
    }
}


export async function updatePaletteFieldInTable(id_palette, namefield, value){
    const options = {
        method: 'PUT',
        headers: {
         'Content-Type': 'application/ld+json',
          Authorization: 'Bearer ' + getToken()
        },
        body: '{"'+namefield+'": '+value+'}'
      };
      
    let data = await fetch(ENTRY_POINT+id_palette, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => {
            console.error(error);
        });
    
    if(ValidateToken(data) || data !== undefined){
        return data;
    }else{
        return "error";
    }
}

export async function updatePaletteField(id_palette, width, length, height, weight, type){
    var options;

    if(type !== null){
        options = {
            method: 'PUT',
            headers: {
             'Content-Type': 'application/ld+json',
              Authorization: 'Bearer ' + getToken()
            },
            body: '{"width": '+width+', "length": '+length+', "height": '+height+', "weight": '+weight+', "type": "'+type+'"}'
          };
    }else{
        options = {
            method: 'PUT',
            headers: {
             'Content-Type': 'application/ld+json',
              Authorization: 'Bearer ' + getToken()
            },
            body: '{"width": '+width+', "length": '+length+', "height": '+height+', "weight": '+weight+'}'
          };
    }
      
    let data = await fetch(ENTRY_POINT+id_palette, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => {
            console.error(error);
        });
    
    if(ValidateToken(data) || data !== undefined){
        return data;
    }else{
        return "error";
    }
}


export async function addItemToPaletteWithQuantity(orderPreparationItem, palette, quantity){
    const options = {
        method: 'POST',
        headers: {
         'Content-Type': 'application/ld+json',
          Authorization: 'Bearer ' + getToken()
        },
        body: '{"orderPreparationItem": "'+orderPreparationItem+'", "palette": "'+palette+'", "quantity": '+quantity+'}'
      };
      
    let data = await fetch(ENTRY_POINT+"/palette_items", options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => {
            console.error(error);
        });
    
    if(ValidateToken(data) || data !== undefined){
        return data;
    }else{
        return "error";
    }
}