import { ENTRY_POINT } from "../../global-config";
import { getToken, ValidateToken } from "../Auth/Authentification";

export async function getPreparationCurrentStep(order_prep_id){
    const options = {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + getToken()
        }
      };
      
    let data = await fetch(ENTRY_POINT+order_prep_id, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => {
            console.error(error);
        });
    
    if(ValidateToken(data) || data !== undefined){
        return data["currentStep"];
    }else{
        return "error";
    }
}


export async function stepPrepFinish(id_order_prep){
    const options = {
        method: 'PATCH',
        headers: {
         'Content-Type': 'application/merge-patch+json',
          Authorization: 'Bearer ' + getToken()
        },
        body: '{"state": "/order_preparation_states/stopped", "step": "/order_preparation_steps/control"}'
      };
      
    let data = await fetch(ENTRY_POINT+id_order_prep, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => {
            console.error(error);
        });
    
    if(ValidateToken(data) || data !== undefined){
        return data;
    }else{
        return "error";
    }
}

export async function stepControlFinish(id_order_prep){
    const options = {
        method: 'PATCH',
        headers: {
         'Content-Type': 'application/merge-patch+json',
          Authorization: 'Bearer ' + getToken()
        },
        body: '{"state": "/order_preparation_states/stopped", "step": "/order_preparation_steps/control-responsible"}'
      };
      
    let data = await fetch(ENTRY_POINT+id_order_prep, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => {
            console.error(error);
        });
    
    if(ValidateToken(data) || data !== undefined){
        return data;
    }else{
        return "error";
    }
}

export async function stepControlRespFinish(id_order_prep){
    const options = {
        method: 'PATCH',
        headers: {
         'Content-Type': 'application/merge-patch+json',
          Authorization: 'Bearer ' + getToken()
        },
        body: '{"state": "/order_preparation_states/stopped", "step": "/order_preparation_steps/pack"}'
      };
      
    let data = await fetch(ENTRY_POINT+id_order_prep, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => {
            console.error(error);
        });
    
    if(ValidateToken(data) || data !== undefined){
        return data;
    }else{
        return "error";
    }
}

export async function validateControlPaletteItem(id_palette_item, value){
    const options = {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/merge-patch+json',
          Authorization: 'Bearer ' + getToken()
        },
        body: '{"controlled": '+value+'}'
      };
      
    let data = await fetch(ENTRY_POINT+id_palette_item, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => {
            console.error(error);
        });
    
    if(ValidateToken(data) || data !== undefined){
        return data;
    }else{
        return "error";
    }
}

export async function validateControlRespPaletteItem(id_palette_item, value){
    const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/ld+json',
          Authorization: 'Bearer ' + getToken()
        },
        body: '{"controlledResponsible": '+value+'}'
      };
      
    let data = await fetch(ENTRY_POINT+id_palette_item, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => {
            console.error(error);
        });
    
    if(ValidateToken(data) || data !== undefined){
        return data;
    }else{
        return "error";
    }
}

export async function validatePackedPaletteItem(id_palette_item, value){
    const options = {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/merge-patch+json',
          Authorization: 'Bearer ' + getToken()
        },
        body: '{"packed": '+value+'}'
      };
      
    let data = await fetch(ENTRY_POINT+id_palette_item, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => {
            console.error(error);
        });
    
    if(ValidateToken(data) || data !== undefined){
        return data;
    }else{
        return "error";
    }
}

export async function stepPackedFinish(id_order_prep){
    const options = {
        method: 'PATCH',
        headers: {
         'Content-Type': 'application/merge-patch+json',
          Authorization: 'Bearer ' + getToken()
        },
        body: '{"state": "/order_preparation_states/stopped", "step": "/order_preparation_steps/charge"}'
      };
      
    let data = await fetch(ENTRY_POINT+id_order_prep, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => {
            console.error(error);
        });
    
    if(ValidateToken(data) || data !== undefined){
        return data;
    }else{
        return "error";
    }
}